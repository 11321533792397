import ReactGA from "react-ga";
var ga = ReactGA.ga();

export const getClientId = () => {
  try {
    var clientId = ga.getAll()[0].get("clientId");
    return clientId;
  } catch (error) {
    return "";
  }
};

export default getClientId;
