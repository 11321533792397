import React from "react";
import {
  LocalCafeRounded,
  CheckRounded,
  PersonSharp,
  UnfoldMoreRounded,
} from "@material-ui/icons";
import IconWrapper from "../../components/hotel/IconWrapper";
import { returnCurrencyValues, formatPrice } from "../../utils/hotelUtil";
import { IS_B2B, IS_CALL_CENTER } from "../../config/constants";

const LongStayRoomRow = ({
  room,
  currency,
  blockCounts,
  setBlockCounts,
  nationality,
  isCustomPrice,
  adultChildRoom,
  fullRoomFlag,
  handleValueChange,
  showPrice,
}) => {
  return (
    <div className="RoomRow d-flex">
      <div
        style={{
          padding: "8px 4px",
          minWidth: 76,
        }}
      >
        <div className="d-flex" style={{ maxWidth: 76 }}>
          <span style={{ fontSize: 14 }}>{room?.room_size}</span>
        </div>
      </div>
    </div>
  );
};

export default LongStayRoomRow;
