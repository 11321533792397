import React, { useState, useRef } from "react";
import {
  Grid,
  Modal,
  Button,
  TextField,
  Card,
  CircularProgress,
} from "@mui/material";
import { CREATE_TEST_CHECKOUT_RESERVATION } from "../../queries/testcheckoutquery";
import { useMutation } from "@apollo/react-hooks";
import { GetSalesChannelID } from "../../utils/common";
import { clientV3 } from "../../utils/v3utils";
import {
  LOGIN_PEOPLE_DETAIL,
  REACT_APP_REDIRECT_URL,
  TEN_MINS_TIMEOUT,
  TWO_MINS_TIMEOUT,
} from "../../config/constants";
import {
  getClientTag,
  checkInApp,
  checkInWeb,
  getLocale,
  getKBZPayToken,
  getUserId,
} from "../../utils/checkurl";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  WAVEMONEY,
  KBZ,
  ONEPAY,
  MPITESAN,
  CITIZENPAY,
  AYAPAY,
  MOMONEY,
} from "../../config/inappclients";
import {
  CREATE_PAYMENT_QUERY,
  CHECK_PAYMENT_QUERY,
} from "../../queries/paymentquery";
import {
  PaymentMutation,
  PaymentCheckingStatus,
} from "../../utils/paymentmutation";
import { KBZH5 } from "../../utils/kbzh5";
import MpitesanRedirectForm from "../../components/payments/MpitesanRedirectForm";
import { useTranslation } from "react-i18next";

const TestCheckoutModal = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();
  const params = useParams();
  const myForm = useRef();
  const [selectedLanguage] = useState(getLocale(params.locale));
  const [clientTag] = useState(getClientTag(window.location.search));
  const [isInApp] = useState(checkInApp(window.location.pathname));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));
  const [checkoutAmount, setCheckoutAmount] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [kbzPayToken] = useState(getKBZPayToken(window.location.search));
  const [mpitesanInfo, setMpitesanInfo] = useState(null);
  const [paymentConfirmError, setPaymentConfirmError] = useState(false);
  const [waitingPayment, setWaitingPayment] = useState(false);
  const [onepayUserId] = useState(getUserId(window.location.search));
  const { t } = useTranslation();

  const handleInAppPayment = (bId, cPhone) => {
    setWaitingPayment(true);
    setShowModal(false);
    let gateway =
      clientTag === WAVEMONEY
        ? "wave"
        : clientTag === KBZ
        ? "kbzpay-apph5"
        : clientTag === ONEPAY
        ? "onepay"
        : clientTag === MPITESAN
        ? "mpitesan-redirect"
        : clientTag === AYAPAY
        ? "ayapay-apph5"
        : clientTag === CITIZENPAY
        ? // "citizen-pay" // v1
          "citizens-pass-through" //change v2
        : clientTag === MOMONEY
        ? "momoney"
        : "";

    const baseUrl = REACT_APP_REDIRECT_URL;
    let returnUrl = `${baseUrl}/${selectedLanguage}/in-app/confirmation/${bId}${window.location.search}`;
    let cancelUrl = `${baseUrl}/${selectedLanguage}/in-app/flight${window.location.search}`;
    let failUrl = `${baseUrl}/${selectedLanguage}/in-app/show-message${window.location.search}`;

    const paymentParams = {
      client: clientV3,
      query: CREATE_PAYMENT_QUERY,
      gateway,
      bookingId: bId,
      otherId: clientTag === AYAPAY ? "flymya-html" : null,
      contactPhone:
        clientTag === ONEPAY && onepayUserId !== "" ? onepayUserId : cPhone,
      returnUrl,
      cancelUrl,
      failUrl,
      productType: "domestic",
    };

    let timeout =
      clientTag === KBZ || clientTag === AYAPAY
        ? TEN_MINS_TIMEOUT
        : TWO_MINS_TIMEOUT;

    PaymentMutation(paymentParams).then((response) => {
      if (response.data.createPayment.status) {
        clientTag === KBZ &&
          KBZH5(response.data.createPayment.redirectData, kbzPayToken);
        if (clientTag === CITIZENPAY || clientTag === MOMONEY) {
          window.location.href = response.data.createPayment.redirectUrl;
        }
        if (clientTag === MPITESAN) {
          setMpitesanInfo(response.data.createPayment);
        }
        checkPaymentStatus(
          gateway,
          timeout,
          paymentParams.bookingId,
          response.data.createPayment.uuid,
        );
      }
    });
  };

  const checkPaymentStatus = (gateway, timeout, bookingId, uuid) => {
    let checkPaymentId = setInterval(() => {
      PaymentCheckingStatus(
        clientV3,
        CHECK_PAYMENT_QUERY,
        bookingId,
        gateway,
        uuid,
      )
        .then((response) => {
          if (response.data.checkPaymentStatus.status) {
            setWaitingPayment(false);
            handleClearInterval(checkPaymentId);
            navigate({
              pathname: `/${selectedLanguage}/in-app/confirmation/${bookingId}`,
              search: window.location.search,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setWaitingPayment(false);
          setPaymentConfirmError(true);
          handleClearInterval(checkPaymentId);
        });
    }, 3000);

    setTimeout(() => {
      setPaymentConfirmError(true);
      handleClearInterval(checkPaymentId);
    }, timeout);
  };

  const handleClearInterval = (id) => {
    clearInterval(id);
  };

  const [createTestCheckoutReservation] = useMutation(
    CREATE_TEST_CHECKOUT_RESERVATION,
    {
      onCompleted: (res) => {
        if (isInApp) {
          handleInAppPayment(res.createTestReservation.id, phoneNumber);
        } else
          navigate({
            pathname: `/en/payment-method/${res.createTestReservation.id}`,
            search: window.location.search,
          });
      },
      onError: (err) => {
        console.log(err);
      },
    },
  );

  let strData = localStorage.getItem(LOGIN_PEOPLE_DETAIL)
    ? JSON.parse(localStorage.getItem(LOGIN_PEOPLE_DETAIL))
    : null;
  let arrEmail =
    strData?.arranger_emails?.filter((val) => val.is_verified === true).length >
    0
      ? strData?.arranger_emails?.filter((val) => val.is_verified === true)[0]
          .email
      : "test@flymya.co";

  let arrPhonePrefix =
    strData?.arranger_phones?.filter(
      (val) => val.is_verified === true && val.is_primary === true,
    ).length > 0
      ? strData?.arranger_phones?.filter(
          (val) => val.is_verified === true && val.is_primary === true,
        )[0].phone_prefix
      : "95";

  let arrPhone =
    strData?.arranger_phones?.filter(
      (val) => val.is_verified === true && val.is_primary === true,
    ).length > 0
      ? strData?.arranger_phones?.filter(
          (val) => val.is_verified === true && val.is_primary === true,
        )[0].phone
      : "9123456789";

  const arrangerData = {
    id: null,
    prefix: strData?.prefix?.split(".")[0],
    first_name: strData?.first_name,
    last_name: strData?.last_name,
    phone_prefix: arrPhonePrefix,
    phone: arrPhone,
    email: arrEmail,
  };

  return (
    <React.Fragment>
      {mpitesanInfo && (
        <MpitesanRedirectForm refName={myForm} data={mpitesanInfo} />
      )}
      {waitingPayment && (
        <>
          <Modal open={waitingPayment}>
            <Card
              className="paymentModalBox"
              style={isMobile ? mobModalBox : modalBox}
            >
              <span className="confirmationBoxTitle">
                {t("masterPassengerInformationTwo.waiting_for_payment") !==
                "masterPassengerInformationTwo.waiting_for_payment"
                  ? t("masterPassengerInformationTwo.waiting_for_payment")
                  : "Waiting for payment confirmation..."}
                <br />
                <br />
              </span>
              <CircularProgress />
            </Card>
          </Modal>
        </>
      )}
      <Modal
        open={showModal}
        id="reliefModal"
        onClose={(event, reason) => {
          window.GorgiasChat && window.GorgiasChat.close();
          if (reason === "backdropClick" && reason !== "escapeKeyDown") {
            setShowModal(false);
          }
        }}
      >
        <Grid
          className="confirmationBox"
          style={isMobile ? mobModalBox : modalBox}
        >
          <p style={infoDescription}>How much do you want to checkout with</p>
          <Grid item sm={12} md={12} xs={12} style={{ marginBottom: "20px" }}>
            {" "}
            <TextField
              name="amount"
              placeholder="Amount"
              value={checkoutAmount}
              autoComplete="on"
              onChange={(e) => setCheckoutAmount(e.target.value)}
              className="checkAmountBox"
            />
          </Grid>
          <Grid item sm={12} md={12} xs={12}>
            {" "}
            <TextField
              name="phoneNo"
              placeholder="Phone Number"
              value={phoneNumber}
              autoComplete="on"
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="checkAmountBox"
            />
          </Grid>

          <br />
          <Grid item sm={12} md={12} xs={12}>
            <Button
              color="primary"
              variant="contained"
              className="btnContinue payment-btn-pay btn-offline"
              onClick={() => {
                createTestCheckoutReservation({
                  variables: {
                    input: {
                      sales_channel_id: GetSalesChannelID(
                        null,
                        strData,
                        isInApp,
                        isInWeb,
                        clientTag,
                      ),
                      arranger: arrangerData,
                      products: [
                        {
                          testCheckout: {
                            product_id: null,
                            name: `${strData.first_name} ${strData.last_name}`,
                            phone: arrPhone,
                            email: arrEmail,
                            total: Number(checkoutAmount),
                          },
                        },
                      ],
                      sources: [],
                    },
                  },
                });
              }}
            >
              Checkout
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};
const infoDescription = {
  fontSize: "16px",
  textAlign: "center",
  lineHeight: "22px",
};
const modalBox = {
  backgroundColor: "#fff",
  display: "block",
  position: "absolute",
  top: "30%",
  padding: "30px",
  margin: "auto",
  width: "400px",
  borderRadius: "8px",
  outline: "none !important",
  left: "calc(28% + 80px)",
  textAlign: "center",
};
const mobModalBox = {
  backgroundColor: "#fff",
  display: "block",
  position: "relative",
  top: "30%",
  padding: "30px",
  margin: "auto",
  width: "80%",
  borderRadius: "8px",
  outline: "none !important",
  textAlign: "center",
};

export default TestCheckoutModal;
