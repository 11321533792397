import { gql } from "apollo-boost";

export const CREATE_WALLET_ACTIVITY = gql`
  mutation ($input: createWalletActivityLogInput!) {
    createWalletActivityLog(input: $input) {
      id
      status
      message
      phone
      access_token
      refresh_token
      expires_in
      token_type
      user {
        id
        name
        email
        phone
        company {
          id
          name
          arrangers {
            id
            prefix
            first_name
            last_name
            arranger_phones {
              id
              phone_prefix
              phone
              is_verified
              is_primary
            }
            arranger_emails {
              id
              email
              is_verified
              is_primary
            }
            nationality {
              id
              name
            }
            dob
            passport
            passport_exp
            nrc
            address
            township {
              id
              name
              city {
                id
                name
                region {
                  id
                  name
                }
              }
            }
          }
        }
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        arranger {
          id
          prefix
          first_name
          last_name
          arranger_phones {
            id
            phone_prefix
            phone
            is_verified
            is_primary
          }
          arranger_emails {
            id
            email
            is_verified
            is_primary
          }
          nationality {
            id
            name
          }
          company {
            id
            name
            active
            class_type {
              id
              name
            }
          }
          dob
          passport
          passport_exp
          nrc
          address
          township {
            id
            name
            city {
              id
              name
              region {
                id
                name
              }
            }
          }
          loyalty_program {
            id
            sign_up
            logged_in_mobile
            joined_experience_myanmar
            invited_friend
            arranger {
              id
            }
            referral_by {
              id
            }
            booked_flight
            booked_bus
            booked_hotel
            added_friend
          }
        }
      }
    }
  }
`;

export const CREATE_ARRANGER_VIBER = gql`
  mutation createArrangerViber($input: arrangerViberCreateInput) {
    createArrangerViber(input: $input) {
      channel
      viber_name
      viber_id
      viber_avatar
      viber_language
      viber_country
      viber_api
      arranger {
        id
        first_name
        last_name
      }
    }
  }
`;
