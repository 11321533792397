import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import { clientV3 } from "../utils/v3utils";
import { UPDATE_CREATE_PASSWORD } from "../queries/passwordquery";
import { useLocation } from "react-router-dom";
import { setToLocalStorage, storeUserProfileInfo } from "../utils/loginutils";
import { FIREBASE_LOGIN_QUERY, AGENT_LOGIN_QUERY } from "../queries/loginquery";
import { CREATE_ARRANGER_VIBER } from "../queries/customerinfoquery";
import { useMutation } from "@apollo/react-hooks";
import CreatePasswordSuccessModal from "../components/modal/CreatePasswordSuccessModal";
import { isMobile } from "react-device-detect";
import { getClientTag, checkInWeb, getViberParam } from "../utils/checkurl";
import { VIBER_IN_WEB } from "../config/inwebclients";
import useFlymyaSessionHook from "../hooks/FlymyaSessionHook";

const ForgetPassword = () => {
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [clientTag] = useState(getClientTag(window.location.search));
  const [isInWeb] = useState(checkInWeb(window.location.pathname));

  const classes = useStyles();
  const { search } = useLocation();
  let searchParams = new URLSearchParams(search);

  const [saveViberCustomerInfo] = useMutation(CREATE_ARRANGER_VIBER, {
    onCompleted: (res) => {
      //console.log(res);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const { handleFlymyaSession, handleUpdateArrangerId } =
    useFlymyaSessionHook();

  useEffect(() => {
    handleFlymyaSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePasswordTypeChange = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = (emailVal, passVal, roleId) => {
    emailVal !== "" &&
      emailVal &&
      passVal &&
      passVal !== "" &&
      clientV3
        .mutate({
          mutation:
            localStorage.getItem("loginPanel") === "agent"
              ? AGENT_LOGIN_QUERY
              : FIREBASE_LOGIN_QUERY,
          variables: {
            input: {
              username: emailVal && emailVal !== "" ? emailVal : "",
              password: passVal && passVal !== "" ? passVal : "",
              device_type: "website",
              oneclick: true,
              client:
                clientTag && clientTag !== ""
                  ? clientTag
                  : isMobile
                  ? "default"
                  : "default",
            },
          },
        })
        .then((res) => {
          //Update Arranger ID Session
          const getVal =
            localStorage.getItem("loginPanel") === "agent"
              ? res?.data?.agentLogin?.user
              : res?.data?.firebaseLogin?.user;
          handleUpdateArrangerId(
            getVal && getVal.arranger ? getVal.arranger.id : null,
          );

          //Add Viber Customer Info
          if (isInWeb && clientTag === VIBER_IN_WEB) {
            const viberParam = getViberParam(window.location.search);
            saveViberCustomerInfo({
              variables: {
                input: {
                  arranger_id: res?.data?.firebaseLogin?.user?.arranger?.id,
                  channel: viberParam.channel,
                  viber_name: viberParam.viber_name,
                  viber_id: viberParam.viber_id,
                  viber_avatar: viberParam.viber_avatar,
                  viber_language: viberParam.viber_language,
                  viber_country: viberParam.viber_country,
                  viber_api: viberParam.viber_api,
                },
              },
            });
          }

          storeUserProfileInfo(res);
          setToLocalStorage(res);
          //window.location.reload(true);

          //if user is in-app after login redirect to in-app home page. else normal web home page
        })
        .catch((err) => {
          const isAccountClose = err.message.includes(
            "Your account is temporarily closed. Please contact our customer service team",
          );
          const isLoginFailB2B = err.message.includes(
            "Login failed! Please use the B2C Customer login tab",
          );
          const isLoginFailB2C = err.message.includes(
            "Login failed! Use Agent login tab from flymya.com",
          );

          const errMsg = isAccountClose
            ? "Your account is temporarily closed. Please contact our partnership team for any assistance."
            : isLoginFailB2B
            ? "Login failed! You are using B2C Customer login. Please use the Customer login Panel instead."
            : isLoginFailB2C
            ? "Login failed! You are using B2B login. Please use the Agent login Panel instead."
            : "The user credentials were incorrect! please try again.";
        });
  };

  const handleResetPassword = async () => {
    if (password.length < 8) {
      setPasswordError(true);
      setPasswordErrorMessage("Must be at least 8 characters");
    } else if (password !== confirmPassword) {
      setPasswordError(true);
      setPasswordErrorMessage("Both passwords must match");
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
      let resetData = {
        email: searchParams?.get("email"),
        token: searchParams?.get("token"),
        password,
        password_confirmation: confirmPassword,
      };
      try {
        const { data } = await clientV3.mutate({
          mutation: UPDATE_CREATE_PASSWORD,
          variables: {
            input: resetData,
          },
        });
        if (data?.updateCreatePassword?.status === "PASSWORD_UPDATED") {
          setShowModal(true);
          window.localStorage.setItem("createdPass", true);
          handleLogin(searchParams?.get("email"), resetData.password);
        }
      } catch (error) {
        console.log(error);
        setPasswordError(true);
        setPasswordErrorMessage(error?.message);
      }
    }
  };
  return (
    <>
      <div className={classes.forgetPasswordContainer}>
        {/* <Grid container> */}
        <div className={classes.forgetPassText}>
          <Typography component="h1" variant="h5">
            Create Password
          </Typography>
        </div>

        <div className={classes.formWrapper}>
          <div>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel>Password</InputLabel>

              <OutlinedInput
                className={classes.passwordInput}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                fullWidth={false}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "-10px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordTypeChange}>
                      {showPassword ? (
                        <Visibility className={classes.iconColor} />
                      ) : (
                        <VisibilityOff className={classes.iconColor} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </div>
          <div style={{ marginTop: "7px" }}>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel>Confirm Password</InputLabel>

              <OutlinedInput
                className={classes.passwordInput}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                fullWidth={false}
                labelWidth={130}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "-10px",
                }}
              />
            </FormControl>
          </div>
          {passwordError && passwordErrorMessage.length > 0 && (
            <small className={classes.errorText}>{passwordErrorMessage}</small>
          )}
        </div>

        <div className={classes.btnMargin}>
          <Button
            onClick={handleResetPassword}
            variant="contained"
            className={classes.resetBtn}
          >
            Create Password
          </Button>
        </div>
        {/* </Grid> */}
      </div>
      <CreatePasswordSuccessModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    forgetPasswordContainer: {
      display: "flex",
      flexDirection: "column",
      //alignItems: "center"
    },
    forgetPassText: {
      //fontSize: 25,
      fontWeight: "normal",
      marginTop: 50,
      marginBottom: 25,
      textAlign: "center",
    },
    formInputWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: "40ch",
      [theme.breakpoints.down("md")]: {
        width: "38ch",
      },
      [theme.breakpoints.down("sm")]: {
        width: "30ch",
      },
      [theme.breakpoints.down("xs")]: {
        width: "25ch",
      },
    },
    passwordInput: {
      padding: "12px !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconColor: {
      color: "#000 !important",
    },
    text: {
      textAlign: "center",
      width: "100%",
      fontWeight: "initial",
      color: "#666666",
    },
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "30px",
    },

    btnMargin: {
      marginTop: theme.spacing(3),
      textAlign: "center",
      // marginLeft: "25px",

      width: "100%",
    },
    resetBtn: {
      backgroundColor: "#2f80ed !important",
      color: "#fff",
      textTransform: "capitalize",
      width: "30%",
      fontSize: "18px",
      marginLeft: "-10px",
      [theme.breakpoints.down("md")]: {
        width: "38ch",
      },
      [theme.breakpoints.down("sm")]: {
        width: "30ch",
      },
      [theme.breakpoints.down("xs")]: {
        width: "25ch",
      },
    },
    errorText: {
      color: "red",
    },
  }),
);

export default ForgetPassword;
