import React from "react";

const HotelMobileMaps = ({ hotel, city, lat, long }) => {
  let hotelName = encodeURIComponent(hotel).replace(/%20/g, "+");
  let cityName = encodeURIComponent(city).replace(/%20/g, "+");
  //let apiKey = process.env.REACT_APP_GOOGLEMAPS;
  let apiKey = "AIzaSyC2oy8jVWzu6oVM6P8YHIWEEnsqMOG3T_w";

  function returnMap() {
    try {
      return (
        <iframe
          title="maps"
          width="400"
          height="200"
          frameBorder="0"
          style={{ border: "0", width: "100%", height: "200px" }}
          src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}
    &q=${hotelName},${cityName}&center=${lat},${long}&zoom=14`}
        />
      );
    } catch (err) {
      console.log(err);
      return <div></div>;
    }
  }
  return (
    <div className="googleMaps" style={{ marginTop: "10px" }}>
      {returnMap()}
    </div>
  );
};

export default HotelMobileMaps;
