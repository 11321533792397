import React from "react";
import Grid from "@material-ui/core/Grid";
import { MY_MM } from "../../config/constants";
import { useTheme } from "@mui/material/styles";
import { ProgressBarStyles } from "../../pages/balloon/styles/ProgressBarStyles";
import { useTranslation } from "react-i18next";

const FlightProgressbar = ({ pageName, selectedLanguage }) => {
  const theme = useTheme();
  const styleProps = { bgColor: theme.palette.primary.main };
  const classes = ProgressBarStyles(styleProps);
  const { t } = useTranslation();

  return (
    <Grid item xs={9} sm={7} md={7} className="progressBarContainer">
      <ul
        className={
          selectedLanguage === MY_MM
            ? `${classes.progressBar} progressbar mm-progress`
            : `${classes.progressBar} progressbar eng-progress`
        }
      >
        <li
          className={
            pageName === "FlightSearch"
              ? "active"
              : "" + pageName !== "/"
              ? "completed"
              : ""
          }
        >
          <span
            style={{
              color:
                pageName === "FlightSearch"
                  ? theme.palette.primary.main
                  : "#a1a1a1",
            }}
            className="step-info"
            id="book"
          >
            {t("masterPassengerInformation.book") !==
            "masterPassengerInformation.book"
              ? t("masterPassengerInformation.book")
              : "Book"}
          </span>
        </li>
        <li
          className={
            pageName === "CustomerDetails"
              ? "active"
              : "" + pageName === "FlightPayment" ||
                pageName === "FlightConfirmation"
              ? "completed"
              : ""
          }
        >
          <span
            style={{
              color:
                pageName === "CustomerDetails"
                  ? theme.palette.primary.main
                  : "#a1a1a1",
            }}
            className="step-info"
          >
            {t(`2Search.info`) !== "2Search.info" ? t(`2Search.info`) : "Info"}
          </span>
        </li>
        <li
          className={
            pageName === "FlightPayment"
              ? "active"
              : "" + pageName === "FlightConfirmation"
              ? "completed"
              : ""
          }
        >
          <span
            style={{
              color:
                pageName === "FlightPayment"
                  ? theme.palette.primary.main
                  : "#a1a1a1",
            }}
            className="step-info"
          >
            {t("masterPassengerInformation.pay") !==
            "masterPassengerInformation.pay"
              ? t("masterPassengerInformation.pay")
              : "Pay"}
          </span>
        </li>
        <li className={pageName === "FlightConfirmation" ? "active" : ""}>
          <span
            style={{
              color:
                pageName === "FlightConfirmation"
                  ? theme.palette.primary.main
                  : "#a1a1a1",
            }}
            className="step-info"
          >
            {t("masterPassengerInformation.confirm") !==
            "masterPassengerInformation.confirm"
              ? t("masterPassengerInformation.confirm")
              : "Confirm"}
          </span>
        </li>
      </ul>
    </Grid>
  );
};

export default FlightProgressbar;
