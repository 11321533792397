import React from "react";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

const HotelFooter = () => {
  return (
    <React.Fragment>
      <Grid className="hotelFooter">
        <p> Copyright Ⓒ {moment().format("YYYY")} Flymya Tech Co.,Ltd.</p>
      </Grid>
    </React.Fragment>
  );
};

export default HotelFooter;
