import React from "react";
import NumericInput from "react-numeric-input";

const NumericInputComponent = (props) => {
  let { min, max, value, onChange, inputName, disabled = false } = props;

  return (
    <NumericInput
      className="numeric-counter"
      min={min}
      max={max}
      value={value}
      onChange={onChange([inputName])}
      mobile
      disabled={disabled}
      strict={true}
    />
  );
};
export default NumericInputComponent;
