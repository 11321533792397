import { gql } from "apollo-boost";

const CREATE_SESSION = gql`
  mutation ($input: flymyaSessionInput) {
    createFlymyaSession(input: $input) {
      id
      session_id
      device_id
      arranger_id
      client_platform_id
      metadata
      expired_at
      updated_at
      localstorage_support
      indexeddb_support
    }
  }
`;

const CREATE_USER_ACTION = gql`
  mutation ($input: userActionInput) {
    createUserAction(input: $input) {
      id
      arranger {
        id
        prefix
        first_name
        last_name
      }
      flymya_event {
        id
        event_name
      }
      flymya_session {
        id
        session_id
        arranger_id
        client_platform_id
        expired_at
      }
      utm_id
      uri
      serialized_payload
    }
  }
`;

export { CREATE_SESSION, CREATE_USER_ACTION };
