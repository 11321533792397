import { makeStyles } from "@material-ui/core/styles";

export const HotelPassengerFormWebStyles = makeStyles({
  insurancePassInfo: {
    backgroundColor: "#fff",
    height: "55px",
    borderRadius: "5px",
    marginTop: "10px",
    position: "relative",
  },
  insuranceSendInquiry: {
    right: "-145px",
    bottom: "-2px",
    position: "absolute !important",
    borderRadius: "5px",
    backgroundColor: "#FC9D00",
    textTransform: "none",
    color: "#fff",
    padding: "12px 14px !important",
    fontWeight: "bold !important",
    fontSize: "20px !important",
    minWidth: "140px !important",
    "&:hover": {
      backgroundColor: "#FC9D00",
    },
  },
  kbzInWebSendInquiry: {
    backgroundColor: "#052a54",
    "&:hover": {
      backgroundColor: "#052a54",
    },
  },
  cityRewardInWebSendInquiry: {
    backgroundColor: "#FFD41C",
    color: "#231f20",
    "&:hover": {
      backgroundColor: "#FFD41C",
      color: "#231f20",
    },
  },
  viberInWebSendInquiry: {
    backgroundColor: "#4e4879",
    "&:hover": {
      backgroundColor: "#4e4879",
    },
  },
  insuranceFirstName: {
    borderRight: "1px solid transparent",
    "& .gender": {
      fontWeight: "400",
      fontSize: "12px",
      color: "#656565",
      margin: "5px 0 10px 10px",
    },
    "& select": {
      marginLeft: "10px",
      height: "28px",
      border: 0,
      outline: "none",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      height: "30px",
      marginTop: "10px",
      fontSize: "14px",
    },
    "& .error-input": {
      "& .MuiInputBase-input::placeholder": {
        color: "red",
      },
    },
  },
  nameDiv: {
    display: "flex",
  },
  insuranceLastName: {
    borderRight: "1px solid #D9D9D9",
    borderLeft: "1px solid #D9D9D9",
    "& .MuiFormControl-root": {
      width: "95%",
    },
    "& .MuiInputBase-input": {
      height: "30px",
      marginTop: "10px",
      marginLeft: "15px",
      fontSize: "14px",
    },
    "& .error-input": {
      "& .MuiInputBase-input::placeholder": {
        color: "red",
      },
    },
  },
  insurancePhone: {
    borderRight: "1px solid #D9D9D9",
    "& .MuiFormControl-root": {
      width: "90%",
    },
    "& .travel-services-phoneprefix": {
      position: "relative",
      "& .countryCode": {
        position: "absolute",
        fontWeight: 400,
        fontSize: "14px",
        color: "#231f20",
        top: "20px",
        right: "22px",
        marginTop: 0,
      },
      "& .react-tel-input": {
        height: "40px",
        maxWidth: "69px",
        "& input[type=tel]": {
          border: "none",
          width: 0,
        },
        "& .flag-dropdown": {
          backgroundColor: "#fff",
          width: "100%",
          borderRadius: "3px",
          border: "none !important",
          marginTop: "15px",
        },
      },
    },

    "& .selected-flag": {
      width: "100%",
      paddingLeft: "7px",
      "& .flag": {
        marginTop: "-6px",
      },
      "& .arrow": {
        left: "50px",
      },
    },
    "& .MuiInputBase-input": {
      height: "30px",
      marginTop: "10px",
      fontSize: "14px",
    },
    "& .error-input": {
      "& .MuiInputBase-input::placeholder": {
        color: "red",
      },
    },
  },
  phoneDiv: {
    display: "flex",
  },
  insuranceEmail: {
    borderRight: "1px solid #D9D9D9",
    "& .MuiFormControl-root": {
      width: "95%",
    },
    "& .MuiInputBase-input": {
      height: "30px",
      marginTop: "10px",
      marginLeft: "15px",
      fontSize: "14px",
    },
    "& .error-input": {
      "& .MuiInputBase-input::placeholder": {
        color: "red",
      },
    },
  },
});
