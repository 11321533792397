import React from "react";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress } from "@mui/material";

const AgentTab = ({
  isDim,
  errorMsg,
  setErrorMsg,
  loginEmail,
  setLoginEmail,
  loginPassword,
  setLoginPassword,
  setIsCheck,
  handleLogin,
  isSpinner,
}) => {
  const { t } = useTranslation();

  return (
    <div className={isDim ? "dim-div infobox" : "infobox"}>
      <label>
        {t(`b2c.email`) !== "b2c.email" ? `${t(`b2c.email`)} *` : "Email *"}
      </label>
      <input
        className={errorMsg !== "" ? "error-textbox" : ""}
        name="loginEmail"
        type="text"
        placeholder={t(`b2c.email`) !== "b2c.email" ? t(`b2c.email`) : "Email"}
        value={loginEmail}
        onChange={(e) => {
          setLoginEmail(e.target.value);
        }}
        onBlur={(e) => {
          // if (!validateEmail(e.target.value))
          //   setErrorMsg("Please fill correct Email");
          // else setErrorMsg("");
          setErrorMsg("");
        }}
        autoComplete="off"
        required
        error={errorMsg !== "" ? true : false}
        variant="standard"
      />

      <label>
        {t("masterPassengerInformationTwo.password") !==
        "masterPassengerInformationTwo.password"
          ? `${t("masterPassengerInformationTwo.password")} *`
          : "Password *"}
      </label>
      <input
        className={errorMsg !== "" ? "error-textbox" : ""}
        name="loginPassword"
        type="password"
        placeholder={
          t("masterPassengerInformationTwo.password") !==
          "masterPassengerInformationTwo.password"
            ? t("masterPassengerInformationTwo.password")
            : "Password"
        }
        value={loginPassword}
        autoComplete="off"
        onChange={(e) => {
          setErrorMsg("");
          setLoginPassword(e.target.value);
        }}
        required
        error={errorMsg !== "" ? true : false}
        variant="standard"
      />

      {errorMsg !== "" && (
        <>
          <p>Ph no - 09 797978885, 09 263097152 </p>
          <p>Email - partners@flymya.co</p>
        </>
      )}

      {errorMsg !== "" && <p>{errorMsg}</p>}

      <Button
        color="primary"
        variant="contained"
        type="button"
        id="btn-sign-in"
        onClick={() => {
          setIsCheck(true);
          handleLogin();
        }}
        disabled={
          errorMsg !== "" || loginEmail === "airticket770@gmail.com"
            ? true
            : false
        }
        style={{
          marginBottom: 40,
        }}
      >
        {t("masterPassengerInformationTwo.sign_in") !==
        "masterPassengerInformationTwo.sign_in"
          ? t("masterPassengerInformationTwo.sign_in")
          : "Sign In"}{" "}
        {isSpinner && <CircularProgress />}
      </Button>
    </div>
  );
};

export default AgentTab;
