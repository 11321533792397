import React from "react";
import styled from "styled-components";

const SimplePagination = ({
  page,
  setPage,
  count,
  handleSizePerPagePagination,
}) => {
  return (
    <PaginationStyle>
      <span onClick={() => setPage(1)}>{`<<`}</span>
      <span
        onClick={() => page !== 1 && page > 0 && setPage(page - 1)}
      >{`<`}</span>
      <span onClick={() => setPage(page + 1)}>{`>`}</span>
      <select
        className="paginate-select common-custom-perpage"
        onChange={(e) => handleSizePerPagePagination(e.target.value)}
        value={count}
      >
        <option value="10">10</option>
        <option value="25">25</option>
        {/* <option value="50">50</option> */}
      </select>
      <p>Page No: {page}</p>
    </PaginationStyle>
  );
};

const PaginationStyle = styled.div`
  width: 100%;
  span {
    position: relative;
    display: block;
    padding: 0.2rem 0.75rem;
    line-height: 1.25;
    color: #20a8d8;
    background-color: #ff;
    border: 1px solid #e3e3e3;
    cursor: pointer;
    width: 1%;
    float: left;
    text-align: center;
  }
  span:hover {
    background: #20a8d8 !important;
    color: #fff;
  }
  .paginate-select {
    background: #fff !important;
    border-radius: 4px !important;
    color: #000 !important;
    border: none !important;
    width: 70px !important;
    text-align: center;
    float: left;
    margin-left: 20px;
    min-height: 37px;
  }
  p {
    float: left;
    margin-top: 8px;
    margin-left: 20px;
    font-size: 15px;
  }
`;

export default SimplePagination;
