import { browserName, osName } from "react-device-detect";
import { pick } from "lodash";
import queryString from "query-string";
import {
  SOURCE_TYPE_REFERRAL,
  SOURCE_TYPE_CAMPAIGN,
  SOURCE_TYPE_DEVICE,
  SOURCE_TYPE_PROMOTION,
  SOURCE_TYPE_WALLET_ACTIVITY,
  SOURCE_TYPE_CLIENT,
} from "../config/constants";
import ReactHtmlParser from "react-html-parser";
import { KBZ } from "../config/inappclients";

export const getSourceData = (
  kbzWalletPhone,
  kbzPayToken,
  clientTag,
  tourData,
) => {
  let queryValue = {};
  queryValue = pick(queryString.parse(window.location.search), [
    "sf",
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "referral",
    "utm_id",
    "flymya_campaign_id",
    "client",
  ]);

  let utmSource =
    queryValue.utm_source && queryValue.utm_source !== ""
      ? { id: null, key: "utm_source", value: queryValue.utm_source }
      : null;
  let utmMedium =
    queryValue.utm_medium && queryValue.utm_medium !== ""
      ? { id: null, key: "utm_medium", value: queryValue.utm_medium }
      : null;
  let utmCampaign =
    queryValue.utm_campaign && queryValue.utm_campaign !== ""
      ? { id: null, key: "utm_campaign", value: queryValue.utm_campaign }
      : null;
  let referral =
    queryValue.referral && queryValue.referral !== ""
      ? { key: "referral_no", value: queryValue.referral }
      : null;
  let utmId =
    queryValue.utm_id && queryValue.utm_id !== ""
      ? { key: "utm_id", value: queryValue.utm_id }
      : null;
  let flymyaCampaignId =
    queryValue.flymya_campaign_id && queryValue.flymya_campaign_id !== ""
      ? { key: "flymya_campaign_id", value: queryValue.flymya_campaign_id }
      : null;

  const getReferralDetail = referral
    ? [referral, { key: "referral_name", value: "" }]
    : [];
  let promotionSource = tourData
    ? [
        {
          id: null,
          key: "promotion_title",
          value: tourData?.slug,
        },
        { id: null, key: "promotion_id", value: tourData?.id },
        {
          id: null,
          key: "tour and package",
          value:
            tourData?.product_type?.filter((item, i) => item?.id === 26)
              ?.length > 0
              ? "True"
              : "False",
        },
      ]
    : [];

  const getDeviceDetail = [
    { key: "resolution width", value: String(window.innerWidth) },
    { key: "resolution height", value: String(window.innerHeight) },
    {
      key: "operating system",
      value: osName,
    },
    { key: "browser", value: browserName },
  ];

  let utmArr = [];
  utmSource && utmArr.push(utmSource);
  utmMedium && utmArr.push(utmMedium);
  utmCampaign && utmArr.push(utmCampaign);
  flymyaCampaignId && utmArr.push(flymyaCampaignId);

  const getUtmId = utmId ? [utmId] : [];
  const utmItems = utmArr.concat(getReferralDetail);
  const referralArr = utmItems.concat(getUtmId);
  const campaignArr = utmArr.concat(getUtmId);

  let sourceArr = [];
  if ((utmSource || utmMedium || utmCampaign) && referral) {
    sourceArr.push({
      items: referralArr,
      source_type_id: SOURCE_TYPE_REFERRAL,
    });
  }
  if (!utmSource && !utmMedium && !utmCampaign && referral) {
    sourceArr.push({
      items: referralArr,
      source_type_id: SOURCE_TYPE_REFERRAL,
    });
  }
  if (clientTag) {
    sourceArr.push({
      items: [
        {
          key: "client_param",
          value: clientTag ? clientTag : "",
        },
      ],
      source_type_id: SOURCE_TYPE_CLIENT,
    });
  }
  if (
    (utmSource || utmMedium || utmCampaign || flymyaCampaignId) &&
    !referral
  ) {
    sourceArr.push({
      items: campaignArr,
      source_type_id: SOURCE_TYPE_CAMPAIGN,
    });
  }

  if (tourData) {
    sourceArr.push({
      items: promotionSource,
      source_type_id: SOURCE_TYPE_PROMOTION,
    });
  }

  if (clientTag === KBZ) {
    sourceArr.push({
      items: [
        {
          key: "wallet_activity_logs_id",
          value: kbzWalletPhone && kbzWalletPhone.id ? kbzWalletPhone.id : "",
        },
        {
          key: "kbzpay_phone",
          value:
            kbzWalletPhone && kbzWalletPhone.phone ? kbzWalletPhone.phone : "",
        },
        { key: "kbzpay_token", value: kbzPayToken },
      ],
      source_type_id: SOURCE_TYPE_WALLET_ACTIVITY,
    });
  }

  sourceArr.push({
    items: getDeviceDetail,
    source_type_id: SOURCE_TYPE_DEVICE,
  });

  return sourceArr;
};
