import moment from "moment";

export const formatParamDate = (date) =>
  date ? moment(date).format("DD-MM-YYYY") : "";

export const formatApiParamDate = (date) =>
  date ? moment(date, "DD-MM-YYYY").format("DD/MMM/YYYY") : "";

export const convertToMoment = (date) =>
  date ? moment(date, "DD-MM-YYYY") : "";

export const formatDisplayDate = (date) =>
  date ? moment(date, "DD-MM-YYYY").format("ddd, DD MMM YYYY") : "";

export const formatDisplayDateHotel = (date) =>
  date ? moment(date, "YYYY-MM-DD").format("ddd, DD MMM YYYY") : "";

export const formatDetailDate = (date) =>
  date ? moment(date, "YYYY-MM-DD").format("DD MMM YYYY") : "";

export const getDayName = (date) =>
  date ? moment(date, "YYYY-MM-DD").format("dddd") : "";

export const getDayNameMultiDate = (date) =>
  date ? moment(date, "YYYY-MM-DD").format("ddd") : "";

export const formatAirlineCheckerDate = (date) =>
  date ? moment(date, "YYYY-MM-DD").format("DD/MMM/YYYY") : "";

export const formatBalloonSearchDate = (date) =>
  date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : "";

export const formatDisplayDate2 = (date) =>
  date ? moment(date, "DD-MM-YYYY").format("ddd, DD MMM") : "";

export const formatTime = (date) =>
  date ? moment(date).format("hh:mm A") : "";

export const formatBusDetailDate = (date) =>
  date ? moment(date).format("ddd, DD MMM YYYY") : "";

export const formatDisplayDate3 = (date) =>
  date ? moment(date, "DD-MM-YYYY").format("DD MMM YYYY") : "";

export const formatSearchParamDate = (date) =>
  date ? moment(date, "DD/MMM/YYYY").format("DD-MM-YYYY") : "";

export const formatDateForHotel = (date) => moment(date).format("YYYY-MM-DD");

export const durationHotel = (date1, date2) => {
  let start = moment(date1, "YYYY-MM-DD");
  let end = moment(date2, "YYYY-MM-DD");
  let duration = moment.duration(end.diff(start)).asDays();
  return `${duration} ${duration > 1 ? "nights" : "night"}`;
};

export const parseDateForHotel = (dateStr) =>
  moment(dateStr, "YYYY-MM-DD").toDate();

export const calculateNights = (dateStr1, dateStr2) =>
  Math.abs(
    moment(dateStr2, "YYYY-MM-DD").diff(moment(dateStr1, "YYYY-MM-DD"), "days"),
  );

export const formatDateDayNameAndMonth = (dateStr) =>
  moment(dateStr, "YYYY-MM-DD").format("ddd D MMM YYYY");

export const formatDateDayNameAndMonth2 = (dateStr) =>
  moment(dateStr, "YYYY-MM-DD").format("ddd, D MMM YYYY");

export const formatDateDayNameAndMonth3 = (dateStr) =>
  moment(dateStr, "YYYY-MM-DD").format("MMM D");

export const multiDateFormat = (dateStr) =>
  moment(dateStr, "YYYY-MM-DD").format("DD MMM");

export const getDayShortName = (date) =>
  date ? moment(date, "YYYY-MM-DD").format("ddd") : "";
