import { gql } from "apollo-boost";

const CREATE_PAYMENT_QUERY = gql`
  mutation ($input: ominipayGatewayInput!) {
    createPayment(input: $input) {
      status
      message
      qrCode
      redirectUrl
      bookingId
      productType
      redirectData
      redirectMethod
      uuid
      id
      transaction_id
    }
  }
`;
const CREATE_PAYMENT_CONFIRM_QUERY = gql`
  mutation ($input: ominipayGatewayOfflineConfirmInput!) {
    createPaymentOfflineConfirm(input: $input) {
      status
      message
    }
  }
`;
const CREATE_B2B_TOPUP_QUERY = gql`
  mutation ($input: ominipayGatewayInput2!) {
    createPaymentDeposit(input: $input) {
      status
      message
      qrCode
      redirectUrl
      bookingId
      productType
      redirectData
      redirectMethod
      uuid
      id
      transaction_id
    }
  }
`;

const CHECK_PAYMENT_QUERY = gql`
  query ($input: ominipayGatewayInput!) {
    checkPaymentStatus(input: $input) {
      status
      message
      productType
      bookingId
    }
  }
`;

const GET_EXCHANGE_RATE_BY_CODE = gql`
  query ($code: ID!) {
    exchangeRatesByCode(code: $code) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;

const GET_EXCHANGE_RATES = gql`
  query ($active: Boolean) {
    exchangeRates(active: $active) {
      code
      buying
      selling
      is_active
      last_updated
    }
  }
`;

const APPLY_PROMO_CODE = gql`
  mutation promoApply($input: promoApplyInput) {
    promoApply(input: $input) {
      id
      total {
        id
        physical_amount
      }
      sources {
        id
        items {
          id
          key
          value
        }
        source_type_id
        customer_charge {
          id
          physical_amount
          physical_currency
        }
      }
    }
  }
`;

const REMOVE_PROMO_CODE = gql`
  mutation removePromo($input: removePromoInput) {
    removePromo(input: $input) {
      id
      total {
        id
        physical_amount
      }
      sources {
        id
        items {
          id
          key
          value
        }
        source_type_id
        customer_charge {
          id
          physical_amount
          physical_currency
        }
      }
    }
  }
`;
const GET_PAYMENT_FEES = gql`
  query (
    $filter: [filterInput]
    $first: Int!
    $page: Int
    $orderBy: [OrderByClause!]
  ) {
    paymentFees(
      filter: $filter
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        payment_method {
          id
          name
        }
        class_type {
          id
          name
        }
        value
        type
      }
      paginatorInfo {
        count
        currentPage
        lastItem
        lastPage
        perPage
        total
      }
    }
  }
`;
const CREATE_PNR = gql`
  mutation createFlightPnr($input: flightPnrInput) {
    createFlightPnr(input: $input) {
      product_id
      status
      message
      pnr
      deadline
    }
  }
`;

const CREATE_FLIGHT_PNR_ISSUE = gql`
  mutation CreateFlightPnrB2BIssue($input: flightPnrB2BInput) {
    createFlightPnrB2BIssue(input: $input) {
      status
      message
    }
  }
`;

const SEND_MAIL_RESERVATION_CROSS_CC = gql`
  mutation ($id: ID!, $template: String!, $attachment_template: String) {
    sendMailReservationCrossCC(
      id: $id
      template: $template
      attachment_template: $attachment_template
    ) {
      id
    }
  }
`;

const ISSUED_BUS_TICKET = gql`
  mutation busIssueTicket($input: busIssueTicketInput) {
    busIssueTicket(input: $input) {
      id
      reservation {
        id
        status {
          id
          name
        }
      }
      product_type {
        id
        name
        order
      }
      status {
        id
        name
        employee {
          id
          name
        }
      }
      external_id
      group
      parent_id
      description
      travel_date
      issued_date
      deadline
    }
  }
`;
const TRANSFER_COMPANY_ACCOUNT = gql`
  mutation ($input: companyAccountTransferInput) {
    transferCompanyAccount(input: $input) {
      id
      name
      account_code
    }
  }
`;
const FILE_UPLOAD = gql`
  mutation ($files: [Upload!]!) {
    upload(files: $files)
  }
`;
const CREATE_CUSTOM_LOG = gql`
  mutation createCustomLog($input: CustomLogInput) {
    createCustomLog(input: $input) {
      id
      type
      causer {
        id
        name
        email
        company {
          id
          name
          internal_id
        }
        roles {
          id
          name
        }

        group_users {
          id
          name
          email
        }
      }
      description
      updates {
        key
        old
        new
      }
      raw
      created_at
    }
  }
`;

const REPORT_FEEDBACK = gql`
  mutation ($input: feedbackCreateInput) {
    createFeedback(input: $input) {
      id
      description
      causer {
        id
      }
      client {
        id
      }
      attachments {
        id
        filename
      }
    }
  }
`;

const SEND_RESERVATION_SMS = gql`
  mutation ($input: SendEticketSMSInput) {
    SendReservationSMS(input: $input) {
      message
    }
  }
`;

const GET_PAYMENT_METHOD = gql`
  {
    paymentMethods {
      id
      name
      online_status
      is_b2c_only
      external_id_regex
      currencies {
        code
        name
        symbol
      }
      active
      product_types {
        id
        name
      }
    }
  }
`;

const GET_PAYMENT_METHOD_ALL = gql`
  {
    paymentMethodsAll {
      id
      name
      online_status
      active
      is_b2c_only
      external_id_regex
      currencies {
        code
        name
        symbol
      }
      product_types {
        id
        name
      }
    }
  }
`;

const GET_PAYMENT_METHODS_BY_PRODUCT_TYPES = gql`
  query ($product_types: [Int!]!) {
    paymentMethodsByProductTypes(product_types: $product_types) {
      id
      name
      active
      online_status
      is_b2c_only
    }
  }
`;

export {
  CREATE_PAYMENT_QUERY,
  CREATE_PAYMENT_CONFIRM_QUERY,
  CHECK_PAYMENT_QUERY,
  GET_EXCHANGE_RATE_BY_CODE,
  GET_EXCHANGE_RATES,
  APPLY_PROMO_CODE,
  REMOVE_PROMO_CODE,
  GET_PAYMENT_FEES,
  CREATE_B2B_TOPUP_QUERY,
  CREATE_PNR,
  SEND_MAIL_RESERVATION_CROSS_CC,
  ISSUED_BUS_TICKET,
  TRANSFER_COMPANY_ACCOUNT,
  FILE_UPLOAD,
  CREATE_CUSTOM_LOG,
  CREATE_FLIGHT_PNR_ISSUE,
  REPORT_FEEDBACK,
  SEND_RESERVATION_SMS,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_ALL,
  GET_PAYMENT_METHODS_BY_PRODUCT_TYPES,
};
