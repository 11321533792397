import { findIndex } from "lodash";
import queryString from "query-string";
import { formatDateForHotel } from "./date";
import { removeTpKey, removeFilterKey } from "./checkurl";

export const groupBy = (blocks) => {
  let types = [];

  blocks.forEach((block) => {
    const {
      room_id,
      name,
      room_description,
      is_flash_deal,
      photos,
      is_disabled,
      ...others
    } = block;
    const existingIndex = types.findIndex((t) => t.room_id === room_id);
    if (existingIndex >= 0) {
      const extract = types.splice(existingIndex, 1)[0];
      extract.rooms = [...extract.rooms, others];
      types.push(extract);
    } else {
      types.push({
        room_id,
        name,
        room_description,
        is_flash_deal,
        is_disabled,
        photos,
        rooms: [{ ...others, photos }],
      });
    }
  });

  types = types.map((t) => {
    let tempRooms = [];
    t.rooms.forEach((r) => {
      const oldRoomIndex = tempRooms.findIndex(
        (tr) => tr.min_price.price === r.min_price.price,
      );
      if (oldRoomIndex >= 0) {
        if (r.max_occupancy > tempRooms[oldRoomIndex].max_occupancy) {
          tempRooms.splice(oldRoomIndex, 1, r);
        }
      } else {
        tempRooms.push(r);
      }
    });
    const newType = { ...t };
    newType.rooms = tempRooms;
    return newType;
  });

  return types;
};

export const addRoomInfo = (room, roomData, room_id) => {
  let room_index = findIndex(roomData, function (o) {
    return o["room_id"] === room_id;
  });
  if (room && room?.room_info) {
    room.room_info =
      room_index === -1
        ? roomData[0].room_info
        : roomData[room_index].room_info;
  }
  return room;
};

export const addLongRoomInfo = (roomData, room_id, hotelRoomPrices) => {
  hotelRoomPrices = hotelRoomPrices.filter(
    (item) => item.duration_of_stay + ` ` + item.date_type === "1 Month",
  );
  const secondObject = hotelRoomPrices?.reduce((acc, room) => {
    acc[room?.room_id] = room;
    return acc;
  }, {});
  const mergedObject = {
    ...roomData,
    ...secondObject[roomData?.room_id],
  };
  // console.log(
  //   mergedObject,
  //   roomData,
  //   secondObject[roomData?.room_id],
  //   "mergedObject",
  // );
  return mergedObject;
};

export const returnCurrencyValues = (val, currency, type) => {
  if (val === null) {
    return null;
  }
  if (type === "sp") {
    let priceObj = {
      price: val[0] && val[0].sp ? val[0].sp : 0,
    };
    return priceObj;
  }
  if (type === "nb") {
    let priceObj = {
      price: val[0] && val[0].nb ? val[0].nb : 0,
    };
    return priceObj;
  }
  if (type === "dr") {
    let priceObj = {
      price: val[0] && val[0].dr ? val[0].dr : 0,
    };
    return priceObj;
  }
  if (type === "ar") {
    let priceObj = {
      price: val[0] && val[0].ar ? val[0].ar : 0,
    };
    return priceObj;
  }
  if (type === "wr") {
    let priceObj = {
      price: val[0] && val[0].wr ? val[0].wr : 0,
    };
    return priceObj;
  }

  if (type === "priceObj") {
    return val["other_currency"] &&
      val["other_currency"]["currency"] &&
      val["other_currency"]["currency"] === currency?.toUpperCase()
      ? val["other_currency"]
      : val;
  }
  if (type === "currency") {
    return val["other_currency"] &&
      val["other_currency"]["currency"] &&
      val["other_currency"]["currency"] === currency?.toUpperCase()
      ? val["other_currency"]["currency"]
      : val["currency"];
  }
  if (type === "priceVal") {
    return val["other_currency"] &&
      val["other_currency"]["currency"] &&
      val["other_currency"]["currency"] === currency?.toUpperCase()
      ? val["other_currency"]["price"]
      : val["price"];
  }
};

export const formatPrice = (price) => {
  return price?.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const getGuestQuantities = (roomArr, adult, child) => {
  let numOfGuests = Number(adult) + Number(child);
  let maxGuests = roomArr.map((room) => {
    return {
      maxOccupancy: Number(room.maxOccupancy),
      guestCount: 0,
    };
  });
  while (numOfGuests > 0) {
    for (let i = 0; i < maxGuests.length; i++) {
      if (
        numOfGuests > 0 &&
        maxGuests[i].guestCount < maxGuests[i].maxOccupancy
      ) {
        maxGuests[i].guestCount = maxGuests[i].guestCount + 1;
        numOfGuests -= 1;
      }
    }
  }

  return maxGuests.map((guest) => guest.guestCount).join(",");
};

export const getTotalPrice = (hotelDetails) => {
  let total = 0;
  hotelDetails.roomInformationUSD.forEach((room) => {
    let count = Number(room.count);
    let roomPrice = returnCurrencyValues(
      room.incrementalPrice[count - 1],
      "USD",
      "priceVal",
    );
    let price = parseFloat(roomPrice);
    total += price;
  });

  return total;
};

export const getParam = (urlString, param) => {
  const params = queryString.parse(urlString);
  const searchType = params[param];
  if (searchType && searchType !== "") {
    return searchType;
  } else {
    return "";
  }
};

export const commonHandleSearch = (
  isCitizen,
  adult,
  child,
  room,
  checkIn,
  checkOut,
  match,
  selectedItem,
  navigate,
  selectedLanguage,
  isInApp,
  isInWeb,
  hotels,
  // fprefCheck,
  fpoffCheck,
  // fbkCheck,
  fponCheck,
) => {
  let nat = isCitizen ? "l" : "f";
  let currency = isCitizen ? "mmk" : "usd";
  let removeTp =
    window.location.search !== "" ? removeTpKey(window.location.search) : "";
  let newUrl = window.location.search !== "" ? removeFilterKey(removeTp) : "";
  if (adult && room && Object.keys(selectedItem).length > 0) {
    navigate({
      pathname: `/${selectedLanguage}${
        isInApp ? "/in-app" : isInWeb ? "/in-web" : ""
      }/hotel/search/${currency}/${nat}/${encodeURIComponent(
        selectedItem.name,
      )}/${formatDateForHotel(checkIn)}/${formatDateForHotel(
        checkOut,
      )}/${adult}-${child}-${room}`,
      search: window.location.search,
      // search:
      //   window.location.search === ""
      //     ? `${
      //         selectedItem.type === "hotel"
      //           ? `?tp=hotel&tpid=${selectedItem.id}&fpoff=${fpoffCheck}&fpref=true&fbk=true&fpon=${fponCheck}`
      //           : `?fpoff=${fpoffCheck}&fpref=true&fbk=true&fpon=${fponCheck}`
      //       }`
      //     : `${newUrl}${
      //         selectedItem.type === "hotel"
      //           ? `${newUrl === "?" ? "" : "&"}tp=hotel&tpid=${
      //               selectedItem.id
      //             }&fpoff=${fpoffCheck}&fpref=true&fbk=true&fpon=${fponCheck}`
      //           : `${
      //               newUrl === "?" ? "" : "&"
      //             }fpoff=${fpoffCheck}&fpref=true&fbk=true&fpon=${fponCheck}`
      //       }`,
    });
  } else if (hotels.length > 0) {
    navigate({
      pathname: `/${selectedLanguage}${
        isInApp ? "/in-app" : isInWeb ? "/in-web" : ""
      }/hotel/search/${currency}/${nat}/${encodeURIComponent(
        hotels[0].name,
      )}/${formatDateForHotel(checkIn)}/${formatDateForHotel(
        checkOut,
      )}/${adult}-${child}-${room}`,
      search: window.location.search,
      // search:
      //   window.location.search === ""
      //     ? `${
      //         selectedItem.type === "hotel"
      //           ? `?tp=hotel&tpid=${selectedItem.id}&fpoff=${fpoffCheck}&fpref=true&fbk=true&fpon=${fponCheck}`
      //           : `?fpoff=${fpoffCheck}&fpref=true&fbk=true&fpon=${fponCheck}`
      //       }`
      //     : `${newUrl}${
      //         selectedItem.type === "hotel"
      //           ? `${newUrl === "?" ? "" : "&"}tp=hotel&tpid=${
      //               selectedItem.id
      //             }&fpoff=${fpoffCheck}&fpref=true&fbk=true&fpon=${fponCheck}`
      //           : `${
      //               newUrl === "?" ? "" : "&"
      //             }fpoff=${fpoffCheck}&fpref=true&fbk=true&fpon=${fponCheck}`
      //       }`,
    });
  }
};

export const getCurrency = (currency) => (currency === "mmk" ? "MMK" : "USD");

export const isRoomPriceExist = (room) => room && room.flymya_price;

export const isRoomDrZero = (room) =>
  isRoomPriceExist && room.flymya_price[0].dr === 0;
export const isRoomArZero = (room) =>
  isRoomPriceExist && room.flymya_price[0].ar === 0;
export const isRoomWrZero = (room) =>
  isRoomPriceExist && room.flymya_price[0].wr === 0;
export const isRoomSpZero = (room) =>
  isRoomPriceExist && room.flymya_price[0].sp === 0;

export const isRoomDr = (room) => isRoomPriceExist && room.flymya_price[0].dr;
export const isRoomAr = (room) => isRoomPriceExist && room.flymya_price[0].ar;
export const isRoomWr = (room) => isRoomPriceExist && room.flymya_price[0].wr;
export const isRoomSp = (room) => isRoomPriceExist && room.flymya_price[0].sp;

export const getRoomDrPrice = (room) =>
  isRoomDr(room)
    ? room.flymya_price[0].dr.toLocaleString("en-US", {
        minimumFractionDigits: 0,
      })
    : "";
export const getRoomArPrice = (room) =>
  isRoomAr(room)
    ? room.flymya_price[0].ar.toLocaleString("en-US", {
        minimumFractionDigits: 0,
      })
    : "";
export const getRoomWrPrice = (room) =>
  isRoomWr(room)
    ? room.flymya_price[0].wr.toLocaleString("en-US", {
        minimumFractionDigits: 0,
      })
    : "";
export const getRoomSpPrice = (room) =>
  isRoomSp(room)
    ? room.flymya_price[0].sp.toLocaleString("en-US", {
        minimumFractionDigits: 0,
      })
    : "";
