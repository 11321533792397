import { useState } from "react";
import {
  GET_REGIONS,
  GET_CITIES,
  GET_TOWNSHIPS,
} from "../queries/countryquery";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  OneList,
  TwoList,
  ThreeList,
  FourList,
  FiveList,
  SixList,
  SevenList,
  EightList,
  NineList,
  TenList,
  ElevenList,
  TwelveList,
  ThirteenList,
  FourteenList,
} from "../config/data/nrcList";
import { MYANMAR_ID, YANGON_REGION, YANGON_CITY } from "../config/constants";

function MoreInformationHook({ setFieldValue }) {
  const [regionArr, setRegionArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [townshipArr, setTownshipArr] = useState([]);
  const [listArr, setListArr] = useState(OneList);
  const [selectedDivision, setSelectedDivison] = useState("1");
  const [selectedList, setSelectedList] = useState("HsaBaTa");
  const [selectedCardList, setSelectedCardList] = useState("N");
  const [selectedNrcNo, setSelectedNrcNo] = useState("");

  const [getRegion] = useLazyQuery(GET_REGIONS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.regions.data.length > 0
          ? res.regions.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setRegionArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [getCity] = useLazyQuery(GET_CITIES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.cities.data.length > 0
          ? res.cities.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setCityArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [getTownship] = useLazyQuery(GET_TOWNSHIPS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.townships.data.length > 0
          ? res.townships.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setTownshipArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const chooseList = (selected) => {
    switch (selected) {
      case "1":
        return setListArr(OneList);
      case "2":
        return setListArr(TwoList);
      case "3":
        return setListArr(ThreeList);
      case "4":
        return setListArr(FourList);
      case "5":
        return setListArr(FiveList);
      case "6":
        return setListArr(SixList);
      case "7":
        return setListArr(SevenList);
      case "8":
        return setListArr(EightList);
      case "9":
        return setListArr(NineList);
      case "10":
        return setListArr(TenList);
      case "11":
        return setListArr(ElevenList);
      case "12":
        return setListArr(TwelveList);
      case "13":
        return setListArr(ThirteenList);
      case "14":
        return setListArr(FourteenList);
      default:
        return setListArr(OneList);
    }
  };

  const setDefaultList = (selected) => {
    switch (selected) {
      case "1":
        setSelectedList("HsaBaTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaBaTa", "N", "");
        break;
      case "2":
        setSelectedList("BaLaKha");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "BaLaKha", "N", "");
        break;
      case "3":
        setSelectedList("RaRaTha");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "RaRaTha", "N", "");
        break;
      case "4":
        setSelectedList("HsaMaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaMaNa", "N", "");
        break;
      case "5":
        setSelectedList("KhaPaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KhaPaNa", "N", "");
        break;
      case "6":
        setSelectedList("KaLaA");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaLaA", "N", "");
        break;
      case "7":
        setSelectedList("AhPhaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "AhPhaNa", "N", "");
        break;
      case "8":
        setSelectedList("MaHtaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "MaHtaNa", "N", "");
        break;
      case "9":
        setSelectedList("NgaZaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "NgaZaNa", "N", "");
        break;
      case "10":
        setSelectedList("KhaZaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KhaZaNa", "N", "");
        break;
      case "11":
        setSelectedList("TaPaWa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "TaPaWa", "N", "");
        break;
      case "12":
        setSelectedList("AhLaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "AhLaNa", "N", "");
        break;
      case "13":
        setSelectedList("KaTaLa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaTaLa", "N", "");
        break;
      case "14":
        setSelectedList("KaKaHTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaKaHTa", "N", "");
        break;
      default:
        setSelectedList("HsaBaTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaBaTa", "N", "");
        break;
    }
  };

  const handleNrc = (division, list, cardList, nrcNo) => {
    setFieldValue(
      "signupNrc",
      division + "/" + list + "(" + cardList + ")" + nrcNo,
    );
  };

  const getRegionTownshipList = (val) => {
    getRegion({
      variables: {
        first: 30,
        page: 1,
        filter: [
          {
            field: "country.id",
            operator: "=",
            value: String(MYANMAR_ID),
          },
        ],
      },
    });
    getCity({
      variables: {
        first: 30,
        page: 1,
        filter: [
          {
            field: "region_id",
            operator: "=",
            value:
              val && val.regionId
                ? String(val.regionId)
                : String(YANGON_REGION),
          },
        ],
      },
    });
    getTownship({
      variables: {
        first: 30,
        page: 1,
        filter: [
          {
            field: "city_id",
            operator: "=",
            value: val && val.cityId ? String(val.cityId) : String(YANGON_CITY),
          },
        ],
      },
    });
  };

  return {
    getCity,
    getTownship,
    regionArr,
    cityArr,
    setCityArr,
    townshipArr,
    setTownshipArr,
    chooseList,
    listArr,
    setDefaultList,
    setSelectedDivison,
    setSelectedList,
    setSelectedCardList,
    setSelectedNrcNo,
    selectedDivision,
    selectedList,
    selectedCardList,
    selectedNrcNo,
    handleNrc,
    getRegionTownshipList,
  };
}

export default MoreInformationHook;
