import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import { clientV3 } from "../../utils/v3utils";
import { RESET_PASSWORD } from "../../queries/passwordquery";
import { useLocation } from "react-router-dom";
import ResetSuccessModal from "../../components/modal/ResetSuccessModal";
import { removeFromLocalStorage } from "../../utils/logoututil";
import useFlymyaSessionHook from "../../hooks/FlymyaSessionHook";

const ForgetPassword = () => {
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const classes = useStyles();
  const { search } = useLocation();
  let searchParams = new URLSearchParams(search);

  const { handleFlymyaSession } = useFlymyaSessionHook();

  useEffect(() => {
    handleFlymyaSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePasswordTypeChange = () => {
    setShowPassword(!showPassword);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleResetPassword = async () => {
    if (password.length < 8) {
      setPasswordError(true);
      setPasswordErrorMessage("Must be at least 8 characters");
    } else if (password !== confirmPassword) {
      setPasswordError(true);
      setPasswordErrorMessage("Both passwords must match");
    } else {
      setPasswordError(false);
      setPasswordErrorMessage("");
      let resetData = {
        email: searchParams?.get("email"),
        token: searchParams?.get("token"),
        password,
        password_confirmation: confirmPassword,
      };
      try {
        const { data } = await clientV3.mutate({
          mutation: RESET_PASSWORD,
          variables: {
            input: resetData,
          },
        });
        if (data?.updateForgottenPassword?.status === "PASSWORD_UPDATED") {
          removeFromLocalStorage();
          setShowModal(true);
        }
      } catch (error) {
        console.log(error);
        setPasswordError(true);
        setPasswordErrorMessage(error?.message);
      }
    }
  };

  return (
    <>
      <div className={classes.forgetPasswordContainer}>
        {/* <Grid container> */}
        <div className={classes.forgetPassText}>
          <Typography component="h1" variant="h5">
            Create New Password
          </Typography>
        </div>
        <div className={classes.text}>
          <Typography component="h2">
            {" "}
            Your new password must be different from previous used passwords.
          </Typography>
        </div>
        <div className={classes.formWrapper}>
          <div>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel>Password</InputLabel>

              <OutlinedInput
                className={classes.passwordInput}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                fullWidth={false}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "-10px",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordTypeChange}>
                      {showPassword ? (
                        <Visibility className={classes.iconColor} />
                      ) : (
                        <VisibilityOff className={classes.iconColor} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
          </div>
          <div style={{ marginTop: "7px" }}>
            <FormControl className={clsx(classes.margin, classes.textField)}>
              <InputLabel>Confirm Password</InputLabel>

              <OutlinedInput
                className={classes.passwordInput}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                fullWidth={false}
                labelWidth={130}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "-10px",
                }}
              />
            </FormControl>
          </div>
          {passwordError && passwordErrorMessage.length > 0 && (
            <small className={classes.errorText}>{passwordErrorMessage}</small>
          )}
        </div>

        <div className={classes.btnMargin}>
          <Button
            color="primary"
            onClick={handleResetPassword}
            variant="contained"
            className={classes.resetBtn}
          >
            Reset Password
          </Button>
        </div>
        {/* </Grid> */}
      </div>
      <ResetSuccessModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleClose={handleClose}
      />
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    forgetPasswordContainer: {
      display: "flex",
      flexDirection: "column",
      //alignItems: "center"
    },
    forgetPassText: {
      //fontSize: 25,
      fontWeight: "normal",
      marginTop: 50,
      marginBottom: 25,
      textAlign: "center",
    },
    formInputWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: "40ch",
      [theme.breakpoints.down("md")]: {
        width: "38ch",
      },
      [theme.breakpoints.down("sm")]: {
        width: "30ch",
      },
      [theme.breakpoints.down("xs")]: {
        width: "25ch",
      },
    },
    passwordInput: {
      padding: "12px !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconColor: {
      color: "#000 !important",
    },
    text: {
      textAlign: "center",
      width: "100%",
      fontWeight: "initial",
      color: "#666666",
    },
    formWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "30px",
    },

    btnMargin: {
      marginTop: theme.spacing(3),
      textAlign: "center",
      // marginLeft: "25px",

      width: "100%",
    },
    resetBtn: {
      backgroundColor: "#2f80ed !important",
      color: "#fff",
      textTransform: "capitalize",
      width: "30%",
      fontSize: "18px",
      marginLeft: "-10px",
      [theme.breakpoints.down("md")]: {
        width: "38ch",
      },
      [theme.breakpoints.down("sm")]: {
        width: "30ch",
      },
      [theme.breakpoints.down("xs")]: {
        width: "25ch",
      },
    },
    errorText: {
      color: "red",
    },
  }),
);

export default ForgetPassword;
