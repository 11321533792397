import { makeStyles } from "@material-ui/core/styles";

export const HotelInquiryFormWebStyles = makeStyles({
  insuranceFormDiv: {
    position: "relative",
    padding: 10,
    display: "flex",
    flexDirection: "column",
  },
  countryDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  countryWrapper: {
    display: "flex",
    width: "40%",
    padding: "6px 10px",
    backgroundColor: "#fff",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    justifyContent: "space-between",
    marginRight: "2px",
    alignItems: "center",
    "& span": {
      color: "#656565",
      fontSize: "14px",
      fontWeight: 400,
      marginRight: "5px",
    },
    "& select": {
      border: "none",
      fontSize: "16px",
      fontWeight: 700,
      color: "#484848",
      outline: "none",
    },
  },
  totalDiv: {
    display: "flex",
    justifyContent: "center",
  },
  insuranceTotal: {
    width: "40%",
    backgroundColor: "#fff",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 10px",
    "& .priceTitle": {
      fontWeight: "400",
      fontSize: "14px",
      color: "#656565",
    },
    "& .priceInputDiv": {
      padding: "3px 0 5px 0",
      fontSize: "16px",
      fontWeight: 700,
      color: "#484848",
    },
  },
  totalFees: {
    width: "25%!important",
  },
  insurancePassInfo: {
    backgroundColor: "#fff",
    height: "55px",
    borderRadius: "5px",
    marginTop: "2px",
    position: "relative",
    alignSelf: "center",
  },
  insuranceTextInfo: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "10px",
    "& img": {
      width: "150px",
    },
    "& span": {
      color: "#fff",
      fontSize: "14px",
      fontWeight: 600,
      marginLeft: "10px",
      lineHeight: "20px",
    },
  },
});
