import React, { useState, useEffect } from "react";
import { Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { KingBed, PersonSharp, CheckRounded } from "@material-ui/icons";
import { returnCurrencyValues, formatPrice } from "../../../utils/hotelUtil";
// import { triggerEmailHotel } from "../../../utils/gorgiasTicket";
import { IS_B2B, IS_CALL_CENTER } from "../../../config/constants";
import {
  CALL_CENTER_PHONENO,
  CC_PHONENO_LINK,
} from "../../../config/constants";
import OfflineInternationalMsg from "../../modal/OfflineInternationalMsg";
import HotelPassengerFormMobile from "../../../pages/hotellong/HotelPassengerFormMobile";
import CheckMark from "../../../assets/images/check-mark.png";

const HotelBlockCard = ({
  roomName,
  room,
  isCustomPrice,
  showPrice,
  sendGorgiaMessage,
  setCart,
  cart,
  totalPrice,
  setTotalPrice,
  hotelRoomPrices,
  duration_of_stay,
}) => {
  const [selfCount, setSelfCount] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const addToCart = (room) => {
    const existingRoomIndex = cart.findIndex(
      (item) => item.room_id === room.room_id,
    );

    if (existingRoomIndex !== -1) {
      // If the room is already in the cart, remove it
      const updatedCart = cart.filter((item) => item.room_id !== room.room_id);
      setCart(updatedCart);
    } else {
      // If the room is not in the cart, add it
      const updatedCart = [...cart, room];
      setCart(updatedCart);
    }
  };

  useEffect(() => {
    // Calculate the total price of all rooms in the cart whenever the cart state changes
    let totalPrice = 0;
    cart.forEach((item) => {
      totalPrice += item.price;
    });
    setTotalPrice(totalPrice);
  }, [cart]);

  const update_room_price = hotelRoomPrices.filter(
    (item) => item.duration_of_stay + ` ` + item.date_type === duration_of_stay,
  );

  const secondObject = update_room_price?.reduce((acc, room) => {
    acc[room?.room_id] = room;
    return acc;
  }, {});

  return (
    <React.Fragment>
      <Grid container className="room-data flex-col">
        <Grid
          item
          xs={4}
          style={
            room.room_photos &&
            room.room_photos.length > 0 &&
            room.room_photos[0].url_original
              ? {
                  backgroundImage: `url(${room.room_photos[0].url_original})`,
                }
              : {}
          }
          className={
            room.room_photos &&
            room.room_photos.length > 0 &&
            room.room_photos[0].url_original
              ? "block-data-img"
              : "block-data-img no-img"
          }
        />
        <Grid className="room-data-info" item xs={12}>
          <Grid className="flex-col col-1" item xs={6}>
            <div className="name">
              <span>{roomName}</span>
            </div>
            <div className="d-flex align-items-center">
              <i className="fa fa-arrows-alt r-icon" aria-hidden="true" />{" "}
              <span>{room?.room_size}</span>
            </div>
          </Grid>

          <Grid className="flex-col col-2" item xs={6}>
            <div className="d-flex flex-col pricing">
              <span
                className="h-bold price"
                style={isCustomPrice === true ? { marginBottom: "3px" } : {}}
              >
                {secondObject[room?.room_id]?.show_price &&
                secondObject[room?.room_id]?.price !== undefined ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <span>Starting From</span>
                    <span style={{ color: "#00A651", padding: 0 }}>
                      {"$" +
                        " " +
                        formatPrice(secondObject[room?.room_id]?.price) +
                        ` / ${duration_of_stay}`}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 5,
                    }}
                  >
                    <img
                      src={CheckMark}
                      alt="Icon"
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    ></img>
                    <p
                      className="hotel-item-price-label"
                      style={{ color: "#00A651", fontSize: 12 }}
                    >
                      Best Price Guaranteed
                    </p>
                  </div>
                )}
              </span>
            </div>
            <Button
              variant="contained"
              className={`${
                !isButtonActive ? "hotel-btn block-hotel-btn" : "hotel-btn"
              } book-by-online`}
              onClick={() => {
                addToCart(room);
              }}
            >
              {cart.some((item) => item.room_id === room.room_id)
                ? "Selected"
                : "Select"}
            </Button>
            {/* ) : (
              <Button
                variant="contained"
                className={`${
                  !isButtonActive ? "hotel-btn block-hotel-btn" : "hotel-btn"
                } book-by-online`}
                onClick={() => {
                  // console.log("ppp");
                  setShowContactUsModal(true);
                  // sendGorgiaMessage();
                }}
              >
                {t("hotel.mobile_select") !== "hotel.mobile_select" ? (
                  t("hotel.mobile_select")
                )
                  : "Select"}
              </Button>
            )} */}
          </Grid>
        </Grid>
      </Grid>
      {/* <OfflineInternationalMsg
        sendGorgiaMessage={sendGorgiaMessage}
        // searchInfo={localStorage.getItem("gorgiasInfo")}
        // productDetail={hotel}
        showContactUsModal={showContactUsModal}
        setShowContactUsModal={setShowContactUsModal}
      /> */}
    </React.Fragment>
  );
};

export default HotelBlockCard;
