import React, { useLayoutEffect, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Offline from "../../components/offline/Offline";
import { HotelInquiryFormWebStyles } from "./styles/HotelInquiryFormWebStyle";
import InsurancePassengerFormWeb from "../insurance/InsurancePassengerFormWeb";
import KBZMSLogo from "../../assets/images/kbzms.svg";
import HotelPassengerFormWeb from "./HotelPassengerFormWeb";

const HotelInquiryFormWeb = ({
  clientTag,
  handleReservation,
  guestNo,
  setGuestNo,
  duration_of_stay,
  setDOS,
  total,
  setTotal,
  formikRef,
}) => {
  const classes = HotelInquiryFormWebStyles();
  const [offline] = useState(false);
  const [clickSearch, setClickSearch] = useState(false);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  return (
    <>
      <div
        style={
          useWindowSize()[0] > 780
            ? {
                backgroundColor: "#42AEEF",
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                padding: 10,
                marginTop: 25,
              }
            : { margin: 0 }
        }
      >
        <div className={classes.insuranceFormDiv}>
          <Grid container xs={12} sm={12} md={12}>
            <Grid item xs={6} md={6} className={classes.countryDiv}>
              <div className={classes.countryWrapper}>
                <span className="label">{"Number Of Guests"}</span>
                <select
                  value={guestNo}
                  onChange={(e) => {
                    setGuestNo(e.target.value);
                  }}
                >
                  {["1", "2", "3", "4", "5"].length > 0 &&
                    ["1", "2", "3", "4", "5"].map((v, k) => {
                      return (
                        <option key={k} value={v}>
                          {v}
                        </option>
                      );
                    })}
                </select>
              </div>
            </Grid>

            {total !== "0" && (
              <Grid item xs={6} md={6}>
                <div className={classes.countryWrapper}>
                  <span className="label">{"Duration Of Stay"}</span>
                  <select
                    value={duration_of_stay}
                    onChange={(e) => {
                      setDOS(e.target.value);
                    }}
                  >
                    {[
                      "1 Month",
                      "2 Month",
                      "3 Month",
                      "4 Month",
                      "5 Month",
                      "6 Month",
                    ].length > 0 &&
                      [
                        "1 Month",
                        "2 Month",
                        "3 Month",
                        "4 Month",
                        "5 Month",
                        "6 Month",
                      ].map((v, k) => {
                        return (
                          <option key={k} value={v}>
                            {v}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item={true}
            xs={12}
            sm={12}
            className={classes.insurancePassInfo}
            onClick={() => setClickSearch(false)}
            style={{ justifyContent: "center" }}
          >
            <HotelPassengerFormWeb
              handleReservation={handleReservation}
              clientTag={clientTag}
              formikRef={formikRef}
            />
          </Grid>
        </div>
      </div>

      <Offline offline={offline} clickSearch={clickSearch} />
    </>
  );
};

export default HotelInquiryFormWeb;
