import axios from "axios";
import { KBZ, WAVEMONEY, ONEPAY } from "../config/inappclients";
import {
  CALL_CENTER_PHONENO,
  REACT_APP_GORGIAS_TICKET,
} from "../config/constants";
import { getClientTag, checkInApp } from "../utils/checkurl";
export const triggerEmail = (product, gorgiasInfo, isInApp) => {
  // let {
  //   roomName,
  //   currency,
  //   checkIn,
  //   checkOut,
  //   minPriceToShow,
  //   hotelName,
  //   phoneNumber,
  //   tripType,
  //   from,
  //   to,
  //   departureDate,
  //   returnDate,
  //   airline,
  //   flightNo,
  // } = gorgiasInfo;
  // const priceToShow = minPriceToShow ? minPriceToShow.price : null;
  // const todayDate = new Date().toJSON();
  // const hotelBody = `<ul><li>Hotel Name: ${
  //   hotelName ? hotelName : null
  // }</li><li>Room Name: ${roomName}</li><li>Room Price: ${
  //   currency ? currency : null
  // } ${priceToShow}</li><li>Check In: ${
  //   checkIn ? checkIn : null
  // }</li><li>Check Out: ${
  //   checkOut ? checkOut : null
  // }</li><li>User Location: </li><li>User Phone Number: ${phoneNumber}</li><li>Page: ${
  //   window.location.href
  // }</li></ul>`;
  // const flightBody = `<ul><li>Product: Flight</li><li>Oneway/RoundTrip: ${tripType}</li><li>From: ${from}</li><li>To: ${to}</li><li>Departure: ${departureDate}</li><li>Return: ${returnDate}</li><li>Airline: ${airline}</li><li>Flight No: ${flightNo}</li><li>Phone Number: ${phoneNumber}</li><li>Page: ${window.location.href}</li></ul>`;
  // const busBody = `<ul><li>Product: Bus</li><li>Oneway/RoundTrip: ${tripType}</li><li>From: ${from}</li><li>To: ${to}</li><li>Departure: ${departureDate}</li><li>Return: ${returnDate}</li><li>Operator: ${airline}</li><li>Trip No: ${flightNo}</li><li>Phone Number: ${phoneNumber}</li><li>Page: ${window.location.href}</li></ul>`;
  // const balloonBody = `<ul><li>Product: Balloon</li><li>Location: ${from}</li><li>Date: ${departureDate}</li><li>Operator: ${airline}</li><li>Trip No: ${flightNo}</li><li>Phone Number: ${phoneNumber}</li><li>Page: ${window.location.href}</li></ul>`;
  // const client = isInApp ? getClientTag(window.location.search) : "mobile";
  // const returnClientName = (val) => {
  //   switch (val) {
  //     case KBZ:
  //       return "#in_app_kbzpay";
  //     case WAVEMONEY:
  //       return "#in_app_wavemoney";
  //     case ONEPAY:
  //       return "#in_app_onepay";
  //     default:
  //       return "#mobile_view";
  //   }
  // };
  // const tags = [
  //   { name: "#direct_phone_call" },
  //   { name: "#standard_calling" },
  //   { name: returnClientName(client) },
  // ];
  // const options = JSON.stringify({
  //   channel: "email",
  //   closed_datetime: null,
  //   created_datetime: todayDate,
  //   subject: `Prospect call from hotel website to "${CALL_CENTER_PHONENO}"`,
  //   from_agent: true,
  //   tags: tags,
  //   messages: [
  //     {
  //       body_html:
  //         product === "Hotel"
  //           ? hotelBody
  //           : product === "Flight"
  //           ? flightBody
  //           : product === "Bus"
  //           ? busBody
  //           : product === "Balloon"
  //           ? balloonBody
  //           : flightBody,
  //       channel: "email",
  //       created_datetime: todayDate,
  //       from_agent: true,
  //       via: "email",
  //       integration_id: 281640,
  //       uri: "/api/integrations/281640/",
  //       source: {
  //         type: "email",
  //         from: { name: "Flymya Hotels", address: "hotels@flymya.co" },
  //         to: [{ name: "Unknown Customer", address: "noreply@flymya.co" }],
  //       },
  //     },
  //   ],
  // });
  // axios
  //   .create({
  //     baseURL: process.env.REACT_APP_BASE_URL,
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Basic bGl6emllLmNoYWlAZmx5bXlhLmNvOmVjaGFpMjAxOSE=",
  //     },
  //   })
  //   .post(process.env.REACT_APP_GORGIAS_TICKET, options)
  //   .then((response) => console.log(response))
  //   .catch((err) => console.log(err));
};

// export const triggerEmailHotel = (
//   roomName,
//   currency,
//   checkIn,
//   checkOut,
//   minPriceToShow,
//   hotelName,
//   phoneNumber,
// ) => {
//   const priceToShow = minPriceToShow.price ? minPriceToShow.price : 0;
//   const todayDate = new Date().toJSON();
//   const client = checkInApp(window.location.pathname)
//     ? getClientTag(window.location.search)
//     : "mobile";

//   const returnClientName = (val) => {
//     switch (val) {
//       case KBZ:
//         return "#in_app_kbzpay";
//       case WAVEMONEY:
//         return "#in_app_wavemoney";
//       case ONEPAY:
//         return "#in_app_onepay";
//       default:
//         return "#mobile_view";
//     }
//   };
//   const tags = [
//     { name: "#direct_phone_call" },
//     { name: "#room_booking" },
//     { name: returnClientName(client) },
//   ];
//   const options = JSON.stringify({
//     channel: "email",
//     closed_datetime: null,
//     created_datetime: todayDate,
//     subject: `Prospect call from hotel website to "${CALL_CENTER_PHONENO}"`,
//     from_agent: true,
//     tags: tags,
//     messages: [
//       {
//         body_html: `<ul><li>Hotel Name:${hotelName}</li><li>Room Name:${roomName}</li><li>Room Price:${currency} ${priceToShow}</li><li>Check In:${checkIn}</li><li>Check Out:${checkOut}</li><li>User Location:</li><li>User Phone Number:${phoneNumber}</li><li>Page: ${window.location.href}</li></ul>`,
//         channel: "email",
//         created_datetime: todayDate,
//         from_agent: true,
//         via: "email",
//         integration_id: 281640,
//         uri: "/api/integrations/281640/",
//         source: {
//           type: "email",
//           from: { name: "Flymya Hotels", address: "hotels@flymya.co" },
//           to: [{ name: "Unknown Customer", address: "noreply@flymya.co" }],
//         },
//       },
//     ],
//   });
//   axios
//     .create({
//       baseURL: process.env.REACT_APP_BASE_URL,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Basic bGl6emllLmNoYWlAZmx5bXlhLmNvOmVjaGFpMjAxOSE=",
//       },
//     })
//     .post(process.env.REACT_APP_GORGIAS_TICKET, options)
//     .then((response) => console.log(response))
//     .catch((err) => console.log(err));
// };

export const triggerEmailFlight = (
  product,
  phone,
  email,
  name,
  produtDetail,
  returnDate,
  isInApp,
  flightInfo,
  tripType,
) => {
  const todayDate = new Date().toJSON();
  const client = checkInApp(window.location.pathname)
    ? getClientTag(window.location.search)
    : "mobile";
  const returnClientName = (val) => {
    switch (val) {
      case KBZ:
        return "#in_app_kbzpay";
      case WAVEMONEY:
        return "#in_app_wavemoney";
      case ONEPAY:
        return "#in_app_onepay";
      default:
        return "#mobile_view";
    }
  };
  const tags = [
    { name: "#direct_phone_call" },
    { name: "#room_booking" },
    { name: returnClientName(client) },
  ];
  const options = JSON.stringify({
    channel: "email",
    closed_datetime: null,
    created_datetime: todayDate,
    subject: `"Offline booking for flight"- ${phone}`,
    from_agent: true,
    tags: tags,
    messages: [
      {
        body_html:
          flightInfo && flightInfo.route
            ? `<ul><li> Name: ${name}</li><li>Phone: ${phone}</li><li>Email: ${email} </li></ul><h5>Departure Flight Details</h5><ul><li>Airline Name: ${flightInfo.route.company}</li><li>Flight Name: ${flightInfo.route.external_id}</li><li>Flight time<ul><li>Departure Time: ${flightInfo.route.departure_time}</li><li>Arrival Time: ${flightInfo.route.arrival_time}</li></ul><li>Departure Destination: ${flightInfo.route.from_airport}</li><li>Arrival Destination: ${flightInfo.route.to_airport}</li><li>Via Destination: ${flightInfo.route.via}</li><li>Starting from price : ${flightInfo.adult_sp}  ${flightInfo.currency}</li><li>Fare class : Economy</li><li>Baggage Allowance : ${flightInfo.route.baggage_allowance}</li></li></ul><h5>Return Flight Details</h5><ul><li>Airline Name: ${produtDetail.company}</li><li>Flight Name: ${produtDetail.external_id}</li><li>Flight time<ul><li>Departure Time: ${produtDetail.departure_time}</li><li>Arrival Time: ${produtDetail.arrival_time}</li></ul><li>Departure Destination: ${produtDetail.from_airport}</li><li>Arrival Destination: ${produtDetail.to_airport}</li><li>Via Destination: ${produtDetail.via}</li><li>Starting from price : ${produtDetail.fareClasses[0].or}  ${produtDetail.fareClasses[0].currency}</li><li>Fare class : Economy</li><li>Baggage Allowance : ${produtDetail.baggage_allowance}</li></ul><h5>Search Criteria Details </h5><ul><li>From : ${flightInfo.route.from_airport}</li><li>To : ${flightInfo.route.to_airport}</li><li>Departure Date : ${flightInfo.route.departure_date}</li><li>Return Date : ${returnDate}</li><li>Myanmar Citizen : No</li><li>Trip Type : ${tripType}</li><li>Passenger Details</li><ul><li>Adult : ${produtDetail.fareClasses[0].adults}</li><li>Child : ${produtDetail.fareClasses[0].children}</li><li>Infant : ${produtDetail.fareClasses[0].infants}</li></ul></ul>`
            : returnDate !== null
            ? `<ul><li> Name: ${name}</li><li>Phone: ${phone}</li><li>Email: ${email} </li></ul><h5>Departure Flight Details</h5><ul><li>Airline Name: ${produtDetail.company}</li><li>Flight Name: ${produtDetail.external_id}</li><li>Flight time<ul><li>Departure Time: ${produtDetail.departure_time}</li><li>Arrival Time: ${produtDetail.arrival_time}</li></ul><li>Departure Destination: ${produtDetail.from_airport}</li><li>Arrival Destination: ${produtDetail.to_airport}</li><li>Via Destination: ${produtDetail.via}</li><li>Starting from price : ${produtDetail.fareClasses[0].or}  ${produtDetail.fareClasses[0].currency}</li><li>Fare class : Economy</li><li>Baggage Allowance : ${produtDetail.baggage_allowance}</li></li></ul><h5>Search Criteria Details </h5><ul><li>From : ${produtDetail.from_airport}</li><li>To : ${produtDetail.to_airport}</li><li>Departure Date : ${produtDetail.departure_date}</li><li>Return Date : ${returnDate}</li><li>Myanmar Citizen : No</li><li>Trip Type : ${tripType}</li><li>Passenger Details</li><ul><li>Adult : ${produtDetail.fareClasses[0].adults}</li><li>Child : ${produtDetail.fareClasses[0].children}</li><li>Infant : ${produtDetail.fareClasses[0].infants}</li></ul></ul>`
            : `<ul><li> Name: ${name}</li><li>Phone: ${phone}</li><li>Email: ${email} </li></ul><h5>Product Details</h5><ul><li>Airline Name: ${produtDetail.company}</li><li>Flight Name: ${produtDetail.external_id}</li><li>Flight time<ul><li>Departure Time: ${produtDetail.departure_time}</li><li>Arrival Time: ${produtDetail.arrival_time}</li></ul><li>Departure Destination: ${produtDetail.from_airport}</li><li>Arrival Destination: ${produtDetail.to_airport}</li><li>Via Destination: ${produtDetail.via}</li><li>Starting from price : ${produtDetail.fareClasses[0].or}  ${produtDetail.fareClasses[0].currency}</li><li>Fare class : Economy</li><li>Baggage Allowance : ${produtDetail.baggage_allowance}</li></li></ul><h5>Search Criteria Details </h5><ul><li>From : ${produtDetail.from_airport}</li><li>To : ${produtDetail.to_airport}</li><li>Departure Date : ${produtDetail.departure_date}</li><li>Myanmar Citizen : No</li><li>Trip Type : ${tripType}</li><li>Passenger Details</li><ul><li>Adult : ${produtDetail.fareClasses[0].adults}</li><li>Child : ${produtDetail.fareClasses[0].children}</li><li>Infant : ${produtDetail.fareClasses[0].infants}</li></ul></ul>`,
        channel: "email",
        created_datetime: todayDate,
        from_agent: true,
        via: "email",
        integration_id: 281640,
        uri: "/api/integrations/281640/",
        source: {
          type: "email",
          from: { name: "Flymya Offline Flight", address: "booking@flymya.co" },
          to: [{ name: "Unknown Customer", address: "booking@flymya.co" }],
        },
      },
    ],
  });

  axios
    .create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic bGl6emllLmNoYWlAZmx5bXlhLmNvOmVjaGFpMjAxOSE=",
      },
    })
    .post(REACT_APP_GORGIAS_TICKET, options)
    .then((response) => console.log(response))
    .catch((err) => console.log(err));
};
