import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const YearOptionComponent = ({ type, passengerType }) => {
  const { t } = useTranslation();

  let startYear = "";
  let endYear = "";
  let thisYear = moment().format("Y");
  if (type === "exp") {
    startYear = thisYear;
    endYear = Number(startYear) + 20;
  } else {
    startYear = Number(thisYear);
    if (passengerType === "Infant") {
      endYear = Number(thisYear) - 2;
    } else {
      endYear = Number(thisYear) - 100;
    }
  }

  let result = [
    <option key="0000" value="0000" disabled>
      {t("masterPassengerInformation.year") !==
      "masterPassengerInformation.year"
        ? t("masterPassengerInformation.year")
        : "Year"}
    </option>,
  ];

  if (type === "exp") {
    for (let i = startYear; i <= endYear; i++) {
      result.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
  } else {
    for (let i = startYear; i >= endYear; i--) {
      result.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
  }

  return <>{result}</>;
};

export default YearOptionComponent;
