import React from "react";
import { Grid, Modal } from "@material-ui/core";
import { CALL_CENTER_PHONENO } from "../../config/constants";
import closeIcon from "../../assets/images/relief_flight_close_icon.png";
import SuccessImg from "../../assets/images/cc_girl.png";
import ErrorImg from "../../assets/images/car_error.svg";

const FeedbackConfirmModal = ({
  confirmModal,
  setConfirmModal,
  bookingConfirm,
  bookingError,
  classes,
  classPhone,
}) => {
  return (
    <React.Fragment>
      <Modal open={confirmModal} id="reliefModal" className={classes}>
        <Grid className="balloon-confirmation-modal">
          <Grid
            item={true}
            sm={12}
            style={{
              display: "inline-block",
              width: "100%",
            }}
          >
            {bookingConfirm && <h2>Feedback Received!</h2>}
            {bookingError && <h2>Something went wrong! Please try again.</h2>}
            <img
              src={closeIcon}
              alt="close"
              onClick={() => setConfirmModal(false)}
              style={{
                width: "15px",
                height: "15px",
                position: "absolute",
                top: "15px",
                right: "15px",
                zIndex: "1",
              }}
              className="closeImg"
            />
          </Grid>
          {bookingConfirm && (
            <div className="img-region">
              <img
                src={SuccessImg}
                alt="cc-girl-img"
                style={{ maxWidth: "150px" }}
              />
            </div>
          )}
          {bookingConfirm && (
            <p>
              We have received your feedback and would like to thank you for
              writing to us.
            </p>
          )}
          {bookingError && (
            <div className="img-region">
              <img src={ErrorImg} alt="" />
            </div>
          )}
          <p>
            If your feedback is urgent, call our 24-hour hotline number
            <br />
            <span className={classPhone}>{CALL_CENTER_PHONENO}</span> to reach
            customer support rep.
          </p>
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default FeedbackConfirmModal;
