import React from "react";
import { Button, CircularProgress } from "@mui/material";

const CustomerEmailOtpForm = ({
  otpErrMsg,
  emailOtp,
  setEmailOtpErrMsg,
  setEmailOtp,
  validateEmail,
  emailOtpErrMsg,
  clickSendEmailOtp,
  signInWithEmailOtp,
}) => {
  return (
    <div className="infobox">
      <label>Email*</label>
      <input
        className={otpErrMsg !== "" ? "error-textbox" : ""}
        name="Email"
        type="text"
        value={emailOtp}
        autoComplete="off"
        onChange={(e) => {
          setEmailOtpErrMsg("");
          setEmailOtp(e.target.value);
        }}
        onBlur={() => {
          if (!validateEmail(emailOtp)) {
            setEmailOtpErrMsg("Please check your email.");
            return;
          }
          setEmailOtpErrMsg("");
        }}
        placeholder="Email *"
        required
        error={emailOtpErrMsg !== "" ? true : false}
        variant="standard"
      />

      {emailOtpErrMsg !== "" && <p>{emailOtpErrMsg}</p>}
      <br />

      <Button
        color="primary"
        variant="contained"
        className={`btn-send-otp ${
          clickSendEmailOtp ? "dim-btn-send-otp" : ""
        }`}
        onClick={signInWithEmailOtp}
        disabled={clickSendEmailOtp}
      >
        Send {clickSendEmailOtp && <CircularProgress />}
      </Button>
    </div>
  );
};

export default CustomerEmailOtpForm;
