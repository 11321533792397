import * as Yup from "yup";
import { IsBurmese, BurmeseWarningMsg } from "../../utils/isburmese";
import { PhoneValidation } from "../../utils/phoneValidation";

const HotelValidationSchema = (props) => {
  return Yup.object().shape({
    name: Yup.string()
      .test("name", BurmeseWarningMsg(props.translation), (value) => {
        return IsBurmese(value);
      })
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .test("name", "Name is required", (value) => {
        return value?.trim() === "" ? false : true;
      })
      .required("First Name + Middle Name is required."),
    last_name: Yup.string()
      .test("last_name", BurmeseWarningMsg(props.translation), (value) => {
        return IsBurmese(value);
      })
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
      .test("last_name", "Last name is required", (value) => {
        return value?.trim() === "" ? false : true;
      })
      .required("Last Name is required."),
    phone: Yup.string()
      .matches(/^[0-9]\d{4,20}$/, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      })
      .test("phone", "Please use other phones", (value) => {
        if (
          // props.isCallCenter &&
          value &&
          value !== "" &&
          (value.includes("9880441011") ||
            value.includes("9797978888") ||
            value.includes("9797978884"))
        ) {
          return false;
        } else {
          return true;
        }
      })
      // .when("phonePrefix", {
      //   is: "95",
      //   then: Yup.string().test(
      //     "mobileNo",
      //     "Please enter valid number.",
      //     (value) => {
      //       return PhoneValidation(value);
      //     },
      //   ),
      // })
      .when("isCheckEmail", {
        is: (value) => value === true && props.isInApp === true,
        then: Yup.string().required(
          props.translation &&
            props.translation("masterPassengerInformation.required.2") !==
              "masterPassengerInformation.required.2"
            ? props.translation("masterPassengerInformation.required.2")
            : "Phone is required.",
        ),
      })
      .when(["isCheckSMS", "phonePrefix"], {
        is: (isCheckSMS, phonePrefix) =>
          isCheckSMS === true && phonePrefix === "95",
        then: Yup.string().test(
          "mobileNo",
          "Please enter valid number.",
          (value) => {
            return PhoneValidation(value);
          },
        ),
      })
      .when(["isCheckSMS", "phonePrefix"], {
        is: (isCheckSMS, phonePrefix) =>
          isCheckSMS === true && phonePrefix === "95",
        then: Yup.string().test(
          "mobileNo",
          "Please enter other number.",
          (value) => {
            if (
              value === "9880441011" ||
              value === "9797978884" ||
              value === "9797978888"
            ) {
              return false;
            } else {
              return true;
            }
            // return PhoneValidation(value);
          },
        ),
      })
      .when(["isCheckSMS"], {
        is: (isCheckSMS) => isCheckSMS === true,
        then: Yup.string().required(
          props.translation &&
            props.translation("masterPassengerInformation.required.2") !==
              "masterPassengerInformation.required.2"
            ? props.translation("masterPassengerInformation.required.2")
            : "Phone is required.",
        ),
      }),
    email: Yup.string()
      .email(
        props.translation &&
          props.translation("2Search.validation_is_email") !==
            "2Search.validation_is_email"
          ? props.translation("2Search.validation_is_email")
          : "Please enter valid email address.",
      )
      .test("email", BurmeseWarningMsg(props.translation), (value) => {
        return IsBurmese(value);
      })
      .when(["phonePrefix", "isCheckEmail"], {
        is: (phonePrefix, isCheckEmail) =>
          phonePrefix !== "95" || isCheckEmail === true,
        then: Yup.string().required(
          props.translation &&
            props.translation("masterPassengerInformation.required.3") !==
              "masterPassengerInformation.required.3"
            ? props.translation("masterPassengerInformation.required.3")
            : "Email is required.",
        ),
      }),
  });
};

export default HotelValidationSchema;
