import { isMobile } from "react-device-detect";

export const updateGorgiasIcon = () => {
  if (
    window.location.href.includes("in-app") &&
    document.getElementById("gorgias-chat-container")
  ) {
    document
      .getElementById("gorgias-chat-container")
      .classList.add("in-app-gorgias");
  }
  if (
    !window.location.href.includes("in-app") &&
    document.getElementById("gorgias-chat-container")
  ) {
    document
      .getElementById("gorgias-chat-container")
      .classList.add("non-in-app-gorgias");
  }

  var maxRepetitions = 60;
  var repetitionCount = 0;
  var isWindowStyleAppended = false;
  var gorgiasChatInterval = window.setInterval(function () {
    var container = document.querySelector("#gorgias-chat-container");
    var chatButtonHead = container
      ?.querySelector("#chat-button")
      ?.contentWindow.document.querySelector("head");
    // var chatWindowHead = container
    //   ?.querySelector("#chat-window")
    //   ?.contentWindow.document.querySelector("head");
    // var chatCampaignsHead = container
    //   ?.querySelector("#chat-campaigns")
    //   ?.contentWindow.document.querySelector("head");

    if (
      chatButtonHead?.children.length &&
      // chatWindowHead?.children.length &&
      !isWindowStyleAppended
    ) {
      var buttonStyle = document.createElement("style");

      // the custom CSS for the chat button
      buttonStyle.textContent = `
          .messenger-button-iframe-jm50kr[aria-label="Open the chat"] {
            top: 0px;
            left: -9px;
            height: 28px;
            width: 45px;
            background: transparent;
            box-shadow: none;
            opacity:0;
          }
          .messenger-button-iframe-jm50kr[aria-label="Close the chat"] {
            top: 0px;
            left: 0px;
            height: 28px;
            width: 28px;
            /* background: transparent; */
            box-shadow: none;
          } 
          .messenger-button-iframe-1uu14c8[aria-label="Open the chat"] {
            top: 0px;
            left: 0px;
            height: 28px;
            width: 28px;
            background: transparent;
            box-shadow: none;
          }
          .messenger-button-iframe-1lfmu30 {
            opacity: 0;
          }
          .messenger-button-iframe-uscp54 {
            opacity: 0;
          } 
          .messenger-button-iframe-6fytgp {
            padding: 0px 5px;
            font-size: 11px;
            line-height: 11px;
            top: 2px;
            left: 1px;
          } 
        `;
      isMobile && chatButtonHead.appendChild(buttonStyle);

      /*var windowStyles = document.createElement('style');
            windowStyles.textContent = '.whatyouwanttochange {property: value}'; // the custom CSS for the chat window
            chatWindowHead.appendChild(windowStyles);*/

      isWindowStyleAppended = true;
    }

    // if (chatCampaignsHead?.children.length) {
    //   window.clearInterval(gorgiasChatInterval); // this line breaks out of the loop - make sure it's not deleted.

    //   var campaignStyles = document.createElement("style");
    //   campaignStyles.textContent = ".whatyouwanttochange {property: value}"; // the custom CSS for the chat campaigns
    //   chatCampaignsHead.appendChild(campaignStyles);
    // }

    if (++repetitionCount === maxRepetitions) {
      window.clearInterval(gorgiasChatInterval);
    }
  }, 100);
};
