import { makeStyles } from "@material-ui/core/styles";

export const InsuranceHomeStyles = makeStyles((theme) => ({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  insuranceBg: {
    padding: "0px !important",
    //height: 550px;
    //min-height: 100vh;
    minWidth: " 100%",
    "&.bgKbz": {
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "#0b55a8",
      },
      backgroundImage: "none",
      height: "auto !important",
      "& .menuPhone": {
        filter: "brightness(0) invert(1)",
      },
    },
    "&.bgWave": {
      backgroundColor: "#1692c5",
      backgroundImage: "none",
      height: "auto !important",
      "& .menuPhone": {
        filter: "brightness(0) invert(1)",
      },
    },
    "&.bgOnePay": {
      backgroundColor: "#45acab",
      backgroundImage: "none",
      height: "auto !important",
      "& .menuPhone": {
        filter: "brightness(0) invert(1)",
      },
    },

    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      backgroundColor: "transparent",
      backgroundImage: "none",
      // paddingLeft: "16px !important",
      // paddingRight: "16px !important",
    },
    "& .homeContainer": {
      position: "relative",
      flexWrap: "nowrap",
      flexDirection: "column",
      height: "auto !important",
      padding: 0,
      // maxWidth: "1300px",
      width: "unset !important",
      display: "block",
      margin: "auto",
    },
    "& .mobile_homeMenu": {
      [theme.breakpoints.down("xs")]: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        justifyContent: "flex-end",
        paddingTop: 0,
        width: "100%",
        maxHeight: "50px",
        gridColumnGap: "18px",
        columnGap: "18px",
      },
    },
  },
  insuranceAlertModal: {
    "& .insurance-confirmation-modal": {
      width: "40%",
      left: "28vw",
      padding: "10px 20px 30px 15px",
      top: "25%",
      maxWidth: "unset",
      maxHeight: "100%",
      position: "fixed",
      backgroundColor: "#fff",
      outline: "none",
      "& h2": {
        fontFamily: "Roboto",
        fontWeight: 700,
        color: "#008ff7",
        width: "100%",
        textAlign: "center",
        display: "inline-block",
      },
      "& .img-region": {
        display: "inline-block",
        width: "100%",
        textAlign: "center",
        margin: "5px 0 20px 0",
      },
      "& p": {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 400,
        textAlign: "center",
        width: "80%",
        margin: "0 auto",
        lineHeight: "25px",
        marginBottom: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "77%",
        left: "7vw",
        top: "20%",
      },
    },
  },
  insurancePhoneNumber: {
    color: "#4AB0FE",
  },
  insuranceDesc: {
    fontSize: "18px",
    color: "#fff",
    paddingLeft: "40px",
    paddingTop: "20px",
    fontWeight: 500,
  },
  insuranceMbDesc: {
    fontSize: "18px",
    paddingTop: "20px",
    fontWeight: 500,
  },
  insuranceFromMobDiv: {
    textAlign: "center",
    minHeight: "65vh",
    marginTop: "30%",
  },
}));
