import React, { useState } from "react";
import { StarRounded } from "@material-ui/icons";
import MediaQuery from "react-responsive";
import ReactHtmlParser from "react-html-parser";

const HotelDesc = ({ hotel, from }) => {
  const [showDesc, setShowDesc] = useState(false);
  return (
    <div className="HotelDesc">
      {!from && (
        <MediaQuery maxWidth={780}>
          <h3 className="top-pick-heading">Hotel Description</h3>
        </MediaQuery>
      )}
      <MediaQuery minWidth={781}>
        <h3 className="top-pick-heading">{`Get the celebrity treatment with world-class service at ${hotel.name}`}</h3>
      </MediaQuery>
      <MediaQuery maxWidth={780}>
        <h3>{`Get the celebrity treatment with world-class service at ${hotel.name}`}</h3>
      </MediaQuery>
      {hotel.preferred && (
        <div className="d-flex align-items-center">
          <div className="star-circle">
            <StarRounded className="h-icon yellow-icon" />
          </div>
          <p className="rating">One of our top picks in {hotel.city}.</p>
        </div>
      )}
      {/* {hotel.hotel_description &&
        hotel.hotel_description
          .split("\n\n")
          .map((line, i) => <p key={i}>{line}</p>)} */}
      <div className={!showDesc && "d-flex align-items-end"}>
        <div class={`expandable-text ${!showDesc ? "hidden" : ""}`}>
          {hotel?.hotel_description &&
            ReactHtmlParser(hotel?.hotel_description)}
        </div>
        {from && hotel?.hotel_description && (
          <span
            class="see-more-link"
            onClick={() => setShowDesc(!showDesc)}
            style={{ display: "block", marginTop: showDesc && "5px" }}
          >
            {showDesc ? "See less" : "See more"}
          </span>
        )}
      </div>
    </div>
  );
};

export default HotelDesc;
