import React from "react";
import LongStayBlockRow from "./LongStayBlockRow";
import { addLongRoomInfo, groupBy } from "../../utils/hotelUtil";

const LongStayBlockData = ({
  roomData,
  hotelRoomPrices,
  blocks,
  setCart,
  cart,
  totalPrice,
  setTotalPrice,
  selectedLanguage,
  currency,
  nationality,
  blockCounts,
  setBlockCounts,
  handleValueChange,
  isCustomPrice,
  adultChildRoom,
  fullRoomFlag,
  showPrice,
  duration_of_stay,
}) => {
  //   const groupedBlocks = groupBy(blocks);
  if (roomData && roomData.length > 0)
    return (
      <tbody
        className="BlockData"
        style={{
          borderLeft: "1px solid #2d2d2d",
          borderBottom: "1px solid #2d2d2d",
        }}
      >
        {roomData.map((room) => (
          <LongStayBlockRow
            key={room.room_id}
            roomData={roomData}
            hotelRoomPrices={hotelRoomPrices}
            setCart={setCart}
            cart={cart}
            totalPrice={totalPrice}
            setTotalPrice={setTotalPrice}
            block={addLongRoomInfo(
              room,
              room.room_id,
              hotelRoomPrices,
              duration_of_stay,
            )}
            selectedLanguage={selectedLanguage}
            currency={currency}
            blockCounts={blockCounts}
            setBlockCounts={setBlockCounts}
            nationality={nationality}
            handleValueChange={handleValueChange}
            isCustomPrice={isCustomPrice}
            adultChildRoom={adultChildRoom}
            fullRoomFlag={fullRoomFlag}
            showPrice={showPrice}
            duration_of_stay={duration_of_stay}
          />
        ))}
      </tbody>
    );
  else return null;
};

export default LongStayBlockData;
