import React from "react";
import { Grid } from "@material-ui/core";
import { COUNTRIES } from "../../config/data/v3countries";
import DayOptionComponent from "../../components/inputs/DayOptionComponent";
import MonthOptionComponent from "../../components/inputs/MonthOptionComponent";
import YearOptionComponent from "../../components/inputs/YearOptionComponent";
import { useTranslation } from "react-i18next";

const ProfileEditForm = ({
  values,
  date,
  setFieldValue,
  selectedDivision,
  setSelectedDivison,
  setDefaultList,
  chooseList,
  selectedList,
  setSelectedList,
  handleNrc,
  selectedNrcNo,
  selectedCardList,
  setSelectedCardList,
  checkNRC,
  setCheckNRC,
  setSelectedNrcNo,
  DivisionList,
  listArr,
  CardList,
  touched,
  errors,
  getCity,
  getTownship,
  regionArr,
  cityArr,
  townshipArr,
}) => {
  const { t } = useTranslation();

  return (
    <div className="profile-edit-div">
      <Grid item={true} xs={12} sm={12} md={12} className="dobRegion">
        <label>
          {t("masterPassengerInformation.dob") !==
          "masterPassengerInformation.dob"
            ? t("masterPassengerInformation.dob") + " *"
            : "Date of Birth *"}
        </label>
        <div container className="dobSelects">
          <Grid item={true} xs={4} sm={4} md={3}>
            <select
              name="signupDobDay"
              className={
                values.signupDobDay === "00"
                  ? "selectBox placeHolderText"
                  : "selectBox"
              }
              disabled={date ? true : false}
              value={values.signupDobDay}
              onChange={(e) => setFieldValue("signupDobDay", e.target.value)}
            >
              <DayOptionComponent
                dayOption={values.signupDobMonth === "02" ? 29 : 31}
              />
            </select>
          </Grid>
          <Grid item={true} xs={4} sm={4} md={5}>
            <select
              name="signupDobMonth"
              disabled={date ? true : false}
              className={
                values.signupDobMonth === "00"
                  ? "selectBox placeHolderText"
                  : "selectBox"
              }
              value={values.signupDobMonth}
              onChange={(e) => setFieldValue("signupDobMonth", e.target.value)}
            >
              <MonthOptionComponent />
            </select>
          </Grid>
          <Grid item={true} xs={4} sm={4} md={4}>
            <select
              disabled={date}
              name="signupDobYear"
              className={
                values.signupDobYear === "00"
                  ? "selectBox placeHolderText"
                  : "selectBox"
              }
              value={values.signupDobYear}
              onChange={(e) => setFieldValue("signupDobYear", e.target.value)}
            >
              <YearOptionComponent type="dob" />
            </select>
          </Grid>
        </div>
      </Grid>

      <Grid item={true} xs={12} sm={12} md={12}>
        <label>
          {t(`b2c.nationality`) !== "b2c.nationality"
            ? t(`b2c.nationality`) + " *"
            : "Nationality *"}
        </label>
        <select
          className="nationality"
          name="signupNationality"
          disabled={date}
          value={values.signupNationality}
          onChange={(e) => setFieldValue("signupNationality", e.target.value)}
        >
          {COUNTRIES.map((country, i) => {
            return (
              <option key={i} value={country.CountryId}>
                {country.CountryName}
              </option>
            );
          })}
        </select>
      </Grid>

      <Grid item={true} xs={12} sm={12} md={12} className="nrcRegion">
        <label>{t(`b2c.nrc`) !== "b2c.nrc" ? t(`b2c.nrc`) : "NRC"}</label>
        <div container className="nrcSelects">
          <Grid item={true} xs={3} sm={3} md={2}>
            <select
              name="nrcDivision"
              disabled={date}
              defaultValue={5}
              className="selectBox nrcNumberField"
              value={selectedDivision}
              onChange={(e) => {
                setSelectedDivison(e.target.value);
                setDefaultList(e.target.value);
                chooseList(e.target.value);
              }}
            >
              {DivisionList.map((division, key) => {
                return (
                  <option key={key} value={division}>
                    {division}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item={true} xs={5} sm={5} md={4}>
            <select
              name="nrcList"
              disabled={date}
              className="selectBox nrcTextField"
              value={selectedList}
              onChange={(e) => {
                setSelectedList(e.target.value);
                handleNrc(
                  selectedDivision,
                  e.target.value,
                  selectedCardList,
                  selectedNrcNo,
                );
              }}
            >
              {listArr &&
                listArr.map((list, key) => {
                  return (
                    <option key={key} value={list}>
                      {list}
                    </option>
                  );
                })}
            </select>
          </Grid>
          <Grid item={true} xs={4} sm={4} md={2}>
            <select
              disabled={date}
              name="nrcCard"
              className="selectBox nrcNumberField"
              value={selectedCardList}
              onChange={(e) => {
                setSelectedCardList(e.target.value);
                handleNrc(
                  selectedDivision,
                  selectedList,
                  e.target.value,
                  selectedNrcNo,
                );
              }}
            >
              {CardList.map((card, key) => {
                return (
                  <option key={key} value={card}>
                    {card}
                  </option>
                );
              })}
            </select>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={4}>
            <input
              name="nrcNumber"
              disabled={date}
              className="nrcNumber"
              value={selectedNrcNo}
              autoComplete="off"
              onChange={(e) => {
                if (!/^[0-9\b]*$/.test(e.target.value)) {
                  setCheckNRC(true);
                  return;
                }
                setCheckNRC(false);
                setSelectedNrcNo(e.target.value);
                handleNrc(
                  selectedDivision,
                  selectedList,
                  selectedCardList,
                  e.target.value,
                );
              }}
              data-lpignore="true"
            />
            {checkNRC && (
              <p className="signup-error">Please type only number.</p>
            )}
          </Grid>
        </div>
      </Grid>

      <Grid container md={12}>
        <Grid item={true} xs={12} sm={12} md={6} className="nrcRegion">
          <label>
            {t(`b2c.passport`) !== "b2c.passport"
              ? t(`b2c.passport`)
              : "Passport"}
          </label>
          <Grid item={true} sm={12} md={12}>
            <input
              className={
                touched.signupNrcPass && errors.signupNrcPass
                  ? "error-textbox nrcNumber"
                  : "nrcNumber"
              }
              name="signupNrcPass"
              disabled={date}
              type="text"
              value={values.signupNrcPass}
              autoComplete="off"
              onChange={(e) => setFieldValue("signupNrcPass", e.target.value)}
              data-lpignore="true"
              required
            />
          </Grid>
        </Grid>
        <Grid item={true} xs={12} sm={12} md={6} className="nrcRegion">
          <label>
            {t(`b2c.passport_expiration_date`) !==
            "b2c.passport_expiration_date"
              ? t(`b2c.passport_expiration_date`)
              : "Passport Expiration Date"}
          </label>
          <Grid container className="passSelects">
            <Grid item={true} md={3}>
              <select
                disabled={date}
                name="signupExpDay"
                className={
                  values.signupExpDay === "00"
                    ? "selectBox placeHolderText"
                    : "selectBox"
                }
                value={values.signupExpDay}
                onChange={(e) => setFieldValue("signupExpDay", e.target.value)}
              >
                <DayOptionComponent
                  dayOption={values.signupExpMonth === "02" ? 29 : 31}
                />
                {/* {passExpErr?.error && (
                            <p className="signup-error">
                              {passExpErr?.errorMessage}
                            </p>
                          )} */}
              </select>
            </Grid>
            <Grid item={true} md={5}>
              <select
                name="signupExpMonth"
                disabled={date}
                className={
                  values.signupExpMonth === "00"
                    ? "selectBox placeHolderText"
                    : "selectBox"
                }
                value={values.signupExpMonth}
                onChange={(e) =>
                  setFieldValue("signupExpMonth", e.target.value)
                }
              >
                <MonthOptionComponent />
              </select>
            </Grid>
            <Grid item={true} md={4}>
              <select
                name="signupExpYear"
                disabled={date}
                className={
                  values.signupExpYear === "00"
                    ? "selectBox placeHolderText"
                    : "selectBox"
                }
                value={values.signupExpYear}
                onChange={(e) => setFieldValue("signupExpYear", e.target.value)}
              >
                <YearOptionComponent type="exp" />
              </select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container md={12}>
        <Grid item={true} xs={6} sm={6} md={4}>
          <label>
            {t(`b2c.region`) !== "b2c.region" ? t(`b2c.region`) : "Region"}
          </label>
          <select
            name="region"
            disabled={date}
            className="selectBox regionSelect"
            value={values.region}
            onChange={(e) => {
              setFieldValue("region", e.target.value);
              setFieldValue("cityArr", []);
              getCity({
                variables: {
                  first: 30,
                  page: 1,
                  filter: [
                    {
                      field: "region_id",
                      operator: "=",
                      value: e.target.value,
                    },
                  ],
                },
              });
            }}
          >
            {regionArr.map((val, key) => {
              return (
                <option key={key} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </select>
        </Grid>

        <Grid item={true} xs={6} sm={6} md={4}>
          <label>{t(`b2c.city`) !== "b2c.city" ? t(`b2c.city`) : "City"}</label>
          <select
            name="city"
            disabled={date}
            className="selectBox regionSelect"
            value={values.city}
            onChange={(e) => {
              setFieldValue("city", e.target.value);
              setFieldValue("townshipArr", []);
              getTownship({
                variables: {
                  first: 30,
                  page: 1,
                  filter: [
                    {
                      field: "city_id",
                      operator: "=",
                      value: e.target.value,
                    },
                  ],
                },
              });
            }}
          >
            <option value="">Select City</option>
            {cityArr.map((val, key) => {
              return (
                <option key={key} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </select>
        </Grid>

        <Grid item={true} xs={12} sm={12} md={4}>
          <label>
            {t(`b2c.township`) !== "b2c.township"
              ? t(`b2c.township`)
              : "Township"}
          </label>
          <select
            disabled={date}
            name="township"
            className="selectBox regionSelect"
            value={values.township}
            onChange={(e) => setFieldValue("township", e.target.value)}
          >
            <option value="">Select Township</option>
            {townshipArr.map((val, key) => {
              return (
                <option key={key} value={val.value}>
                  {val.label}
                </option>
              );
            })}
          </select>
        </Grid>
      </Grid>

      <Grid item={true} xs={12} sm={12} md={12}>
        <label>
          {t(`b2c.address_details`) !== "b2c.address_details"
            ? t(`b2c.address_details`)
            : "Address Details"}
        </label>
        <textarea
          name="address"
          disabled={date}
          className="address"
          value={values.address}
          autoComplete="off"
          onChange={(e) => setFieldValue("address", e.target.value)}
        ></textarea>
      </Grid>
    </div>
  );
};

export default ProfileEditForm;
