import React from "react";
import { useTranslation } from "react-i18next";

const DayOptionComponent = ({ dayOption }) => {
  const { t } = useTranslation();

  return (
    <>
      <option value="00" disabled>
        {t("masterPassengerInformation.day") !==
        "masterPassengerInformation.day"
          ? t("masterPassengerInformation.day")
          : "Day"}
      </option>

      {Array.apply(0, Array(dayOption)).map(function (x, i) {
        return (
          <option
            key={i}
            value={(i + 1).toString().length === 1 ? "0" + (i + 1) : i + 1}
          >
            {(i + 1).toString().length === 1 ? "0" : ""}
            {i + 1}
          </option>
        );
      })}
    </>
  );
};

export default DayOptionComponent;
