require("./kbzpayjs");
//require("./weui");
require("./js-sdk.min.js");

var baseUrl = "https://api.kbzpay.com/web/gateway/uat";

function autoLogin() {
  //console.log("merchantAppId", merchantAppId);
  //console.log("merchantAppId", "kp5f13fea8db9a4e52906ce5b2eb71e1");
  getMiniAppToken()
    .then((token) => {
      //2. Verify the returned token of the Super APP. If the token is verified, login is not required.
      authToken(token);
    })
    .catch((ex) => {})
    .finally(() => {});
}
//1.get token from Super app
function getMiniAppToken() {
  return new Promise((resolve, reject) => {
    console.log(
      window.ma.native("getMiniAppToken", {
        appId: "kpdc2ce0a8733d412182f1f5ae5c2da3",
      }),
    );
    window.ma
      .native("getMiniAppToken", {
        appId: "kpdc2ce0a8733d412182f1f5ae5c2da3",
      })
      .then((res) => {
        console.log("res", res);
        let data = JSON.parse(res);
        resolve(data.token);
      })
      .catch((ex) => {
        reject(ex);
        console.log("ec", ex);
      });
  });
}
// 3.call H5 service auth token and get userinfo
function authToken(token) {
  //console.log(token);
  window
    .fetch(baseUrl + "/auth/token", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authToken: token,
      }),
    })
    .then((res) => {
      console.log(res);
      res.json().then((json) => {
        let biz = json.biz_content;
        let openId = biz.open_id;
        let identityId = biz.identityId;
        console.log(openId);
        document.getElementById(
          "userinfo",
        ).innerHTML = `openId: <br> <span style='color:red;'>${openId}</span>
      <br>
      identityId: <br> <span style='color:red;'>${identityId}</span>`;
      });
    })
    .finally(() => {
      //loading.hide();

      startPay(2000);
      console.log("authtoken");
    });
}

function startPay(price, result, orderInfo) {
  if (!price) {
    return;
  }
  window
    .fetch(baseUrl + "/create/order", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: "diamond_" + price,
        amount: price + "",
      }),
    })
    .then((res) => {
      console.log("res", res);
      let orderInAppInfo =
        "appid=" +
        result.appid +
        "&merch_code=" +
        result.merch_code +
        "&nonce_str=" +
        result.nonce_str +
        "&prepay_id=" +
        result.prepay_id +
        "&trade_type=InApp" +
        "&timestamp=" +
        result.timestamp;
      // alert(res.result);
      //rawRequest is the signed parameter returned by the backend.
      window.ma.callNativeAPI(
        "startPay",
        {
          prepayid: result.prepay_id,
          orderInfo: orderInfo,
          sign: result.sign,
          signType: "SHA256",
          disableNewCheckout: "true/false",
          tradeType: "MINIAPP",
        },
        (res) => {
          //After the payment is successful, the information is printed.
          console.log(res);
        },
      );
    })
    .finally(() => {
      alert("MINIAPP");
      console.log("startpay");
    });
}

export const KBZH5 = (data, kbzPayToken) => {
  let result = JSON.parse(data);
  let orderInfo =
    "appid=" +
    result.appid +
    "&merch_code=" +
    result.merch_code +
    "&nonce_str=" +
    result.nonce_str +
    "&prepay_id=" +
    result.prepay_id +
    "&timestamp=" +
    result.timestamp;

  let callBackParams = {
    token: kbzPayToken,
    tradeType: "APPH5",
    lang: "en",
  };

  let callBackErrParams = {
    code: "400",
    msg: "Bad Request",
  };

  if (window.location.search.includes("miniapp")) {
    window.ma.callNativeAPI(
      "startPay",
      {
        prepayid: result.prepay_id,
        orderInfo: orderInfo,
        sign: result.sign,
        signType: "SHA256",
        disableNewCheckout: "true/false",
        tradeType: "MINIAPP",
      },
      (res) => {
        //After the payment is successful, the information is printed.
        console.log(res);
      },
    );
  } else {
    window.kbzpay.startPayCallback(callBackParams);
    window.kbzpay.startPayCallbackError(callBackErrParams);
    window.kbzpay.startPay(
      result.prepay_id,
      orderInfo,
      result.sign,
      window.kbzpay.startPayCallback,
      window.kbzpay.startPayCallbackError,
    );
  }

  return true;
};
