import React, { useState } from "react";
import { toPairs, forEach } from "lodash";

const MpitesanRedirectForm = ({ refName, mpitesanInfo }) => {
  const [flag, setFlag] = useState(false);
  let result = mpitesanInfo && mpitesanInfo !== undefined ? mpitesanInfo : null;
  let formData = [];
  let arrValue = [];
  if (result) {
    arrValue = toPairs(result).map(([key, value]) => ({
      key,
      value,
    }));
    forEach(arrValue, (arr) => {
      formData.push(<input type="hidden" name={arr.key} value={arr.value} />);
      //formData.push({ key: arr.key, value: arr.value });
    });

    // var keys = Object.keys(result);
    // keys.forEach(function (key) {
    //   formData.push(result[key]);
    // });
    // return formData;
  }

  if (mpitesanInfo && document.getElementById("myForm")) {
    !flag && document.getElementById("myForm").submit();
    setFlag(true);
  }

  return (
    <>
      <form
        id="myForm"
        ref={refName}
        action="https://pgw.ooredoo.com.mm/MPGEnduserportal/mpitesan/payment"
        method="post"
      >
        {mpitesanInfo && (
          <>
            <input
              type="hidden"
              name={"merchant_id"}
              value={mpitesanInfo?.merchant_id}
            />
            <input
              type="hidden"
              name={"order_id"}
              value={mpitesanInfo?.order_id}
            />
            <input
              type="hidden"
              name={"product_name"}
              value={mpitesanInfo?.product_name}
            />
            <input
              type="hidden"
              name={"product_description"}
              value={mpitesanInfo?.product_description}
            />
            <input type="hidden" name={"msisdn"} value={mpitesanInfo?.msisdn} />
            <input type="hidden" name={"amount"} value={mpitesanInfo?.amount} />
            <input
              type="hidden"
              name={"currency"}
              value={mpitesanInfo?.currency}
            />
            <input
              type="hidden"
              name={"callbackurl1"}
              value={mpitesanInfo?.callbackurl1}
            />
            <input
              type="hidden"
              name={"callbackurl2"}
              value={mpitesanInfo?.callbackurl2}
            />
            <input
              type="hidden"
              name={"signature"}
              value={mpitesanInfo?.signature}
            />
          </>
        )}
      </form>
    </>
  );
};

export default MpitesanRedirectForm;
