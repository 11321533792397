import React, { useState, useEffect } from "react";
import "../../assets/scss/LongBlockRow.scss";
import LongStayRoomRow from "./LongStayRoomRow";
import { Modal } from "@material-ui/core";
import HotelImageGallery from "./HotelImageGallery";
import CloseIcon from "@material-ui/icons/Close";
import { addLongRoomInfo, formatPrice } from "../../utils/hotelUtil";

const LongStayBlockRow = ({
  block,
  roomData,
  hotelRoomPrices,
  selectedLanguage,
  currency,
  blockCounts,
  setBlockCounts,
  nationality,
  handleValueChange,
  isCustomPrice,
  adultChildRoom,
  fullRoomFlag,
  showPrice,
  setCart,
  cart,
  totalPrice,
  setTotalPrice,
  duration_of_stay,
}) => {
  const {
    room_name,
    room_description,
    room_photos,
    rooms,
    room_id,
    show_price,
  } = block;
  const [modal, setModal] = useState(false);

  const addToCart = (room) => {
    const existingRoomIndex = cart.findIndex(
      (item) => item.room_id === room.room_id,
    );

    if (existingRoomIndex !== -1) {
      // If the room is already in the cart, remove it
      const updatedCart = cart.filter((item) => item.room_id !== room.room_id);
      setCart(updatedCart);
    } else {
      // If the room is not in the cart, add it
      const updatedCart = [...cart, room];
      setCart(updatedCart);
    }
  };

  useEffect(() => {
    // Calculate the total price of all rooms in the cart whenever the cart state changes
    let totalPrice = 0;
    cart.forEach((item) => {
      totalPrice += item.price;
    });
    setTotalPrice(totalPrice);
  }, [cart]);

  const update_room_price = hotelRoomPrices.filter(
    (item) => item.duration_of_stay + ` ` + item.date_type === duration_of_stay,
  );

  const secondObject = update_room_price?.reduce((acc, room) => {
    acc[room?.room_id] = room;
    return acc;
  }, {});

  return (
    <tr className="BlockRow" style={{ borderBottom: "none" }}>
      <td
        colSpan="1"
        className="d-flex justify-content-center align-items-center"
      >
        <input
          type="checkbox"
          onChange={() => {
            addToCart(block);
          }}
        />
      </td>
      <td className="type-col">
        <div
          // className="room-type-fake-col"
          style={{ paddingLeft: 5, paddingRight: 5 }}
        >
          <p className="h-bold room-type-name fg-blue">{room_name}</p>
          {room_photos && room_photos.length > 0 && (
            <div className="room-photo-region">
              {room_photos.slice(0, 3).map((photo, pkey) => {
                return (
                  <img
                    src={photo.url_original}
                    alt=""
                    key={pkey}
                    onClick={() => setModal(true)}
                  />
                );
              })}
              {room_photos.length > 4 && (
                <span onClick={() => setModal(true)}>more</span>
              )}
              <Modal
                open={modal}
                onClose={() => setModal(!modal)}
                className="room-gallery-modal"
              >
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="modal-image-wrap"
                    style={{
                      boxSizing: "border-box",
                      margin: "15px",
                      width: "100%",
                    }}
                  >
                    <span
                      onClick={() => setModal(!modal)}
                      className="room-close-icon"
                    >
                      <CloseIcon className="h-icon white-icon" />
                    </span>
                    <HotelImageGallery imgs={room_photos} />
                  </div>
                </div>
              </Modal>
            </div>
          )}
          {room_description && <small>{room_description}</small>}
        </div>
      </td>
      <td colSpan="1">
        <div className="d-flex flex-col align-items-stretch room-row-stack">
          {/* {roomData &&
            roomData.map((room) => {
              console.log(room, roomData, "room...");
              return ( */}
          <LongStayRoomRow
            key={block.block_id}
            selectedLanguage={selectedLanguage}
            currency={currency}
            blockCounts={blockCounts}
            setBlockCounts={setBlockCounts}
            room={block}
            //   room={addLongRoomInfo(room, room.room_id, hotelRoomPrices)}
            nationality={nationality}
            handleValueChange={handleValueChange}
            isCustomPrice={isCustomPrice}
            adultChildRoom={adultChildRoom}
            fullRoomFlag={fullRoomFlag}
            showPrice={showPrice}
          />
          {/* );
            })} */}
        </div>
      </td>
      <td>
        <div
          style={{
            padding: "8px 15px",
          }}
        >
          <div className="d-flex">
            {secondObject[room_id]?.show_price &&
            secondObject[room_id]?.price !== undefined ? (
              <div
                style={{
                  marginBottom: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span className="hotel-item-price-label">Starting From</span>
                <span className="hotel-item-price">
                  {"$" +
                    " " +
                    formatPrice(secondObject[room_id]?.price) +
                    ` / ${duration_of_stay}`}
                </span>
              </div>
            ) : (
              // <span>{"$" + " " + formatPrice(block?.price)}</span>
              <span style={{ fontSize: 14 }}>Best price guaranteed</span>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default LongStayBlockRow;
