import queryString from "query-string";
import { isArray } from "lodash";

export const checkInApp = (pathName) => {
  let arr = pathName.split("/");
  return arr.length > 0 && (arr[1] === "in-app" || arr[2] === "in-app")
    ? true
    : false;
};
export const checkInWeb = (pathName) => {
  let arr = pathName.split("/");
  return arr.length > 0 &&
    (arr[1] === "in-web" ||
      arr[2] === "in-web" ||
      arr[1] === "in-web/" ||
      arr[2] === "in-web/")
    ? true
    : false;
};
export const checkPromotion = (pathName) => {
  let arr = pathName.split("/");
  return arr.length > 0
    ? checkInApp(pathName) || checkInWeb(pathName)
      ? arr[4]
      : arr[3]
    : "";
};

export const checkProductForPaymentPage = (pathName) => {
  let arr = pathName.split("/");
  return arr.length > 1
    ? arr[2] === "flight"
      ? "flight"
      : arr[2] === "hotel"
      ? "hotel"
      : arr[2] === "hotel-long-stay"
      ? "hotel-long-stay"
      : arr[2] === "bus"
      ? "bus"
      : arr[3] === "vip-service"
      ? "vip"
      : arr[3] === "visa-service"
      ? "visa"
      : arr[2] === "insurance"
      ? "insurance"
      : arr[2] === "tour-packages"
      ? "tour"
      : arr[2] === "car-rental"
      ? "car"
      : arr[2] === "balloon"
      ? "balloon"
      : ""
    : "";
};
export const checkProductForInWebPaymentPage = (pathNameInWeb) => {
  let arr = pathNameInWeb.split("/");
  return arr.length > 1
    ? arr[3] === "flight"
      ? "flight"
      : arr[3] === "hotel"
      ? "hotel"
      : arr[3] === "bus"
      ? "bus"
      : arr[4] === "vip-service"
      ? "vip"
      : arr[4] === "visa-service"
      ? "visa"
      : arr[3] === "insurance"
      ? "insurance"
      : arr[3] === "tour-packages"
      ? "tour"
      : arr[3] === "car-rental"
      ? "car"
      : arr[3] === "balloon"
      ? "balloon"
      : ""
    : "";
};

export const checkForInAppRedirect = (pathName) => {
  let arr = pathName.split("/");
  return arr.length > 0 && arr[1] === "in-app" ? true : false;
};

export const getUserId = (urlString) => {
  const params = queryString.parse(urlString);
  const clientTag = params.UserID;
  if (clientTag && clientTag !== "") {
    let check = clientTag.substring(0, 2);
    return check === "95" ? clientTag.substring(2) : clientTag;
  } else {
    return "";
  }
};

export const getClientTag = (urlString) => {
  const params = queryString.parse(urlString);
  const clientTag = params.client;
  return clientTag && clientTag !== "" ? clientTag : "";
};

export const getReservationId = (urlString) => {
  const params = queryString.parse(urlString);
  const reservation_id = params.resid;
  return reservation_id && reservation_id !== "" ? reservation_id : "";
};

export const getCustomerName = (urlString) => {
  const params = queryString.parse(urlString);
  const customerName = params.customerName;
  return customerName && customerName !== "" ? customerName : "";
};

export const getCustomerPhoneNo = (urlString) => {
  const params = queryString.parse(urlString);
  const phoneNumber = params.phoneNumber;
  return phoneNumber && phoneNumber !== "" ? phoneNumber : "";
};
export const getCampaignId = (urlString) => {
  const params = queryString.parse(urlString);
  const campaignId = params.flymya_campaign_id;
  return campaignId && campaignId !== "" ? campaignId : "";
};
export const getutmId = (urlString) => {
  const params = queryString.parse(urlString);
  const utmId = params.utm_id;
  return utmId && utmId !== "" ? utmId : "";
};
export const getUtmCampaign = (urlString) => {
  const params = queryString.parse(urlString);
  const utmCampaign = params.utm_campaign;
  const val = utmCampaign
    ? decodeURIComponent(utmCampaign.replace("%20", "+"))
    : "";
  return val && val !== "" ? val : "";
};
export const getDepartureUUID = (departureUUID) => {
  return departureUUID ? departureUUID : "";
};

export const isInAppUrl = (isInApp, selectedLanguage, product) => {
  return isInApp
    ? selectedLanguage !== undefined
      ? "/" + selectedLanguage + "/in-app/" + product
      : "/my-mm/in-app/" + product
    : selectedLanguage !== undefined
    ? "/" + selectedLanguage + "/" + product
    : "/my-mm/" + product;
};
export const isInWebUrl = (isInWeb, selectedLanguage, product) => {
  return isInWeb
    ? selectedLanguage !== undefined
      ? "/" + selectedLanguage + "/in-web/" + product
      : "/my-mm/in-web/" + product
    : selectedLanguage !== undefined
    ? "/" + selectedLanguage + "/" + product
    : "/my-mm/" + product;
};
export const isInWebUrlWithoutProduct = (isInWeb, selectedLanguage) => {
  return isInWeb
    ? selectedLanguage !== undefined
      ? "/" + selectedLanguage + "/in-web/"
      : "/my-mm/in-web/"
    : selectedLanguage !== undefined
    ? "/" + selectedLanguage + "/"
    : "/my-mm/";
};
export const isInAppUrlWithoutProduct = (isInApp, selectedLanguage) => {
  return isInApp
    ? selectedLanguage !== undefined
      ? "/" + selectedLanguage + "/in-app/"
      : "/my-mm/in-app/"
    : selectedLanguage !== undefined
    ? "/" + selectedLanguage + "/"
    : "/my-mm/";
};

export const getLocale = (locale) => {
  if (locale === undefined) {
    return "en";
  } else {
    return locale && locale !== "in-app" ? locale : "en";
  }
};

export const getKBZPayToken = (urlString) => {
  const params = queryString.parse(urlString);
  const token = params.kbzpay_token;
  return token && token !== "" ? token : "";
};

export const getCustomerPhoneNoKBZ = (string) => {
  const params = queryString.parse(string);
  const phoneNumber = params.customerPhone;
  return phoneNumber && phoneNumber !== "" ? phoneNumber : "";
};

export const getCustomerPhoneNoUabPay = (string) => {
  const params = queryString.parse(string);
  const phoneNumber = params.UserID;
  return phoneNumber && phoneNumber !== "" ? phoneNumber : "";
};

export const getCustomerPhoneNoCitizenPay = (string) => {
  const params = queryString.parse(string);
  const phoneNumber = params.phoneNumber;
  return phoneNumber && phoneNumber !== "" ? phoneNumber : "";
};

export const removeQueryStringByKey = (url) => {
  const params = queryString.parse(url);
  params.tp && delete params.tp;
  params.tpid && delete params.tpid;
  params.cp && delete params.cp;
  return `?${queryString.stringify(params)}`;
};
export const removeCpKey = (url) => {
  const params = queryString.parse(url);
  params.cp && delete params.cp;
  return `?${queryString.stringify(params)}`;
};
export const removeTpKey = (url) => {
  const params = queryString.parse(url);
  params.tp && delete params.tp;
  params.tpid && delete params.tpid;
  return `?${queryString.stringify(params)}`;
};
export const removeResIdKey = (url) => {
  const params = queryString.parse(url);
  params.resid && delete params.resid;
  return `?${queryString.stringify(params)}`;
};
export const removeFilterKey = (url) => {
  const params = queryString.parse(url);
  params.fpoff && delete params.fpoff;
  params.fpref && delete params.fpref;
  params.fbk && delete params.fbk;
  params.fpon && delete params.fpon;
  return `?${queryString.stringify(params)}`;
};
export const removeDuplicatedKey = (url) => {
  const params = queryString.parse(url);
  params.tp = params.tp ? (isArray(params.tp) ? params.tp[0] : params.tp) : "";
  params.tpid = params.tpid
    ? isArray(params.tpid)
      ? params.tpid[0]
      : params.tpid
    : "";
  params.cp = params.cp ? (isArray(params.cp) ? params.cp[0] : params.cp) : "";
  return `?${queryString.stringify(params)}`;
};

export const updateQueryValueByKey = (url, keyName, value) => {
  const params = queryString.parse(url);
  params[keyName] && (params[keyName] = value);
  return `?${queryString.stringify(params)}`;
};
export const updateQueryValueBySpecificKey = (url, value) => {
  const params = queryString.parse(url);
  params.fpref && (params.fpref = "true");
  params.fpoff && (params.fpoff = value);
  params.fbk && (params.fbk = "true");
  params.fpon && (params.fpon = value);
  return `?${queryString.stringify(params)}`;
};

export const getRedirectUrl = (urlString) => {
  const params = queryString.parse(urlString);
  const redirectUrl = params.redirect;
  return redirectUrl && redirectUrl !== "" ? redirectUrl : "";
};

//Start Get Viber Param Info
export const getViberParam = (urlString) => {
  const paramArr = urlString.slice(urlString.indexOf("?") + 1).split("&");
  const params = {};
  paramArr.map((param) => {
    const [key, val] = param.split("=");
    params[key] = val ? decodeURIComponent(val.replace("%20", "+")) : "";
    return param;
  });
  const arranger_id = params.arranger_id;
  const channel = params.channel;
  const viber_name = params.viber_name;
  const viber_id = params.viber_id;
  const viber_avatar = params.viber_avatar;
  const viber_language = params.viber_language;
  const viber_country = params.viber_country;
  const viber_api = params.viber_api;
  return {
    arranger_id: arranger_id && arranger_id !== "" ? arranger_id : "",
    channel: channel && channel !== "" ? channel : "",
    viber_name: viber_name && viber_name !== "" ? viber_name : "",
    viber_id: viber_id && viber_id !== "" ? viber_id : "",
    viber_avatar: viber_avatar && viber_avatar !== "" ? viber_avatar : "",
    viber_language:
      viber_language && viber_language !== "" ? viber_language : "",
    viber_country: viber_country && viber_country !== "" ? viber_country : "",
    viber_api: viber_api && viber_api !== "" ? viber_api : "",
  };
};
//End Get Viber Param Info
