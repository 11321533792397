import React, { useState, useRef } from "react";
import Modal from "@material-ui/core/Modal";
import ImageGallery from "react-image-gallery";
import MediaQuery from "react-responsive";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const HotelImageGallery = ({ imgs, mobile = false }) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(imgs[0].url_original);
  const [imageIndex, setImageIndex] = useState(0);
  const hotelImageGallery = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!imgs || !imgs.length) return null;

  const images =
    mobile === false
      ? imgs.map((img) => ({
          original: img.url_original,
          thumbnail: img.url_square60,
          originalClass: "originalImg",
          thumbnailClass: "thumbnailImg",
        }))
      : imgs.map((img) => ({
          original: img.url_max300 ? img.url_max300 : img.url_original,
          thumbnail: img.url_square60,
          originalClass: "originalImg",
          thumbnailClass: "thumbnailImg",
        }));

  return (
    <div className="HotelImageGallery">
      <MediaQuery minWidth={781}>
        <ImageGallery items={images} showFullscreenButton={false} />
      </MediaQuery>
      <MediaQuery maxWidth={780}>
        <ImageGallery
          ref={hotelImageGallery}
          items={images}
          showFullscreenButton={false}
          onThumbnailClick={(e, index) => {
            setItem(images[index]);
            setImageIndex(index);
            handleOpen();
          }}
          renderItem={() => {}}
          showPlayButton={false}
        />
        {imageIndex < 3 && (
          <div className="img-count">
            <span>{`+${images.length}`}</span>
          </div>
        )}

        <Modal open={open} onClose={handleClose} className="img-gallery-modal">
          <div
            style={{ height: "100vh", display: "flex", alignItems: "center" }}
          >
            <div
              className="modal-image-wrap-long"
              style={{
                boxSizing: "border-box",
                margin: "15px",
                width: "100%",
              }}
            >
              <span onClick={handleClose} className="white-icon ">
                <CloseIcon className="h-icon white-icon" />
              </span>
              <div className="d-flex align-items-center justify-content-between">
                <span
                  onClick={() => {
                    let index = hotelImageGallery.current.getCurrentIndex();
                    if (index !== 0) {
                      setItem(images[index - 1]);
                      setImageIndex(index - 1);
                      hotelImageGallery.current.slideToIndex(index - 1);
                    }
                  }}
                  className="left-arrow"
                >
                  <KeyboardArrowLeftIcon className="h-icon white-icon" />
                </span>
                <img
                  src={item.original}
                  alt="description"
                  style={{
                    width: "100%",
                  }}
                />
                <span
                  onClick={() => {
                    let index = hotelImageGallery.current.getCurrentIndex();
                    if (index !== images.length - 1) {
                      setItem(images[index + 1]);
                      setImageIndex(index + 1);
                      hotelImageGallery.current.slideToIndex(index + 1);
                    }
                  }}
                  className="right-arrow"
                >
                  <KeyboardArrowRightIcon className="h-icon white-icon" />
                </span>
              </div>
            </div>
          </div>
        </Modal>
      </MediaQuery>
    </div>
  );
};

export default HotelImageGallery;
