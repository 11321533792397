import React, { useState } from "react";
import { Grid, Divider, Button, Paper, Slide } from "@material-ui/core";
import { returnCurrencyValues } from "../../../utils/hotelUtil";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";

const HotelMobilePricePanel = (props) => {
  const customerCurrency = props.currency.toUpperCase();
  const roomCart = props.roomCart;
  const totalPrice = props.roomCartTotal;
  const totalRooms = props.roomCartRooms;
  const totalNights = props.totalNights;
  const taxes =
    roomCart[0].taxes &&
    roomCart[0].taxes !== null &&
    roomCart[0].taxes !== undefined
      ? roomCart[0].taxes.split(",")
      : null;
  const isCustomPrice = props.isCustomPrice;

  const [expandMore, setExpandMore] = useState(true);

  const toggle = () => setExpandMore(!expandMore);

  const formatPrice = (price) => {
    return price.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const calculateTax = (price, value, isFormat) => {
    let val = parseFloat(value) / 100;
    let p = parseFloat(price);
    let total = val * p;

    if (!isFormat) {
      return total;
    }
    return formatPrice(total);
  };
  const calculateTotalTax = (price, taxes, isFormat) => {
    let total = 0;
    taxes.forEach((tax) => {
      total += calculateTax(price, tax, false);
    });
    if (!isFormat) {
      return total;
    }
    return formatPrice(total);
  };

  const calculateTotalRoomPriceInOther = (arr, currency) => {
    let total = 0;
    arr.forEach((room) => {
      total += returnCurrencyValues(room.allPrices, currency, "priceVal");
    });
    return formatPrice(total);
  };

  return (
    <React.Fragment>
      <Slide
        direction="up"
        in={props.isOpen}
        className="slideContainter hotelPricePanel"
        style={{
          height: "100vh",
          padding: "15px",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <Paper>
          <span onClick={props.closePricePanel}>
            <CloseIcon />
          </span>
          <Grid item xs={12} s={12} md={4}>
            <div className="hotel-sidebar-card">
              <div className="hotel-sidebar-cardTitle">
                <span>Price Details</span>
              </div>
              <div className="hotel-sidebar-cardBody">
                <div className="priceSummary d-flex justify-content-between">
                  <span>
                    {`${totalRooms} ${
                      totalRooms > 1 ? "Rooms" : "Room"
                    } x ${totalNights} ${totalNights > 1 ? "Nights" : "Night"}`}
                  </span>
                  {isCustomPrice ? (
                    <span>
                      {customerCurrency} {formatPrice(totalPrice)}
                    </span>
                  ) : (
                    <span>
                      {customerCurrency}{" "}
                      {formatPrice(
                        totalPrice -
                          calculateTotalTax(totalPrice, taxes, false),
                      )}
                    </span>
                  )}
                </div>
                {taxes !== null && !isCustomPrice && (
                  <React.Fragment>
                    <div className="d-flex priceSummary  justify-content-between align-items-center">
                      <span
                        onClick={() => toggle()}
                        className="align-items-center"
                      >
                        {"Taxes & Fees "}
                        {expandMore ? (
                          <ExpandLessIcon className="expandIcon" />
                        ) : (
                          <ExpandMoreIcon className="expandIcon" />
                        )}
                      </span>
                      <span>
                        {customerCurrency}{" "}
                        {calculateTotalTax(totalPrice, taxes, true)}
                      </span>
                    </div>
                    {expandMore && (
                      <div className="dropdown">
                        {taxes.map((tax, i) => {
                          return (
                            <div
                              className="d-flex priceSummary justify-content-between"
                              key={`tax${i}`}
                            >
                              <span>{tax}</span>
                              <span>
                                {customerCurrency}{" "}
                                {calculateTax(totalPrice, tax, true)}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </React.Fragment>
                )}

                <Divider />
                <div className="d-flex priceSummary align-items-center  justify-content-between">
                  <span>Price in {customerCurrency}</span>
                  <span className="fg-blue main">
                    {`${customerCurrency} ${formatPrice(totalPrice)}`}
                  </span>
                </div>
                <Divider />
                {isCustomPrice === false && (
                  <>
                    {customerCurrency !== "USD" && (
                      <div className="priceSummary d-flex justify-content-between">
                        <span>Price in USD</span>
                        <span>
                          {"USD "}
                          {calculateTotalRoomPriceInOther(roomCart, "USD")}
                        </span>
                      </div>
                    )}

                    <div>
                      <span className="underText">
                        {
                          "* This price is converted to show you the approximate cost in "
                        }
                        <strong>USD</strong>. {"You'll pay in "}
                        <strong>USD</strong>
                        {customerCurrency === "USD" ? "." : " or "}
                        {customerCurrency !== "USD" && (
                          <strong>{customerCurrency}.</strong>
                        )}
                      </span>
                      <br />
                      <span className="underText">
                        Bear in mind that your card issuer may charge you a
                        foreign transaction fee.
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <Button
              variant="contained"
              className="doneBtn"
              onClick={props.closePricePanel}
              style={{
                marginTop: isCustomPrice ? "50px" : "unset",
              }}
            >
              Close
            </Button>
          </Grid>
        </Paper>
      </Slide>
    </React.Fragment>
  );
};

export default HotelMobilePricePanel;
