import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Grid from "@material-ui/core/Grid";

const LoadingResultComponent = () => (
  <SkeletonTheme color="#f1f1f1" highlightColor="#f9f9f9">
    <Grid container className="loadingWrapper">
      <Grid item sm={4} xs={7} className="infoSection">
        <span>
          <Skeleton width={"80%"} height={12} duration={2} />
        </span>
        <br />
        <span>
          <Skeleton width={"60%"} height={12} />
        </span>
        <br />
      </Grid>
      <Grid item sm={3} xs={5}>
        <span>
          <Skeleton width={"80%"} height={12} />
        </span>
        <br />
        <span className="airlnelbl">
          <Skeleton width={"60%"} height={12} />
        </span>
      </Grid>
      <Grid item sm={3} xs={6} className="duration">
        <span>
          <Skeleton width={150} height={12} />
        </span>
        <br />
        <span className="airlnelbl">
          <Skeleton width={100} height={12} />
        </span>
      </Grid>
      <Grid item sm={2} xs={6}>
        <span>
          <Skeleton width={120} height={12} />
        </span>
        <br />
        <span className="airlnelbl">
          <Skeleton width={100} height={12} />
        </span>
      </Grid>
      <Grid item sm={3} xs={6} style={{ marginTop: 30 }}>
        <span>
          <Skeleton width={100} height={12} />
        </span>
      </Grid>
      <Grid item sm={3} xs={6} style={{ marginTop: 30 }}>
        <span>
          <Skeleton width={100} height={12} />
        </span>
      </Grid>
    </Grid>
  </SkeletonTheme>
);

export default LoadingResultComponent;
