import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//const BEUrl = process.env.REACT_APP_BE_SERVER;

const getLangObj = () => {
  let newObj = {};
  fetch(`https://cdn.flymya.com/lang.json`)
    .then((res) => res.json())
    .then((data) => {
      //console.log(data);
      Object.assign(newObj, data);
    })
    .catch((err) => {
      console.log(err.message);
      return {};
    });
  return newObj;
};

const lngKey = window.location.href.split("/")[3];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: getLangObj(),
    lng: lngKey && lngKey !== "" ? lngKey : "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
