/* eslint-disable */
!(function (t) {
  var e = {};
  function o(n) {
    if (e[n]) return e[n].exports;
    var a = (e[n] = { i: n, l: !1, exports: {} });
    return t[n].call(a.exports, a, a.exports, o), (a.l = !0), a.exports;
  }
  (o.m = t),
    (o.c = e),
    (o.d = function (n, a, t) {
      o.o(n, a) || Object.defineProperty(n, a, { enumerable: !0, get: t });
    }),
    (o.r = function (n) {
      "undefined" != typeof Symbol &&
        Symbol.toStringTag &&
        Object.defineProperty(n, Symbol.toStringTag, { value: "Module" }),
        Object.defineProperty(n, "__esModule", { value: !0 });
    }),
    (o.t = function (a, n) {
      if ((1 & n && (a = o(a)), 8 & n)) return a;
      if (4 & n && "object" == typeof a && a && a.__esModule) return a;
      var t = Object.create(null);
      if (
        (o.r(t),
        Object.defineProperty(t, "default", { enumerable: !0, value: a }),
        2 & n && "string" != typeof a)
      )
        for (var e in a)
          o.d(
            t,
            e,
            function (n) {
              return a[n];
            }.bind(null, e),
          );
      return t;
    }),
    (o.n = function (n) {
      var a =
        n && n.__esModule
          ? function () {
              return n.default;
            }
          : function () {
              return n;
            };
      return o.d(a, "a", a), a;
    }),
    (o.o = function (n, a) {
      return Object.prototype.hasOwnProperty.call(n, a);
    }),
    (o.p = ""),
    o((o.s = 0));
})([
  function (n, a, t) {
    t.r(a);
    var e = {};
    t.r(e),
      t.d(e, "version", function () {
        return c;
      }),
      t.d(e, "platform", function () {
        return s;
      }),
      t.d(e, "language", function () {
        return u;
      }),
      t.d(e, "supportUnicode", function () {
        return l;
      }),
      t.d(e, "appName", function () {
        return f;
      }),
      t.d(e, "PLATFORM_ANDROID", function () {
        return b;
      }),
      t.d(e, "PLATFORM_IOS", function () {
        return y;
      }),
      t.d(e, "APP_CUSTOMER", function () {
        return k;
      }),
      t.d(e, "APP_PARTNER", function () {
        return g;
      });
    var o = {};
    t.r(o),
      t.d(o, "getAppPlatform", function () {
        return d;
      }),
      t.d(o, "getAppVersion", function () {
        return w;
      }),
      t.d(o, "getAppLanguage", function () {
        return m;
      }),
      t.d(o, "getAppSupportUnicode", function () {
        return z;
      }),
      t.d(o, "getAppName", function () {
        return v;
      }),
      t.d(o, "close", function () {
        return N;
      }),
      t.d(o, "openBrowser", function () {
        return h;
      }),
      t.d(o, "hideToolBar", function () {
        return _;
      }),
      t.d(o, "showToolBar", function () {
        return P;
      }),
      t.d(o, "gotoFunction", function () {
        return A;
      });
    var i = {};
    t.r(i),
      t.d(i, "startPay", function () {
        return O;
      }),
      t.d(i, "startPayCallback", function () {
        return S;
      }),
      t.d(i, "startPayCallbackError", function () {
        return F;
      });
    var p = {};
    t.r(p),
      t.d(p, "requestPin", function () {
        return C;
      }),
      t.d(p, "getContactPhoneNumber", function () {
        return j;
      }),
      t.d(p, "back", function () {
        return J;
      }),
      t.d(p, "shareSocialNetwork", function () {
        return V;
      }),
      t.d(p, "shareSocialNetworkCallback", function () {
        return U;
      }),
      t.d(p, "startScan", function () {
        return L;
      }),
      t.d(p, "getFaceVerification", function () {
        return T;
      }),
      t.d(p, "readContact", function () {
        return W;
      });
    var r = {};
    t.r(r),
      t.d(r, "openLocation", function () {
        return B;
      });
    var c = void 0,
      s = void 0,
      u = void 0,
      l = void 0,
      f = void 0,
      b = "android",
      y = "ios",
      k = "customer",
      g = "partner";
    function d() {
      return this.platform
        ? this.platform
        : kbzpayapp.getPlatform instanceof Function
        ? ((this.platform = kbzpayapp.getPlatform()), this.platform)
        : void (
            kbzpayapp.getPlatformAsync instanceof Function &&
            kbzpayapp.getPlatformAsync(
              JSON.stringify({
                callback: "window.kbzpay.getAppPlatformCallback",
              }),
            )
          );
    }
    function w() {
      return this.version
        ? this.version
        : window.kbzpayapp.getVersion instanceof Function
        ? ((this.version = window.kbzpayapp.getVersion()), this.version)
        : void (
            window.kbzpayapp.getVersionAsync instanceof Function &&
            window.kbzpayapp.getVersionAsync(
              JSON.stringify({
                callback: "window.kbzpay_getAppVersionCallback",
              }),
            )
          );
    }
    function m() {
      return this.language
        ? this.language
        : kbzpayapp.getLanguage instanceof Function
        ? ((this.language = kbzpayapp.getLanguage()), this.language)
        : void (
            kbzpayapp.getLanguageAsync instanceof Function &&
            kbzpayapp.getLanguageAsync(
              JSON.stringify({
                callback: "window.kbzpay_getAppLanguageCallback",
              }),
            )
          );
    }
    function z() {
      return this.supportUnicode
        ? this.supportUnicode
        : kbzpayapp.getUnicode instanceof Function
        ? ((this.supportUnicode = kbzpayapp.getUnicode()), this.supportUnicode)
        : void (
            kbzpayapp.getUnicodeAsync instanceof Function &&
            kbzpayapp.getUnicodeAsync(
              JSON.stringify({
                callback: "window.kbzpay_getAppSupportUnicodeCallback",
              }),
            )
          );
    }
    function v() {
      return this.appName
        ? this.appName
        : kbzpayapp.getAppName instanceof Function
        ? ((this.appName = kbzpayapp.getAppName()), this.appName)
        : void (
            kbzpayapp.getAppNameAsync instanceof Function &&
            kbzpayapp.getAppNameAsync(
              JSON.stringify({
                callback: "window.kbzpay_getAppNameAsyncCallback",
              }),
            )
          );
    }
    function N() {
      window.kbzpayapp &&
        (kbzpayapp.evaluate instanceof Function
          ? (kbzpayapp.evaluate(
              JSON.stringify({ functionName: "js_fun_closeWebview" }),
            ),
            kbzpayapp.evaluate(
              JSON.stringify({ functionName: "js_fun_closeWebView" }),
            ))
          : window.kbzpayapp.closeWebview instanceof Function
          ? window.kbzpayapp.closeWebview()
          : window.kbzpayapp.closeWebView instanceof Function
          ? window.kbzpayapp.closeWebView()
          : window.kbzpayapp.close());
    }
    function h(n) {
      n
        ? window.kbzpayapp &&
          (kbzpayapp.evaluate instanceof Function
            ? kbzpayapp.evaluate(
                JSON.stringify({
                  functionName: "js_fun_openNewWindow",
                  params: { url: n },
                }),
              )
            : window.kbzpayapp.openNewWindow &&
              window.kbzpayapp.openNewWindow(n))
        : console.log("url is empty");
    }
    function _() {
      kbzpayapp.evaluate instanceof Function
        ? kbzpayapp.evaluate(
            JSON.stringify({ functionName: "js_fun_hideToolBar" }),
          )
        : kbzpayapp.hideToolBar instanceof Function && kbzpayapp.hideToolBar();
    }
    function P() {
      kbzpayapp.evaluate instanceof Function
        ? kbzpayapp.evaluate(
            JSON.stringify({ functionName: "js_fun_showToolBar" }),
          )
        : kbzpayapp.showToolBar instanceof Function && kbzpayapp.showToolBar();
    }
    function A(n, a) {
      if (
        (n || console.log("gotoFunction command is empty"),
        kbzpayapp.evaluate instanceof Function)
      ) {
        var t = {};
        if (a)
          try {
            t = JSON.parse(a);
          } catch (n) {
            return void console.log("jsonParams is not json");
          }
        kbzpayapp.evaluate(
          JSON.stringify({
            functionName: "js_fun_gotoFunction",
            params: { command: n, commandParams: t },
          }),
        );
      } else
        kbzpayapp.showToolBar instanceof Function &&
          kbzpayapp.gotoFunction(n, a);
    }
    function O(n, a, t, e, o) {
      e instanceof Function
        ? (this.startPay_callback = e)
        : (this.startPay_callback = void 0),
        o instanceof Function
          ? (this.startPay_callback_error = o)
          : (this.startPay_callback_error = void 0),
        n && a && t
          ? kbzpayapp
            ? kbzpayapp.evaluate instanceof Function
              ? kbzpayapp.evaluate(
                  JSON.stringify({
                    functionName: "js_fun_startPay",
                    params: { prepayId: n, orderInfo: a, sign: t },
                  }),
                )
              : kbzpayapp.startPay instanceof Function &&
                kbzpayapp.startPay(n, a, t)
            : this.startPay_callback_error &&
              this.startPay_callback_error(10, "not found kbzpay environment")
          : console.log("parameters have empty value");
    }
    function S(n) {
      if (this.startPay_callback instanceof Function) {
        n &&
          n.toString().startsWith("(") &&
          2 <= n.length &&
          (n = n.substr(1, n.length - 2));
        var a = JSON.parse(JSON.stringify(n));
        this.startPay_callback(a);
      }
    }
    function F(n, a) {
      this.startPay_callback_error instanceof Function &&
        this.startPay_callback_error({ code: n, msg: a });
    }
    function C(n) {
      kbzpayapp.evaluate instanceof Function
        ? ((this.requestPinCallback = n),
          kbzpayapp.evaluate(
            JSON.stringify({
              functionName: "js_fun_requestPin",
              params: { callback: "window.kbzpay.requestPinCallback" },
            }),
          ))
        : kbzpayapp.requestPin instanceof Function
        ? ((this.requestPinCallback = n),
          kbzpayapp.requestPin(
            JSON.stringify({ callback: "window.kbzpay.requestPinCallback" }),
          ))
        : console.log("kbzpayapp.requestPin is empty");
    }
    function j(n) {
      var a = this.getAppVersion(),
        t = this.getAppName();
      if (a) {
        var e = parseFloat(this.version);
        if (t) {
          if (t === g && e <= 3.2)
            return void console.log("current version is not support");
          if (t === k && e <= 2.4)
            return void console.log("current version is not support");
        } else if (e <= 2.4)
          return void console.log("current version is not support");
        kbzpayapp.evaluate instanceof Function
          ? ((this.getContactPhoneNumberCallback = n),
            kbzpayapp.evaluate(
              JSON.stringify({
                functionName: "js_fun_getContactPhoneNumber",
                params: {
                  callback: "window.kbzpay.getContactPhoneNumberCallback",
                },
              }),
            ))
          : kbzpayapp.getContactPhoneNumber instanceof Function
          ? ((this.getContactPhoneNumberCallback = n),
            kbzpayapp.getContactPhoneNumber(
              JSON.stringify({
                callback: "window.kbzpay.getContactPhoneNumberCallback",
              }),
            ))
          : console.log("kbzpayapp.getContactPhoneNumber is empty");
      } else console.log("current version is empty");
    }
    function J() {
      kbzpayapp.evaluate instanceof Function
        ? kbzpayapp.evaluate(JSON.stringify({ functionName: "js_fun_back" }))
        : kbzpayapp.back instanceof Function && kbzpayapp.back();
    }
    function V(n, a, t) {
      n && "" !== n.trim()
        ? (t instanceof Function && (this.shareSocialNetworkCallback = t),
          kbzpayapp.evaluate instanceof Function
            ? kbzpayapp.evaluate(
                JSON.stringify({
                  functionName: "js_fun_shareSocialNetwork",
                  params: { shareUrl: n, appId: a },
                }),
              )
            : kbzpayapp.shareSocialNetwork instanceof Function &&
              (a
                ? kbzpayapp.shareSocialNetwork(n, a)
                : kbzpayapp.shareSocialNetwork(n)))
        : console.log("function shareSocialNetwork shareUrl is empty");
    }
    function U(n) {}
    function L(n) {
      var a = this.getAppVersion(),
        t = this.getAppName();
      if (!a)
        return (
          console.log("current version empty"),
          void (n && n('{"result":"-2", "msg":"current version empty"}'))
        );
      if (!t)
        return (
          console.log("current appName is empty"),
          void (n && n('{"result":"-2", "msg":"current appName is empty"}'))
        );
      if (t !== k && t !== g)
        return (
          console.log("current platform is not support"),
          void (
            n && n('{"result":"-2", "msg":"current platform is not support"}')
          )
        );
      var e = parseFloat(a);
      if (t === k && e <= 2.5) {
        if ("2.5.2" !== a)
          return (
            console.log("current version is not support"),
            void (
              n && n('{"result":"-2", "msg":"current version is not support"}')
            )
          );
      } else if (t === g && e <= 3.2 && "3.2.2" !== a)
        return (
          console.log("current version is not support"),
          void (
            n && n('{"result":"-2", "msg":"current version is not support"}')
          )
        );
      kbzpayapp.evaluate instanceof Function
        ? ((this.startScanCallback = n),
          kbzpayapp.evaluate(
            JSON.stringify({
              functionName: "js_fun_startScan",
              params: { callback: "window.kbzpay.startScanCallback" },
            }),
          ))
        : console.log("kbzpayapp.startScan is empty");
    }
    function T(n, a, t, e) {
      var o = this.getAppVersion(),
        i = this.getAppName();
      if (!o)
        return (
          console.log("current version empty"),
          void (n && n('{"result":"-2", "msg":"current version empty"}'))
        );
      if (!i)
        return (
          console.log("current appName is empty"),
          void (n && n('{"result":"-2", "msg":"current appName is empty"}'))
        );
      if (i !== k && i !== g)
        return (
          console.log("current platform is not support"),
          void (
            n && n('{"result":"-2", "msg":"current platform is not support"}')
          )
        );
      var p = parseFloat(o);
      if (i === k && p <= 2.5) {
        if ("2.5.2" !== o)
          return (
            console.log("current version is not support"),
            void (
              n && n('{"result":"-2", "msg":"current version is not support"}')
            )
          );
      } else if (i === g && p <= 3.2 && "3.2.2" !== o)
        return (
          console.log("current version is not support"),
          void (
            n && n('{"result":"-2", "msg":"current version is not support"}')
          )
        );
      var r = { callback: "window.kbzpay.faceVerificationCallback" };
      (r.compare = a ? "1" : "0"),
        t && (r.targetWidth = t),
        e && (r.targetHeight = e),
        kbzpayapp.evaluate instanceof Function
          ? ((this.faceVerificationCallback = n),
            kbzpayapp.evaluate(
              JSON.stringify({
                functionName: "js_fun_faceVerification",
                params: r,
              }),
            ))
          : console.log("kbzpayapp.faceVerificationCallback is empty");
    }
    function W(n) {
      kbzpayapp.evaluate instanceof Function
        ? ((this.readContactCallback = n),
          kbzpayapp.evaluate(
            JSON.stringify({
              functionName: "js_fun_readContact",
              params: { callback: "window.kbzpay.readContactCallback" },
            }),
          ))
        : console.log("kbzpayapp.readContact is empty");
    }
    function B(n) {
      kbzpayapp.evaluate instanceof Function
        ? ((this.openLocationCallback = n),
          kbzpayapp.evaluate(
            JSON.stringify({
              functionName: "js_fun_openLocation",
              params: { callback: "window.kbzpay.openLocationCallback" },
            }),
          ))
        : console.log("kbzpayapp.openLocation is empty");
    }
    function q(a, n) {
      var t = Object.keys(a);
      if (Object.getOwnPropertySymbols) {
        var e = Object.getOwnPropertySymbols(a);
        n &&
          (e = e.filter(function (n) {
            return Object.getOwnPropertyDescriptor(a, n).enumerable;
          })),
          t.push.apply(t, e);
      }
      return t;
    }
    function M(n, a, t) {
      return (
        a in n
          ? Object.defineProperty(n, a, {
              value: t,
              enumerable: !0,
              configurable: !0,
              writable: !0,
            })
          : (n[a] = t),
        n
      );
    }
    window.kbzpay = (function (a) {
      for (var n = 1; n < arguments.length; n++) {
        var t = null != arguments[n] ? arguments[n] : {};
        n % 2
          ? q(Object(t), !0).forEach(function (n) {
              M(a, n, t[n]);
            })
          : Object.getOwnPropertyDescriptors
          ? Object.defineProperties(a, Object.getOwnPropertyDescriptors(t))
          : q(Object(t)).forEach(function (n) {
              Object.defineProperty(
                a,
                n,
                Object.getOwnPropertyDescriptor(t, n),
              );
            });
      }
      return a;
    })(
      {},
      e,
      {},
      new (function () {
        setTimeout(function () {
          window.kbzpayapp &&
            window.kbzpayapp.getPlatformAsync instanceof Function &&
            ((window.kbzpay_getAppPlatformCallback = function (n) {
              window.kbzpay.platform = n;
            }),
            window.kbzpayapp.getPlatformAsync(
              JSON.stringify({
                callback: "window.kbzpay_getAppPlatformCallback",
              }),
            )),
            window.kbzpayapp &&
              window.kbzpayapp.getVersionAsync instanceof Function &&
              ((window.kbzpay_getAppVersionCallback = function (n) {
                window.kbzpay.version = n;
              }),
              window.kbzpayapp.getVersionAsync(
                JSON.stringify({
                  callback: "window.kbzpay_getAppVersionCallback",
                }),
              )),
            window.kbzpayapp &&
              window.kbzpayapp.getLanguageAsync instanceof Function &&
              ((window.kbzpay_getAppLanguageCallback = function (n) {
                window.kbzpay.language = n;
              }),
              window.kbzpayapp.getLanguageAsync(
                JSON.stringify({
                  callback: "window.kbzpay_getAppLanguageCallback",
                }),
              )),
            window.kbzpayapp &&
              window.kbzpayapp.getUnicodeAsync instanceof Function &&
              ((window.kbzpay_getAppSupportUnicodeCallback = function (n) {
                window.kbzpay.supportUnicode = n;
              }),
              window.kbzpayapp.getUnicodeAsync(
                JSON.stringify({
                  callback: "window.kbzpay_getAppSupportUnicodeCallback",
                }),
              )),
            window.kbzpayapp &&
              window.kbzpayapp.getAppNameAsync instanceof Function &&
              ((window.kbzpay_getAppNameAsyncCallback = function (n) {
                window.kbzpay.appName = n;
              }),
              window.kbzpayapp.getAppNameAsync(
                JSON.stringify({
                  callback: "window.kbzpay_getAppNameAsyncCallback",
                }),
              ));
        }, 100);
      })(),
      {},
      o,
      {},
      i,
      {},
      p,
      {},
      r,
    );
  },
]);
