import { gql } from "apollo-boost";

const CREATE_TEST_CHECKOUT_RESERVATION = gql`
  mutation createTestReservation($input: reservationTestCheckoutInput) {
    createTestReservation(input: $input) {
      id
      products {
        id
        status {
          id
          name
        }
      }
    }
  }
`;

export { CREATE_TEST_CHECKOUT_RESERVATION };
