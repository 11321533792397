import React from "react";
import { Button } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import MediaQuery from "react-responsive";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { isMobile } from "react-device-detect";

const HotelBackButton = ({
  history,
  city,
  checkInFormattedDate,
  checkOutFormattedDate,
  duration,
  pageName,
  selectedLanguage,
  isInApp,
  isInWeb,
  showHotelDetail,
  setShowHotelDetail,
}) => {
  let navigate = useNavigate();
  return (
    <MediaQuery maxWidth={780}>
      {pageName === "HotelSearch" ? (
        <Link
          to={{
            pathname: isInApp
              ? "/" + selectedLanguage + "/in-app/hotel"
              : isInWeb
              ? "/" + selectedLanguage + "/in-web/hotel"
              : "/" + selectedLanguage + "/hotel",
            search: window.location.search,
          }}
        >
          <Button className="hotel-back-btn" style={{ zIndex: "3" }}>
            <ArrowBackIcon />
            {/* <span className="backText">
              {city}
              {checkInFormattedDate && checkOutFormattedDate && duration ? (
                <>
                  <br />
                  <span className="hotelDuration">
                    {checkInFormattedDate} - {checkOutFormattedDate} ({duration}
                    )
                  </span>
                </>
              ) : (
                ""
              )}
            </span> */}
          </Button>
        </Link>
      ) : (
        <Button
          onClick={() => {
            !isMobile
              ? setShowHotelDetail({ ...showHotelDetail, show: false })
              : navigate(-1);
          }}
          className="hotel-back-btn"
          style={{ zIndex: "3" }}
        >
          <ArrowBackIcon />
          {/* <span className="backText">
            {city}
            {checkInFormattedDate && checkOutFormattedDate && duration ? (
              <>
                <br />
                <span className="hotelDuration">
                  {checkInFormattedDate} - {checkOutFormattedDate} ({duration})
                </span>
              </>
            ) : (
              ""
            )}
          </span> */}
        </Button>
      )}
    </MediaQuery>
  );
};
export default HotelBackButton;
