import knayi from "knayi-myscript";

export const IsBurmese = (value) => {
  knayi.setGlobalOptions({ silent_mode: true });
  if (
    knayi.fontDetect(value) === "unicode" ||
    knayi.fontDetect(value) === "zawgyi"
  ) {
    return false;
  } else {
    return true;
  }
};

export const BurmeseWarningMsg = (translation) => {
  return translation &&
    translation("2Search.validation_is_burmese") !==
      "2Search.validation_is_burmese"
    ? translation("2Search.validation_is_burmese")
    : "Please enter in English.";
};
