import React from "react";

const FlymyaIconLoading = (props) => (
  <div className="loadingGifDiv">
    <div className="loadingGifDivInner">
      &nbsp;
      {props?.loadingMessage && (
        <p className="loadingMessage">{props?.loadingMessage}</p>
      )}
    </div>
  </div>
);

export default FlymyaIconLoading;
