import { checkInApp, getClientTag } from "../utils/checkurl";

export const HandleGorgias = () => {
  checkInApp(window.location.pathname) &&
    process.env[
      "REACT_APP_" + getClientTag(window.location.search) + "_GORGIAS"
    ] === "false" &&
    document.body.classList.add("homeInApp");
  return true;
};

export const HandleMenu =
  process.env[
    "REACT_APP_" + getClientTag(window.location.search) + "_SHOWMENU"
  ];

export const HandleFlag =
  process.env[
    "REACT_APP_" + getClientTag(window.location.search) + "_SHOWFLAG"
  ];
