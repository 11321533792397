import React from "react";

const IconWrapper = ({
  children,
  Icon,
  className,
  style,
  iconClassName,
  iconStyle,
}) => {
  return (
    <div
      className={"IconWrapper " + (className ? className : "")}
      style={style}
    >
      {children}
      {Icon && (
        <div className="icon-div-wrapper">
          <Icon className={iconClassName} style={iconStyle} />
        </div>
      )}
    </div>
  );
};

export default IconWrapper;
