import { useCallback } from "react";
import { IS_B2B, IS_CUSTOMER, LOGIN_PEOPLE_COMPANY } from "../config/constants";

let classTypeId =
  localStorage.getItem(IS_B2B) === "yes" &&
  JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)) !== null &&
  JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)).class_type &&
  JSON.parse(localStorage.getItem(LOGIN_PEOPLE_COMPANY)).class_type.id;

function useComponentHasPermission({ isInApp }) {
  const componentSourceId = isInApp ? "3" : "1";
  const getIdByClassType =
    localStorage.getItem(IS_B2B) === "yes" && classTypeId !== "7"
      ? classTypeId
      : localStorage.getItem(IS_B2B) === "yes" && classTypeId === "7"
      ? "1"
      : localStorage.getItem(IS_CUSTOMER) === "yes"
      ? "3"
      : "5";

  const asynGetPermission = useCallback(async () => {
    let permissionArr = [];
    let permissionArrId = [];
    fetch(
      `https://cdn.flymya.com/component_has_permission/chp_${componentSourceId}_${getIdByClassType}.json`,
    )
      .then((res) => res.json())
      .then((data) => {
        //console.log("data=====>", data.permissions);
        data.permissions.map((val) =>
          permissionArr.push(
            val.permission.name === "eWallets"
              ? val.permission.name.replace(val.permission.name, "Flights")
              : val.permission.name,
          ),
        );
        data.permissions.map((val) => permissionArrId.push(val.permission.id));
        localStorage.setItem("permissionArr", JSON.stringify(permissionArr));
        localStorage.setItem(
          "permissionArrId",
          JSON.stringify(permissionArrId),
        );
        //Object.assign(newObj, data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [componentSourceId, getIdByClassType]);

  return {
    asynGetPermission,
  };
}

export default useComponentHasPermission;
