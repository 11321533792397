import { gql } from "apollo-boost";

export const AUTOCOMPLETE_SEARCH_QUERY = gql`
  mutation ($input: hotelSearchInput) {
    hotelSearch(input: $input) {
      type
      id
      name
      label

      city_ufi
      city_name

      country
      country_name

      region
      language
      nr_hotels

      latitude
      longitude
    }
  }
`;
/*
  sample input
  {
      "input": {
          "language": "en",
          "text": "sedona"
      }
  }
*/

export const SEARCH_HOTEL_QUERY = gql`
  mutation ($input: hotelAvailability2Input) {
    hotelAvailability2(input: $input) {
      address
      country
      district {
        district_id
        name
      }
      default_language
      hotel_currency_code
      hotel_id
      hotel_name
      number_of_rooms_left
      postcode
      price
      stars
      is_custom_price
      is_online_promo
      rooms {
        adults
        block_id
        deposit_required
        num_rooms_available_at_this_price
        price
        refundable
        refundable_until
        room_id
        room_name
        room_type_id
        flymya_price {
          sp
          ar
          wr
          dr
          extra_bed_rate
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
/*
  sample input
  {
    "input": {
        "type": "distance",
        "type_id": "16.8293380073104,96.1555761694035",
        "latitude": "16.8293380073104",
	      "longitude": "96.1555761694035",
        "checkin": "2020-07-11",
        "checkout": "2020-07-12",
        "country": "mm",
        "currency": "MMK",
        "adult": 2,
        "child": 0
    }
  }
*/

export const HOTEL_DETAIL_BY_ID = gql`
  mutation ($input: hotelDetailInput) {
    hotelDetails(input: $input) {
      hotel_id
      phoneno
      hotel_data {
        affiliate_id
        default_language
        max_persons_in_reservation
        address
        preferred
        country
        max_rooms_in_reservation
        creditcard_required
        zip
        city
        district_id
        is_closed
        url
        city_id
        ranking
        name
        exact_class
        license_number
        class_is_estimated
        hotel_type_id
        number_of_rooms
        currency
        deep_link_url
        class
        book_domestic_without_cc_details

        hotelier_welcome_message
        hotel_description
        hotel_important_information

        chain_id
        theme_ids
        hotel_policies {
          name
          content
          type
        }
        key_collection_info {
          how_to_collect
          key_location
        }
        location {
          latitude
          longitude
        }
        payment_details {
          cvc_required
          bookable
          payment_id
          payable
        }
        payment_options {
          pay_now
          pay_at_property
        }
        checkin_checkout_times {
          checkout_to
          checkout_from
          checkin_from
          checkin_to
        }
        hotel_facilities {
          hotel_facility_type_id
          name
          attrs
        }
        hotel_photos {
          url_square60
          main_photo
          url_original
          url_max300
          is_logo_photo
          auto_tags {
            tag_name
            tag_id
          }
          tags
        }
        spoken_languages
      }
      room_data {
        room_id
        room_name
        room_description

        room_info {
          bedroom_count
          room_type
          bedrooms {
            name
            description
          }
          min_price
          bed_configurations {
            bed_types {
              description_imperial
              count
              name
              description
              configuration_id
            }
          }
          room_size {
            metre_square
          }
          max_price
          room_type_id
          ranking
          max_persons
          bookable
          bathroom_count
        }
        room_photos {
          url_square60
          main_photo
          url_original
          url_max300
          is_logo_photo
          auto_tags {
            tag_name
            tag_id
          }
          tags
        }
        room_facilities {
          room_facility_type_id
          name
        }
      }
    }
  }
`;

export const LONG_STAY_HOTEL_ROOM_PRICES = gql`
  query ($hotelId: Int!) {
    longStayHotelRoomPrices(hotel_id: $hotelId) {
      hotel_id
      room_id
      price
      room_name
      room_type
      room_size
      show_price
      duration_of_stay
      date_type
      max_occupancy
    }
  }
`;

/*
Sample Input
{
    "input": {
        "hotel_ids": "734032"
    }
}
*/

export const HOTELS_PHOTOS_BY_HOTEL_IDS = gql`
  query ($input: hotelPhotosInput) {
    hotelPhotos(input: $input) {
      hotel_id
      photos {
        url_square60
        url_original
        url_max300
      }
    }
  }
`;

/*
Sample Input
{
  "input": {
      "hotel_ids": "734032,734033",
      "options1": "hotel_main_photos",
      "options2": "main_photo"
  }
}
*/

export const HOTEL_AVA_BLOCKS = gql`
  mutation ($input: hotelAvailDetailInput) {
    hotelAvailDetails(input: $input) {
      checkin
      checkout

      hotel_id

      cc_required
      cvc_required
      qualifies_for_no_cc_reservation
      domestic_no_cc
      is_flash_deal

      license_number
      max_rooms_in_reservation
      important_information
      commission_formula
      block {
        name
        number_of_rooms_left
        is_disabled
        is_flash_deal
        room_description
        refundable
        room_id
        block_id
        refundable_until
        deposit_required
        breakfast_included
        taxes
        max_occupancy
        photos {
          photo_id
          url_square60
          main_photo
          url_original
          url_max300
          is_logo_photo
        }
        rack_rate {
          other_currency {
            price
            currency
          }
          price
          currency
        }
        min_price {
          other_currency {
            price
            currency
          }
          price
          currency
        }
        incremental_price {
          other_currency {
            price
            currency
          }
          price
          currency
        }
        fm_min_price {
          other_currency {
            price
            currency
          }
          price
          currency
        }
        fm_incremental_price {
          other_currency {
            price
            currency
          }
          price
          currency
        }
        flymya_price {
          tf
          mar
          nb
          sf
          sp
          ar
          dr
          wr
        }
        payment_terms {
          cancellation_description
          name
          prepayment_description
        }
        cancellation_info {
          guest_currency_fee_pretty
          guest_currency
          until
          timezone
          currency
          guest_currency_fee
          fee
          from
        }
        all_inclusive
        dinner_included
        lunch_included
        mealplan_description
        facilities
        content_model {
          id
          name
          translation_fields {
            id
            translation_key
            field_type
            translations {
              id
              translation_text
              language_id
            }
          }
          __typename
        }
      }
      policies {
        class
        content
      }
    }
  }
`;

export const BOOK_HOTEL = gql`
  mutation ($input: hotelProcessBookingInput) {
    hotelProcessBooking(input: $input) {
      arranger {
        id
      }
      reservation_id
      pincode
      auth_form_url
      gmaps_url
      product_type_id
    }
  }
`;

export const GET_HOTEL_PAYMENTS = gql`
  query ($input: bkPaymentTypesInput) {
    bkPaymentTypes(input: $input) {
      name
      payment_id
      bookable
    }
  }
`;

export const GET_HOTEL_CITY = gql`
  query ($input: hotelCityInput) {
    hotelCity(input: $input) {
      country
      city_id
      nr_hotels
      name
    }
  }
`;

export const BOOK_LONG_STAY_HOTEL = gql`
  mutation ($input: longStayBookingInput) {
    longStayBooking(input: $input) {
      arranger {
        id
        __typename
      }
      reservation_id
      pincode
      auth_form_url
      gmaps_url
      __typename
    }
  }
`;

export const GET_LONG_STAY_HOTEL = gql`
  query ($orderBy: [OrderByClause!]) {
    longStayHotels(orderBy: $orderBy) {
      address
      country
      hotel_id
      hotel_name
      price
      property_facilities
      room_facilities
      show_price
      min_length_of_stay
      hotel_photo {
        photo_id
        url_square60
        main_photo
        url_original
        url_max300
        is_logo_photo
        tags
        auto_tags {
          tag_name
          tag_id
        }
      }
    }
  }
`;

export const GET_LANDMARKS = gql`
  query flymyaLandmark(
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    flymyaLandmark(
      filter: $filter
      page: $page
      first: $first
      orderBy: $orderBy
    ) {
      data {
        id
        name
        latitude
        longitude
        created_at
        updated_at
      }
      __typename
    }
  }
`;
export const GET_DISTRICTS = gql`
  query district(
    $first: Int!
    $page: Int
    $filter: [filterInput]
    $orderBy: [OrderByClause!]
  ) {
    district(filter: $filter, page: $page, first: $first, orderBy: $orderBy) {
      data {
        id
        district_id
        name
        country
        city_id
        hotels {
          id
          hotel_id
          name
          address
        }
      }
    }
  }
`;
