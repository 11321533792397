import { makeStyles } from "@material-ui/core/styles";

export const HotelPassengerFormMobileStyles = makeStyles({
  insurancePassengerInfo: {
    marginTop: "10px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow:
      "0 6px 12px -2px rgb(50 50 93 / 25%), 0 3px 7px -3px rgb(0 0 0 / 30%)",
    padding: "15px",
    "& select": {
      backgroundColor: "transparent",
      padding: "10px",
      border: "1px solid #d2cece",
      borderRadius: "7px",
      width: "90%",
      "& option": {
        color: "#000",
      },
    },
    "& .MuiFormControl-root": {
      border: "1px solid #d2cece",
      padding: "10px",
      borderRadius: "7px",
      "& input": {
        fontSize: "14px",
      },
    },
  },
  firstName: {
    "& .MuiFormControl-root": {
      width: "95%",
    },
    "& .error-input": {
      border: "1px solid red",
      "& input::placeholder": {
        color: "red",
      },
    },
  },
  lastName: {
    "& .MuiFormControl-root": {
      width: "96%",
      marginTop: "15px",
    },
    "& .error-input": {
      border: "1px solid red",
      "& input::placeholder": {
        color: "red",
      },
    },
  },
  email: {
    "& .MuiFormControl-root": {
      width: "96%",
      marginTop: "15px",
    },
    "& .error-input": {
      border: "1px solid red",
      "& input::placeholder": {
        color: "red",
      },
    },
  },
  phonePrefix: {
    marginTop: "15px !important",
    position: "relative",
    "& .react-tel-input": {
      width: "90% !important",
      height: "40px",
      "& .special-label": {
        background: "#fff",
        fontSize: "13px",
        left: "46px",
        padding: "0 2px",
        position: "absolute",
        top: "-8px",
        zIndex: 1,
      },
      "& .form-control": {
        fontSize: "14px",
        width: 0,
        height: "35px",
        letterSpacing: ".01rem",
        lineHeight: "25px",
        marginBottom: 0,
        marginLeft: 0,
        marginTop: 0,
        outline: "none",
        paddingLeft: "48px",
        position: "relative",
        backgroundColor: "transparent",
        border: "none",
      },
      "& .flag-dropdown": {
        backgroundColor: "transparent",
        border: " 1px solid #d2cece",
        borderRadius: "7px",
        width: "100%",
      },
    },
    "& .countryCode": {
      color: "#231f20",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      position: "absolute",
      right: "24px",
      top: "12px",
    },
  },
  phoneNumber: {
    "& .MuiFormControl-root": {
      width: "95%",
      marginTop: "15px",
    },
    "& .error-input": {
      border: "1px solid red",
      "& input::placeholder": {
        color: "red",
      },
    },
  },
  submitButton: {
    "&.bgKbz": {
      height: "auto !important",
      backgroundColor: "#fff !important",
    },
    "&.bgWave": {
      height: "auto !important",
      minHeight: "auto !important",
      backgroundColor: "#fff !important",
      "& button": {
        backgroundColor: "#fee337",
        color: "#000",
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "#fee337",
        color: "#000",
      },
    },
    "&.bgOnePay": {
      height: "auto !important",
      minHeight: "auto !important",
      backgroundColor: "#fff !important",
      "& button": {
        backgroundColor: "#2e9d68",
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "#2e9d68",
      },
    },
    "&.bgAyaPay": {
      height: "auto !important",
      minHeight: "auto !important",
      backgroundColor: "#fff !important",
      "& button": {
        backgroundColor: "#cd1f2c",
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "#cd1f2c",
      },
    },
    "&.bgCitizenPay": {
      height: "auto !important",
      minHeight: "auto !important",
      backgroundColor: "#fff !important",
      "& button": {
        backgroundColor: "#ec008c",
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "#ec008c",
      },
    },
    "&.bgMpitesan": {
      height: "auto !important",
      minHeight: "auto !important",
      backgroundColor: "#fff !important",
      "& button": {
        backgroundColor: "#ec3833",
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "#ec3833",
      },
    },
    "&.bgCityReward": {
      height: "auto !important",
      minHeight: "auto !important",
      backgroundColor: "#fff !important",
      "& button": {
        backgroundColor: "#6e51a2",
      },
      "& .MuiButtonBase-root:hover": {
        backgroundColor: "#ec008c",
      },
    },
    "& button": {
      width: "100%",
      marginTop: "20px",
      // backgroundColor: "#00AEEF",
      color: "#fff",
      padding: "10px 0",
      textTransform: "none",
    },
    "& .btnSearch": {
      display: "inline-flex !important",
      width: "100% !important",
      maxWidth: "100% !important",
    },
  },
  insuranceTextInfo: {
    display: "flex",
    flexDirection: "column !important",
    "& img": {
      width: "150px",
      marginTop: "15px",
    },
    "& span": {
      fontSize: "14px",
      fontWeight: 600,
      marginTop: "10px",
      marginBottom: "15px",
    },
  },
});
