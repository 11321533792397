import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, TextField, CircularProgress } from "@mui/material";
import PhonePrefix from "../../../components/inputs/PhonePrefix";
import { PhoneValidation } from "../../../utils/phoneValidation";

const CustomerPhoneForm = ({
  setPhoneCountryCode,
  phoneCountryCode,
  setPhoneCountryFlag,
  phoneCountryFlag,
  otpErrMsg,
  phonenumber,
  setOtpErrMsg,
  setPhonenumber,
  isPhonePass,
  otpPassword,
  setOtpPassword,
  isDisable,
  signInWithPhoneNumber,
  clickSendOtp,
  sendOtp,
  errorMsg,
  otp,
  setotp,
  OTPValidation,
  setSignUpOTPLoading,
  signUpOTPLoading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {!sendOtp && (
        <div className="infobox">
          <div style={{ display: "flex" }}>
            <Grid className="phone-container" style={{ marginLeft: "3px" }}>
              <PhonePrefix
                handlePhonePrefix={setPhoneCountryCode}
                prefixValue={phoneCountryCode}
                handlePhoneFlag={setPhoneCountryFlag}
                mobileCountryFlag={phoneCountryFlag}
                showSpanLabel={false}
                dropdownStyle={{
                  background: "white",
                  border: "none",
                }}
              />
            </Grid>
            <TextField
              className={
                otpErrMsg !== ""
                  ? "error-textbox login-box-phone"
                  : "login-box-phone"
              }
              name="Phone Number"
              type="number"
              value={phonenumber}
              autoComplete="off"
              onChange={(e) => {
                setOtpErrMsg("");
                setPhonenumber(e.target.value);
              }}
              onBlur={() => {
                const num = /^[0-9]+$/;
                const firstNo = phonenumber.charAt(0);
                if (!num.test(phonenumber) || Number(firstNo) === 0) {
                  setOtpErrMsg("Please check your phone number.");
                  return;
                }
                if (
                  phoneCountryCode === "95" &&
                  !PhoneValidation(phonenumber)
                ) {
                  setOtpErrMsg("Please enter valid number.");
                  return;
                }
                setOtpErrMsg("");
              }}
              // label="Phone Number"
              placeholder="Phone Number"
              required
              error={otpErrMsg !== "" ? true : false}
              variant="standard"
            />
          </div>
          {isPhonePass && (
            <TextField
              className={otpErrMsg !== "" ? "error-textbox" : ""}
              name="otpPassword"
              type="password"
              value={otpPassword}
              autoComplete="off"
              onChange={(e) => {
                setOtpErrMsg("");
                setOtpPassword(e.target.value);
              }}
              label={
                t("masterPassengerInformationTwo.password") !==
                "masterPassengerInformationTwo.password"
                  ? t("masterPassengerInformationTwo.password")
                  : "Password"
              }
              required
              error={otpErrMsg !== "" ? true : false}
            />
          )}
          {otpErrMsg !== "" && <p>{otpErrMsg}</p>}
          <span className="phoneInfo">
            e.g. 9123456789, for country code (+95) and mobile No.9123456789
          </span>
          <br />
          <div id="recaptcha-container"></div>
          <Button
            color="primary"
            variant="contained"
            className={`btn-send-otp ${isDisable ? "dim-btn-send-otp" : ""}`}
            onClick={signInWithPhoneNumber}
            disabled={clickSendOtp}
          >
            {t(`auth.send_otp`) !== "auth.send_otp"
              ? t(`auth.send_otp`)
              : "Send OTP"}
          </Button>
          {isDisable && (
            <p>
              {" "}
              {t(`auth.wait_next_attempt`) !== "auth.wait_next_attempt"
                ? t(`auth.wait_next_attempt`)
                : "Please wait 60secs before the next attempt."}
            </p>
          )}
        </div>
      )}
      {sendOtp && (
        <div className="infobox">
          <label>OTP*</label>
          <input
            className={errorMsg !== "" ? "error-textbox" : ""}
            name="OTP"
            type="number"
            value={otp}
            autoComplete="off"
            onChange={(e) => {
              setotp(e.target.value);
            }}
            label="OTP"
            required
            error={errorMsg !== "" ? true : false}
            variant="standard"
          />
          <Button
            color="primary"
            variant="contained"
            className="btn-send-otp"
            onClick={() => {
              OTPValidation();
              setSignUpOTPLoading && setSignUpOTPLoading(true);
            }}
          >
            {signUpOTPLoading && <CircularProgress />}
            {!signUpOTPLoading &&
              (t(`auth.verify`) !== "auth.verify"
                ? t(`auth.verify`)
                : "Verify")}
          </Button>
        </div>
      )}
    </>
  );
};

export default CustomerPhoneForm;
