import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

function handleHide(event) {
  document.getElementById("offlineBox").hidden = "hidden";
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6" className="offlineTitle">
        {children}
      </Typography>
      <IconButton
        aria-label="close"
        className={classes.closeButton + " offlineCloseIcon"}
        onClick={handleHide}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const CustomizedDialogs = (props) => {
  return (
    props.offline &&
    props.clickSearch && (
      <div>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={true}
          id="offlineBox"
        >
          <DialogTitle id="customized-dialog-title">Warning !</DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>No internet connection.</Typography>
          </DialogContent>
        </Dialog>
      </div>
    )
  );
};

export default CustomizedDialogs;
