import {
  AUTH_TOKEN,
  TOKEN_TYPE,
  LOGIN_USER_ID,
  IDENTIFIER,
  EXPIRES_IN,
  LOGIN_PEOPLE_COMPANY,
  LOGIN_PEOPLE_CLASS_TYPE,
  LOGIN_USER_PEOPLE,
  IS_B2B,
  IS_CALL_CENTER,
  IS_CUSTOMER,
  LOGIN_PEOPLE_DETAIL,
  B2B_ClASS_TYPE,
} from "../config/constants";
import { expireDate } from "../lib/auth";
import { includes } from "lodash";
import { IS_EMPLOYEE } from "../config/constants";

export const setToLocalStorage = (res) => {
  res.data.firebaseLogin &&
    localStorage.setItem(
      IS_CUSTOMER,
      res.data.firebaseLogin.user?.roles &&
        res.data.firebaseLogin.user?.roles.length > 0
        ? res.data.firebaseLogin.user?.roles.find((val) =>
            includes(val.name, "Customer"),
          )
          ? "yes"
          : "no"
        : "no",
    );
  res.data.firebaseLoginByToken &&
    localStorage.setItem(
      IS_CUSTOMER,
      res.data.firebaseLoginByToken.user?.roles &&
        res.data.firebaseLoginByToken.user?.roles.length > 0
        ? res.data.firebaseLoginByToken.user?.roles.find((val) =>
            includes(val.name, "Customer"),
          )
          ? "yes"
          : "no"
        : "no",
    );
  res.data.agentLogin &&
    localStorage.setItem(
      IS_CUSTOMER,
      res.data.agentLogin.user?.roles &&
        res.data.agentLogin.user?.roles.length > 0
        ? res.data.agentLogin.user?.roles.find((val) =>
            includes(val.name, "Customer"),
          )
          ? "yes"
          : "no"
        : "no",
    );
  res.data.agentLogin &&
    localStorage.setItem(
      IS_B2B,
      res.data.agentLogin.user?.roles &&
        res.data.agentLogin.user?.roles.length > 0
        ? res.data.agentLogin.user?.roles.find((val) =>
            includes(val.name, "B2B"),
          )
          ? "yes"
          : "no"
        : "no",
    );
  const isCustomer =
    localStorage.getItem(IS_CUSTOMER) &&
    localStorage.getItem(IS_CUSTOMER) === "yes"
      ? true
      : false;
  const isB2B =
    localStorage.getItem(IS_B2B) && localStorage.getItem(IS_B2B) === "yes"
      ? true
      : false;
  localStorage.setItem(
    AUTH_TOKEN,
    res.data.firebaseLogin
      ? res.data?.firebaseLogin?.access_token
      : res.data?.firebaseLoginByToken
      ? res.data?.firebaseLoginByToken?.access_token
      : res.data?.agentLogin?.access_token,
  );
  localStorage.setItem(
    TOKEN_TYPE,
    res.data.firebaseLogin
      ? res.data?.firebaseLogin?.token_type
      : res.data?.firebaseLoginByToken
      ? res.data?.firebaseLoginByToken?.token_type
      : res.data?.agentLogin?.token_type,
  );

  localStorage.setItem(
    EXPIRES_IN,
    expireDate(
      res.data.firebaseLogin
        ? res.data?.firebaseLogin?.expires_in
        : res.data?.firebaseLoginByToken
        ? res.data?.firebaseLoginByToken?.expires_in
        : res.data?.agentLogin?.expires_in,
    ),
  );
  localStorage.setItem(
    IDENTIFIER,
    res.data.firebaseLogin
      ? res.data.firebaseLogin?.user?.email
      : res.data.firebaseLoginByToken
      ? res.data.firebaseLoginByToken?.user?.email
      : res.data.agentLogin?.user?.email,
  );
  localStorage.setItem(
    LOGIN_USER_ID,
    res.data.firebaseLogin
      ? res.data.firebaseLogin.user?.id
      : res.data.firebaseLoginByToken
      ? res.data.firebaseLoginByToken.user?.id
      : res.data.agentLogin.user?.id,
  );

  res.data.firebaseLogin
    ? res.data.firebaseLogin.user &&
      res.data.firebaseLogin.user?.arranger &&
      localStorage.setItem(
        LOGIN_PEOPLE_COMPANY,
        JSON.stringify(res.data.firebaseLogin.user?.arranger.company),
      )
    : res.data.firebaseLoginByToken
    ? res.data.firebaseLoginByToken?.user &&
      res.data.firebaseLoginByToken.user?.arranger &&
      localStorage.setItem(
        LOGIN_PEOPLE_COMPANY,
        JSON.stringify(res.data.firebaseLoginByToken.user?.arranger.company),
      )
    : localStorage.setItem(
        LOGIN_PEOPLE_COMPANY,
        JSON.stringify(
          res.data.agentLogin?.user &&
            res.data.agentLogin.user?.arrangers &&
            res.data.agentLogin.user?.arrangers.length > 0 &&
            res.data.agentLogin.user?.arrangers[0].company,
        ),
      );

  res.data.agentLogin &&
    res.data.agentLogin.user &&
    res.data.agentLogin?.user?.arrangers &&
    res.data.agentLogin?.user?.arrangers.length > 0 &&
    localStorage.setItem(
      LOGIN_PEOPLE_CLASS_TYPE,
      JSON.stringify(
        res.data.agentLogin.user?.arrangers &&
          res.data.agentLogin?.user?.arrangers.length > 0 &&
          res.data.agentLogin.user?.arrangers[0].company &&
          res.data.agentLogin.user?.arrangers[0].company.class_type &&
          res.data.agentLogin.user?.arrangers[0].company.class_type.id,
      ),
    );
  res.data.firebaseLogin
    ? res.data.firebaseLogin.user &&
      res.data.firebaseLogin.user?.arranger &&
      localStorage.setItem(
        LOGIN_USER_PEOPLE,
        res.data.firebaseLogin.user?.arranger?.id,
      )
    : res.data.firebaseLoginByToken
    ? res.data.firebaseLoginByToken?.user &&
      res.data.firebaseLoginByToken.user?.arranger &&
      localStorage.setItem(
        LOGIN_USER_PEOPLE,
        res.data.firebaseLoginByToken.user?.arranger.id,
      )
    : res.data.agentLogin &&
      res.data.agentLogin.user &&
      res.data.agentLogin.user?.arrangers &&
      res.data.agentLogin.user?.arrangers.length > 0 &&
      localStorage.setItem(
        LOGIN_USER_PEOPLE,
        res.data.agentLogin.user?.arrangers[0].id,
      );
  res.data.firebaseLogin
    ? localStorage.setItem(
        IS_B2B,
        res.data.firebaseLogin.user?.roles &&
          res.data.firebaseLogin.user?.roles.length > 0
          ? res.data.firebaseLogin.user?.roles.find((val) =>
              includes(val.name, "B2B"),
            )
            ? "yes"
            : "no"
          : "no",
      )
    : res.data.firebaseLoginByToken
    ? localStorage.setItem(
        IS_B2B,
        res.data.firebaseLoginByToken.user?.roles &&
          res.data.firebaseLoginByToken.user?.roles.length > 0
          ? res.data.firebaseLoginByToken.user?.roles.find((val) =>
              includes(val.name, "B2B"),
            )
            ? "yes"
            : "no"
          : "no",
      )
    : localStorage.setItem(
        IS_B2B,
        res.data.agentLogin.user?.roles &&
          res.data.agentLogin.user?.roles.length > 0
          ? res.data.agentLogin.user?.roles.find((val) =>
              includes(val.name, "B2B"),
            )
            ? "yes"
            : "no"
          : "yes",
      );

  res.data.firebaseLogin
    ? localStorage.setItem(
        IS_EMPLOYEE,
        res.data.firebaseLogin.user?.roles &&
          res.data.firebaseLogin.user?.roles.length > 0
          ? res.data.firebaseLogin.user?.roles.find(
              (val) =>
                includes(val.name, "Employee") || includes(val.name, "Super"),
            )
            ? "yes"
            : "no"
          : "no",
      )
    : res.data.firebaseLoginByToken
    ? localStorage.setItem(
        IS_EMPLOYEE,
        res.data.firebaseLoginByToken.user?.roles &&
          res.data.firebaseLoginByToken.user?.roles.length > 0
          ? res.data.firebaseLoginByToken.user?.roles.find(
              (val) =>
                includes(val.name, "Employee") || includes(val.name, "Super"),
            )
            ? "yes"
            : "no"
          : "no",
      )
    : localStorage.setItem(
        IS_EMPLOYEE,
        res.data.agentLogin.user?.roles &&
          res.data.agentLogin.user?.roles.length > 0
          ? res.data.agentLogin.user?.roles.find(
              (val) =>
                includes(val.name, "Employee") || includes(val.name, "Super"),
            )
            ? "yes"
            : "no"
          : "yes",
      );

  res.data.firebaseLogin &&
    localStorage.setItem(
      IS_CALL_CENTER,
      res.data.firebaseLogin.user?.roles &&
        res.data.firebaseLogin.user?.roles.length > 0
        ? res.data.firebaseLogin.user?.roles.find(
            (val) =>
              includes(val.name, "Employee") || includes(val.name, "Super"),
          )
          ? "yes"
          : "no"
        : "no",
    );
  res.data.firebaseLoginByToken &&
    localStorage.setItem(
      IS_CALL_CENTER,
      res.data.firebaseLoginByToken.user?.roles &&
        res.data.firebaseLoginByToken.user?.roles.length > 0
        ? res.data.firebaseLoginByToken.user?.roles.find(
            (val) =>
              includes(val.name, "Employee") || includes(val.name, "Super"),
          )
          ? "yes"
          : "no"
        : "no",
    );
  res.data.agentLogin &&
    localStorage.setItem(
      IS_CALL_CENTER,
      res.data.agentLogin.user?.roles &&
        res.data.agentLogin.user?.roles.length > 0
        ? res.data.agentLogin.user?.roles.find(
            (val) =>
              includes(val.name, "Employee") || includes(val.name, "Super"),
          )
          ? "yes"
          : "no"
        : "no",
    );

  res.data.firebaseLogin &&
    res.data.firebaseLogin.user &&
    res.data.firebaseLogin.user?.company &&
    res.data.firebaseLogin.user?.company.arrangers &&
    res.data.firebaseLogin.user?.company.arrangers.length > 0 &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.data.firebaseLogin.user?.company.arrangers[0]),
    );
  res.data.firebaseLogin &&
    res.data.firebaseLogin.user &&
    res.data.firebaseLogin.user?.arranger &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.data.firebaseLogin.user?.arranger),
    );
  res.data.firebaseLoginByToken &&
    res.data.firebaseLoginByToken.user &&
    res.data.firebaseLoginByToken.user?.company &&
    res.data.firebaseLoginByToken.user?.company.arrangers &&
    res.data.firebaseLoginByToken.user?.company.arrangers.length > 0 &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.data.firebaseLoginByToken.user?.company?.arrangers[0]),
    );
  res.data.firebaseLoginByToken &&
    res.data.firebaseLoginByToken.user &&
    res.data.firebaseLoginByToken.user.arranger &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.data.firebaseLoginByToken.user?.arranger),
    );
  res.data.agentLogin &&
    res.data.agentLogin.user &&
    res.data.agentLogin.user?.arrangers &&
    res.data.agentLogin.user?.arrangers.length > 0 &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.data.agentLogin.user?.arrangers[0]),
    );
  res.data.agentLogin &&
    res.data.agentLogin.user &&
    res.data.agentLogin.user?.arrangers &&
    res.data.agentLogin.user?.arrangers.length > 0 &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.data.agentLogin.user?.arrangers[0]),
    );
  res.data.agentLogin &&
    res.data.agentLogin.user &&
    res.data.agentLogin.user?.arrangers &&
    res.data.agentLogin.user?.arrangers.length > 0 &&
    localStorage.setItem(
      B2B_ClASS_TYPE,
      res.data.agentLogin.user?.arrangers[0].company &&
        res.data.agentLogin.user?.arrangers[0].company.class_type &&
        res.data.agentLogin.user?.arrangers[0].company.class_type.id,
    );
  res.data.firebaseLogin &&
    res.data.firebaseLogin.user &&
    res.data.firebaseLogin.user?.arranger &&
    res.data.firebaseLogin.user?.arranger.company &&
    localStorage.setItem(
      B2B_ClASS_TYPE,
      res.data.firebaseLogin.user?.arranger.company &&
        res.data.firebaseLogin.user?.arranger.company.class_type &&
        res.data.firebaseLogin.user?.arranger.company.class_type.id,
    );
};

export const setToLocalStorageFromVerifyEmail = (res) => {
  res.verifyEmail &&
    localStorage.setItem(
      IS_CUSTOMER,
      res.verifyEmail.user?.roles && res.verifyEmail.user?.roles.length > 0
        ? res.verifyEmail.user?.roles.find((val) =>
            includes(val.name, "Customer"),
          )
          ? "yes"
          : "no"
        : "no",
    );

  localStorage.setItem(AUTH_TOKEN, res.verifyEmail.access_token);
  localStorage.setItem(TOKEN_TYPE, res.verifyEmail.token_type);

  localStorage.setItem(EXPIRES_IN, expireDate(res.verifyEmail.expires_in));
  localStorage.setItem(IDENTIFIER, res.verifyEmail.user?.email);
  localStorage.setItem(LOGIN_USER_ID, res.verifyEmail.user?.id);

  localStorage.setItem(
    LOGIN_PEOPLE_COMPANY,
    JSON.stringify(
      res.verifyEmail?.user &&
        res.verifyEmail.user?.arranger &&
        res.verifyEmail.user?.arranger.company,
    ),
  );

  res.verifyEmail &&
    res.verifyEmail.user &&
    res.verifyEmail?.user?.arranger &&
    localStorage.setItem(
      LOGIN_PEOPLE_CLASS_TYPE,
      JSON.stringify(
        res.verifyEmail.user?.arranger &&
          res.verifyEmail.user?.arranger.company &&
          res.verifyEmail.user?.arranger.company.class_type &&
          res.verifyEmail.user?.arranger.company.class_type.id,
      ),
    );
  res.verifyEmail &&
    res.verifyEmail.user &&
    res.verifyEmail.user?.arranger &&
    localStorage.setItem(LOGIN_USER_PEOPLE, res.verifyEmail.user?.arranger.id);

  localStorage.setItem(
    IS_B2B,
    res.verifyEmail.user?.roles && res.verifyEmail.user?.roles.length > 0
      ? res.verifyEmail.user?.roles.find((val) => includes(val.name, "B2B"))
        ? "yes"
        : "no"
      : "yes",
  );

  localStorage.setItem(
    IS_EMPLOYEE,
    res.verifyEmail.user?.roles && res.verifyEmail.user?.roles.length > 0
      ? res.verifyEmail.user?.roles.find(
          (val) =>
            includes(val.name, "Employee") || includes(val.name, "Super"),
        )
        ? "yes"
        : "no"
      : "yes",
  );

  localStorage.setItem(
    IS_CALL_CENTER,
    res.verifyEmail.user?.roles && res.verifyEmail.user?.roles.length > 0
      ? res.verifyEmail.user?.roles.find(
          (val) =>
            includes(val.name, "Employee") || includes(val.name, "Super"),
        )
        ? "yes"
        : "no"
      : "no",
  );

  res.verifyEmail &&
    res.verifyEmail.user &&
    res.verifyEmail.user?.arranger &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.verifyEmail.user?.arranger),
    );
  res.verifyEmail &&
    res.verifyEmail.user &&
    res.verifyEmail.user?.arranger &&
    res.verifyEmail.user?.arranger.company &&
    localStorage.setItem(
      B2B_ClASS_TYPE,
      res.verifyEmail.user?.arranger.company &&
        res.verifyEmail.user?.arranger.company.class_type &&
        res.verifyEmail.user?.arranger.company.class_type.id,
    );
};
export const storeUserProfileInfoFromVerifyEmail = (res) => {
  localStorage.setItem("userData", JSON.stringify(res?.verifyEmail?.user));
};

export const setToLocalStorageFromEmailLink = (res) => {
  res.data.createArranger &&
    res.data.createArranger?.user &&
    res.data.createArranger?.user?.company &&
    localStorage.setItem(
      LOGIN_PEOPLE_COMPANY,
      JSON.stringify(res.data.createArranger?.user?.company),
    );

  res.data.createArranger &&
    res.data.createArranger?.user &&
    res.data.createArranger?.user?.company &&
    localStorage.setItem(
      LOGIN_PEOPLE_CLASS_TYPE,
      JSON.stringify(
        res.data.createArranger?.user?.company &&
          res.data.createArranger?.user?.company?.class_type &&
          res.data.createArranger?.user?.company?.class_type?.id,
      ),
    );
  res.data.createArranger &&
    localStorage.setItem(LOGIN_USER_PEOPLE, res.data.createArranger?.id);

  res.data.createArranger &&
    res.data.createArranger?.user &&
    res.data.createArranger?.user?.arranger &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.data.createArranger?.user?.arranger),
    );
  res.data.createArranger &&
    res.data.createArranger?.user &&
    res.data.createArranger?.user?.company &&
    localStorage.setItem(
      B2B_ClASS_TYPE,
      res.data.createArranger?.user?.company?.class_type &&
        res.data.createArranger?.user?.company?.class_type?.id,
    );
};

export const storeUserProfileInfoFromEmailLink = (res) => {
  localStorage.setItem(
    "userData",
    JSON.stringify(res.data.createArranger?.user),
  );
};

export const storeUserProfileInfo = (res, type) => {
  localStorage.setItem(
    "userData",
    localStorage.getItem("loginPanel") === "agent"
      ? JSON.stringify(res?.data?.agentLogin?.user)
      : JSON.stringify(res?.data?.firebaseLogin?.user),
  );
};

export const storeUserProfileInformation = (res) => {
  localStorage.setItem(
    "userData",
    JSON.stringify(res?.data?.firebaseLoginByToken?.user),
  );
};

export const storeUserArrangerInfo = (res) => {
  localStorage.setItem(
    "userData",
    JSON.stringify(res?.data?.createArranger?.user),
  );
  localStorage.setItem(
    LOGIN_PEOPLE_DETAIL,
    JSON.stringify(res?.data?.createArranger?.user?.arranger),
  );
};

export const storeUserEditDate = (res) => {
  let data = [];
  let value = res?.data?.createArranger;

  data.push(value?.user?.id);
  data.push(value?.updated_at);
  JSON.stringify(localStorage.setItem("updated_date", data));
};

export const getUserEditDate = () => {
  return localStorage.getItem("updated_date");
};

export const getUserProfileInfo = (res) => {
  let data = localStorage.getItem("userData");

  if (data === undefined || data === "undefined") {
    return null;
  } else {
    return JSON.parse(data);
  }
};

export const storeProfileId = (id) => {
  localStorage.setItem("profileId", id);
};

export const getProfileId = () => {
  return localStorage.getItem("profileId");
};

export const removeLocalStorageItem = (item) => {
  localStorage.removeItem(item);
};

export const setKbzLoginToLocalStorage = (res) => {
  res &&
    localStorage.setItem(
      IS_CUSTOMER,
      res.user?.roles && res.user?.roles.length > 0
        ? res.user?.roles.find((val) => includes(val.name, "Customer"))
          ? "yes"
          : "no"
        : "no",
    );
  localStorage.setItem(AUTH_TOKEN, res?.access_token);
  localStorage.setItem(TOKEN_TYPE, res.token_type);
  localStorage.setItem(EXPIRES_IN, expireDate(res.expires_in));
  localStorage.setItem(IDENTIFIER, res?.user?.email);
  localStorage.setItem(LOGIN_USER_ID, res.user?.id);

  res &&
    res.user?.arranger &&
    localStorage.setItem(
      LOGIN_PEOPLE_COMPANY,
      JSON.stringify(res.user?.arranger.company),
    );

  res &&
    res.user?.arrangers &&
    localStorage.setItem(
      LOGIN_PEOPLE_CLASS_TYPE,
      JSON.stringify(res.user?.arranger.company?.class_type.id),
    );
  res &&
    res.user?.arranger &&
    localStorage.setItem(LOGIN_USER_PEOPLE, res.user?.arranger.id);

  localStorage.setItem(
    IS_B2B,
    res.user?.roles && res.user?.roles.length > 0
      ? res.user?.roles.find((val) => includes(val.name, "B2B"))
        ? "yes"
        : "no"
      : "no",
  );

  localStorage.setItem(
    IS_EMPLOYEE,
    res.user?.roles && res.user?.roles.length > 0
      ? res.user?.roles.find(
          (val) =>
            includes(val.name, "Employee") || includes(val.name, "Super"),
        )
        ? "yes"
        : "no"
      : "no",
  );

  localStorage.setItem(
    IS_CALL_CENTER,
    res.user?.roles && res.user?.roles.length > 0
      ? res.user?.roles.find(
          (val) =>
            includes(val.name, "Employee") || includes(val.name, "Super"),
        )
        ? "yes"
        : "no"
      : "no",
  );

  res &&
    res.user &&
    res.user.arranger &&
    localStorage.setItem(
      LOGIN_PEOPLE_DETAIL,
      JSON.stringify(res.user?.arranger),
    );

  res &&
    res.user &&
    res.user?.arranger &&
    res.user?.arranger.company &&
    localStorage.setItem(
      B2B_ClASS_TYPE,
      res.user?.arranger.company &&
        res.user?.arranger.company.class_type &&
        res.user?.arranger.company.class_type.id,
    );
};
export const storeKbzUserProfileInformation = (res) => {
  localStorage.setItem("userData", JSON.stringify(res?.user));
};
